/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"

import { useToasts } from "react-toast-notifications"
import get from "lodash.get"
import forEach from "lodash.foreach"
import sortBy from "lodash.sortby"
import has from "lodash.has"
import merge from "lodash.merge"

// Components
import SectionTitle from "../../components/atoms/SectionTitle"
import Card from "../../components/atoms/Card"
import Dashboard from "../../components/molecules/Dashboard"
import TimeZoneModal from "../../components/molecules/TimeZoneModal"
import Modal from "../../components/atoms/CustomModal"
import Chat from "../../components/organisms/Chat"
import QuestionCard from "../../components/atoms/QuestionCard"

import { SectionsDoubleContainer, Toolbar } from "../../components/atoms/Global"

// Utils
import { appointmentsCancelUrl } from "../../utils/App"
import { withFirebase } from "../../utils/Firebase"
import { showBrowserNotification } from "../../utils/helpers"
import { withAuthorization, AuthUserContext } from "../../context/Session"
import ChatContext from "../../context/Chat"
import { setFirstMessageKey } from "../../context/Chat/reducer"
import constants from "../../utils/constants"

// Styles
import theme from "../../styles/theme"
import {
  Container,
  StyledImage,
  MessageContainer,
  ChatWrapper,
  Waiting,
  ImportantText,
  ImportantWrapper,
  ModalOptions,
  ModalWrapper,
  SubmitButton,
} from "./styles"
import ConfirmPaymentModal from "./ConfirmPaymentModal"
import ConfirmActivationModal from "./ConfirmActivationModal"

let interval = null
const twoHour = 3600000 * 2

const Sessions = ({ firebase }) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const { dispatch } = useContext(ChatContext)
  const userType = get(user, ["medicProfile", "userType"])
  const { addToast } = useToasts()
  const isReturning = get(state, "session")
  const [customLimit, setCustomLimit] = useState(constants.chats.MESSAGE_LIMIT)
  const [chats, setChats] = useState([])
  const [messages, setMessages] = useState([])
  const [chatsRef, setChatsRef] = useState(null)
  const [messageKeys, setMessageKeys] = useState(null)
  const [currentSession, setCurrentSession] = useState(null)
  const [currentType, setCurrentType] = useState(null)
  const [currentAppointment, setCurrentAppointment] = useState(null)
  const [loadingChats, setLoadingChats] = useState(true)
  const [loadingMessages, setloadingMessages] = useState(true)
  const [loadingEarlier, setLoadingEarlier] = useState(false)
  const [waitingPatient, setWaitingPatient] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [loadingAppointments, setLoadingAppointments] = useState(true)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [cancelAction, setCancelAction] = useState(null)
  const [questions, setQuestions] = useState([])

  const onCardClick = async (card, type) => {
    const currentId =
      type === "agenda" ? (card.chatId ? card.chatId : card.slotID) : card.id
    if (!currentId) {
      setCurrentSession(null)
      setWaitingPatient(true)
      setCurrentAppointment(null)
    } else if (currentId !== currentSession) {
      if (type === "agenda") {
        const apt = appointments.find(
          (a) => a.chatId === currentId || a.slotID === currentId
        )
        setWaitingPatient(true)
        setCurrentAppointment(apt)
      }
      if (type !== "agenda" && currentAppointment) {
        setWaitingPatient(false)
        setCurrentAppointment(null)
      }
      setCustomLimit(constants.chats.MESSAGE_LIMIT)
      setCurrentType(type)
      setCurrentSession(currentId)
      setloadingMessages(true)

      // check for existing contact
      try {
        if (card && card.clientEmail) {
          const contactData = await firebase.checkContact(
            user.isImpersonating ? user.impersonatingEmail : user.email,
            card.clientEmail
          )

          if (contactData.docs.length <= 0) {
            try {
              const contact = {
                email: card?.clientEmail.toLowerCase(),
                displayName: card?.clientName || card?.clientDisplayName,
                phoneNumber: "",
                userType: "client",
                isFavorite: false,
              }

              await firebase.addContact({
                doctor: user.isImpersonating
                  ? user.impersonatingEmail
                  : user.email,
                contact,
              })
            } catch (error) {
              console.log("Error adding a new contact", error)
            }
          }
        }
      } catch (error) {
        console.log("Error checking for a contact", error)
      }
    } else {
      setWaitingPatient(null)
      setCurrentSession(null)
      setCurrentAppointment(null)
    }
  }

  const handleLoadMore = () => {
    setLoadingEarlier(true)
    if (customLimit > 0) {
      setCustomLimit(customLimit + 10)
    } else {
      setCustomLimit(constants.chats.MESSAGE_LIMIT + 10)
    }
  }

  const getPendingQuestions = async () => {
    try {
      const data = await firebase.getPendingAnswerQuestions(
        user.isImpersonating ? user.impersonatingEmail : user.email
      )
      if (data && data.docs) {
        const questionsData = data.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }))
        setQuestions(questionsData.sort((a, b) => b.createdAt - a.createdAt))
      }
    } catch (err) {
      window.Honeybadger?.notify(`Error on get sessions questions: ${err}`)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  useEffect(() => {
    if (user?.email || user?.impersonatingEmail) {
      getPendingQuestions()
    }
  }, [firebase, user])

  useEffect(() => {
    if (user?.email || user?.impersonatingEmail) {
      interval = setInterval(() => {
        getPendingQuestions()
      }, twoHour)
    }
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      firebase.getChats({
        doctor: user.isImpersonating ? user.impersonatingUID : user.uid,
        onceValue: (snapshot) => {
          // eslint-disable-next-line no-shadow
          let chats = {}
          forEach(snapshot.val(), (chat, key) => {
            chats = {
              ...chats,
              [key]: {
                id: key,
                ...chat,
              },
            }
          })
          setChats(chats)
          setLoadingChats(false)
        },
      })
    }
  }, [userType])

  useEffect(() => {
    const loadAppointmentsFromDB = async () => {
      const _appointments = []
      const querySnapshot = await firebase.getUpcomingAppointments(
        user.isImpersonating ? user.impersonatingEmail : user.email // Check if is an assistant
      )
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          const _appointment = doc.data()
          _appointments.push(_appointment)
        })
        setAppointments(_appointments)
      }
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      loadAppointmentsFromDB()
    }
  }, [firebase, userType])

  const notificationAppointmentToShow = (appointmentData) => {
    const appointmentStartDate = appointmentData.isActive
      ? moment().format("DD/MM/YYYY")
      : moment.unix(appointmentData.slotStart).format("DD/MM/YYYY")

    const appointmentStartTime = appointmentData.isActive
      ? moment().format("HH:mm a")
      : moment.unix(appointmentData.slotStart).format("HH:mm a")

    const body = t("new_appointment_schedule", {
      name: get(appointmentData, "clientDisplayName"),
      date: appointmentStartDate,
      time: appointmentStartTime,
    })
    showBrowserNotification(t("you_have_an_appointment"), body)
  }

  const showNewAppointmentNotification = async (appointmentData) => {
    const Settings = await firebase.getSettings({
      email: user.isImpersonating ? user.impersonatingEmail : user.email,
      key: "notifications",
    })
    if (Settings.data() && Settings.data().onBookedBrowser) {
      const getShowedDates = JSON.parse(
        localStorage.getItem("appointments_showed") || "[]"
      )

      if (getShowedDates.length > 0) {
        if (!getShowedDates.includes(appointmentData.slotID)) {
          notificationAppointmentToShow(appointmentData)
          getShowedDates.push(appointmentData.slotID)
          localStorage.setItem(
            "appointments_showed",
            JSON.stringify(getShowedDates)
          )
        }
      } else {
        notificationAppointmentToShow(appointmentData)
        localStorage.setItem(
          "appointments_showed",
          JSON.stringify([appointmentData.slotID])
        )
      }
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.subscribeToAppointments({
      doctor: user.isImpersonating ? user.impersonatingEmail : user.email,
      onSnapshot: (s) => {
        setLoadingAppointments(true)
        // let _appointments = appointments
        // Utilizar prev state
        s.docChanges().forEach((change) => {
          if (change.type === "added") {
            const data = change.doc.data()
            showNewAppointmentNotification(data)
            setAppointments((prev) => {
              if (!prev.find((a) => a.chatId === data.chatId))
                return [...prev, data]
              return prev
            })
          }
          if (change.type === "modified") {
            const data = change.doc.data()
            // const newAppointments = _appointments.filter(
            //   (a) => a.chatId !== data.chatId
            // )
            // newAppointments.push(data)
            setAppointments((prev) => [
              ...prev.filter((a) => a.chatId !== data.chatId),
              data,
            ])
            // _appointments = newAppointments
          }
          if (change.type === "removed") {
            const data = change.doc.data()
            setAppointments((prev) =>
              prev.filter((a) => a.chatId !== data.chatId)
            )
          }
        })
        setAppointments((prev) =>
          prev.sort((a, b) => (a.slotStart < b.slotStart ? -1 : 1))
        )
        setLoadingAppointments(false)
      },
    })
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [])

  useEffect(() => {
    const processCancelAppointment = async () => {
      try {
        const authToken = await firebase.getIdToken()
        const response = await fetch(appointmentsCancelUrl, {
          method: "POST",
          body: JSON.stringify({
            chatId: currentSession,
            user: user.isImpersonating ? user.impersonatingEmail : user.email,
            client: currentAppointment.clientEmail,
          }),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })

        if (response.status !== 200) {
          addToast(t("unexpected_error"), { appearance: "error" })
          return
        }
        const data = await response.json()
        if (!response.ok) {
          addToast(t("unexpected_error"), { appearance: "error" })
          return
        }
        addToast(t("session_succesfully_closed"), { appearance: "success" })
      } catch (error) {
        addToast(t("unexpected_error"), { appearance: "error" })
        window.Honeybadger?.notify(error)
      } finally {
        setWaitingPatient(false)
        setAppointments(
          appointments.filter(
            (a) => a.chatId !== currentSession || a.slotID !== currentSession
          )
        )
        setCurrentType(null)
        setCurrentSession(null)
        setCurrentAppointment(null)
        setCancelAction(null)
        setIsCancelling(false)
        setIsCancelModalOpen(false)
      }
    }

    if (currentAppointment && cancelAction != null) {
      if (cancelAction === "cancel") {
        processCancelAppointment()
      }
    }
  }, [cancelAction, user])

  const onChildAdded = (snapshot) => {
    const chatId = snapshot.key
    const chatData = snapshot.val()
    if (!has(chats, chatId)) {
      const newChat = {
        [chatId]: {
          id: chatId,
          ...chatData,
        },
      }

      if (chatData && chatData.status === "active") {
        const createAppointmentData = {
          clientDisplayName: chatData?.clientName,
          slotStart: chatData?.startedAt,
          slotID: chatData?.slotID,
          isActive: true,
        }
        showNewAppointmentNotification(createAppointmentData)
      }

      setChats((currentValues) => ({
        ...currentValues,
        ...newChat,
      }))
    }
  }

  const onError = () => {
    addToast(t("unexpected_error"), { appearance: "error" })
  }

  useEffect(() => {
    if (state && state.session && Object.keys(chats).length > 0) {
      setCurrentSession(state.session)
    }
  }, [chats])

  useEffect(() => {
    if (firebase) {
      firebase.getMessages({
        chat: currentSession,
        messagesLimit:
          customLimit > 0 ? customLimit : constants.chats.MESSAGE_LIMIT,
        onceValue: (snapshot) => {
          const msgTotal = snapshot.numChildren()
          let localMessageKeys = {}
          const sortedMessages = sortBy(snapshot.val(), ["createdAt"])
          if (loadingEarlier) {
            dispatch(setFirstMessageKey(sortedMessages?.[0]?._id))
            setLoadingEarlier(false)
          }

          // console.log("sortedMessages ==>", sortedMessages)
          if (msgTotal && msgTotal > 0 && currentType !== "agenda") {
            // Normal sessions
            sortedMessages.forEach((msg, index) => {
              if (!has(localMessageKeys, msg._id)) {
                localMessageKeys = merge(localMessageKeys, { [msg._id]: msg })
              }
              if (index === msgTotal - 1 || msgTotal === 0) {
                setMessages(sortedMessages)
                setMessageKeys(localMessageKeys)
                setWaitingPatient(false)
                setloadingMessages(false)
              }
            })
            forEach(sortedMessages, (msg) => {
              if (!has(localMessageKeys, msg._id)) {
                localMessageKeys = merge(localMessageKeys, { [msg._id]: msg })
              }
            })
          } else {
            setMessages([])
            setMessageKeys({})
            setloadingMessages(false)
            // setWaitingPatient(false)
          }
        },
      })
    }
  }, [currentSession, customLimit, firebase])

  useEffect(() => {
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      const ref = firebase.subscribeToChats()
      setChatsRef(ref)
    }
  }, [firebase, userType])

  useEffect(() => {
    if (chatsRef) {
      chatsRef
        .orderByChild("provider")
        .equalTo(user.isImpersonating ? user.impersonatingUID : user.uid)
        .on("child_added", onChildAdded, onError)
    }

    return () => {
      if (chatsRef) {
        chatsRef.off("child_added", onChildAdded)
      }
    }
  }, [chatsRef])

  useEffect(() => {
    const onChildChanged = async (snapshot) => {
      const chatId = snapshot.key
      const chatData = snapshot.val()
      const newChat = {
        id: chatId,
        ...chatData,
      }
      if (
        chatId === currentSession &&
        (newChat.status === "finished" || newChat.status === "cancelled")
      ) {
        setCurrentSession(null)
      }
      if (newChat.status === "cancelled") {
        const Settings = await firebase.getSettings({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
          key: "notifications",
        })
        if (Settings.data() && Settings.data().onCancelDateBrowser) {
          const body = `${get(newChat, "clientName")} ${t("was_cancelled")}`
          showBrowserNotification(t("your_appointment_with"), body)
        }
      }
      setChats((currentValues) => ({
        ...currentValues,
        [chatId]: newChat,
      }))
    }

    if (chatsRef) {
      chatsRef
        .orderByChild("provider")
        .equalTo(user.isImpersonating ? user.impersonatingUID : user.uid)
        .on("child_changed", onChildChanged, onError)
    }

    return () => {
      if (chatsRef) {
        chatsRef.off("child_changed", onChildChanged)
      }
    }
  }, [chatsRef, currentSession])

  useEffect(() => {
    if (
      user &&
      userType === "medicalAssistant" &&
      !get(user, "isImpersonating", false)
    ) {
      history.push("/dashboard")
    }
  }, [userType, get(user, "isImpersonating")])

  const activeChats = Object.values(chats)
    .filter((chat) => chat.status === "active")
    .sort((a, b) => b.modifiedAt - a.modifiedAt)

  return (
    <SectionsDoubleContainer>
      <Toolbar>
        <SectionTitle withPadding>{t("web_client.next_sessions")}</SectionTitle>
        <MessageContainer>
          {!loadingChats &&
            user.medicProfile &&
            get(user, ["medicProfile", "profession"]) === "" && (
              <>
                {/* <StyledImage fluid={data.allFile.edges.find((i) => i.node.name === 'create-services').node.childImageSharp.fluid} id="create-services" /> */}
                <p>
                  Para recibir consultas debes crear primero un servicio médico.
                </p>
              </>
            )}
          {Object.keys(activeChats).map((c, i) => (
            <Card
              key={`chat-${i}`}
              handleCardClick={onCardClick}
              session={activeChats[c]}
              isActive={
                !currentSession ? true : currentSession === activeChats[c].id
              }
              type="app"
              readOnlyChat={false}
              sessionType={activeChats[c].sessionType}
            />
          ))}
          {!loadingAppointments &&
            appointments &&
            appointments.length > 0 &&
            appointments.map((c, index) => (
              <Card
                key={`chat-apt-${c.chatId || index}`}
                handleCardClick={onCardClick}
                session={c}
                isActive={
                  !currentSession
                    ? true
                    : currentSession === c.chatId || currentSession === c.slotID
                }
                type="agenda"
                status={c.status}
                service={c.providerProfession}
                sessionType={c.type}
              />
            ))}
          {questions?.length > 0 &&
            questions.map((question) => (
              <QuestionCard
                key={`question-${question.id}`}
                question={question}
              />
            ))}
        </MessageContainer>
      </Toolbar>
      <Container>
        <ChatWrapper isChat={currentSession}>
          {!currentSession && !isReturning && !waitingPatient && (
            <Dashboard user={user} />
          )}
          {currentType === "agenda" && waitingPatient && (
            <Waiting>
              <p>{t("appointment_details")}</p>
              <p>{`${t("you_have_an_appointment")} ${
                currentAppointment?.clientDisplayName
              }`}</p>
              <p>{`${t("date")} ${moment
                .unix(currentAppointment?.slotStart)
                .format("DD/MM/yyyy")}`}</p>
              <p>{`${t("time")} ${moment
                .unix(currentAppointment?.slotStart)
                .format("HH:mm")}`}</p>
              <ImportantWrapper>
                <ImportantText>{t("appointment_rules_provider")}</ImportantText>
                <div>
                  <SubmitButton
                    color="red"
                    isLoading={false}
                    type="button"
                    onClick={() => {
                      setIsCancelModalOpen(true)
                    }}
                  >
                    {t("cancel")}
                  </SubmitButton>
                </div>
              </ImportantWrapper>
              <StyledImage src="/images/product/doctor.png" key="doctor" />
            </Waiting>
          )}
          {currentSession && !waitingPatient && (
            <Chat
              messages={messages}
              messageKeys={messageKeys}
              session={currentSession}
              user={user}
              info={chats[currentSession]}
              customLimit={customLimit}
              setLoadMore={handleLoadMore}
              loadingMessages={loadingMessages}
              isReturning={!!isReturning}
              history={history}
              t={t}
              readOnlyChat={user.isImpersonating}
            />
          )}
        </ChatWrapper>
      </Container>
      {user && !user.timezone && (
        <TimeZoneModal user={user} firebase={firebase} />
      )}
      <Modal
        modalOpen={isCancelModalOpen}
        setModalOpen={setIsCancelModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>{t("appointment_cancel_warning")}</p>
              <div>
                <SubmitButton
                  small
                  color={theme.color.secondary}
                  isLoading={isCancelling}
                  type="button"
                  onClick={() => {
                    setIsCancelling(true)
                    setCancelAction("cancel")
                  }}
                >
                  {t("session_cancel")}
                </SubmitButton>
                <SubmitButton
                  small
                  color={theme.color.secondary}
                  isLoading={false}
                  type="button"
                  onClick={() => {
                    setIsCancelModalOpen(false)
                  }}
                >
                  {t("close")}
                </SubmitButton>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </Modal>
      <ConfirmPaymentModal />
      <ConfirmActivationModal />
    </SectionsDoubleContainer>
  )
}

const condition = (authUser) => !!authUser
// && (get(authUser, ["medicProfile", "active"], false) || // User is active
//   (!get(authUser, ["medicProfile", "active"], false) &&
//     !get(authUser, ["medicProfile", "businessFormReady"], false))) // User is inactive with missing information
const CompSessions = compose(
  withFirebase,
  withAuthorization(condition)
)(Sessions)
export default CompSessions
