import get from "lodash.get"
import Select from "react-select"
import PropTypes from "prop-types"
import { compose } from "recompose"
import loadable from "@loadable/component"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import React, { useState, useEffect, useContext } from "react"
import { useHistory, withRouter, Prompt } from "react-router-dom"

// Components
import { SectionsContainer } from "../../atoms/Global"
import CustomModal from "../../atoms/CustomModal"
import {
  UserIcon,
  GlobeIcon,
  VideoIcon,
  ClockIcon,
  EditIcon,
  GearIcon,
  CheckIcon,
} from "../../atoms/Common"
import TimeZoneModal from "../../molecules/TimeZoneModal"
import Scheduler from "../../molecules/Scheduler"

// Utils
import constants from "../../../utils/constants"
import { withFirebase } from "../../../utils/Firebase"
import { getLocalTimeZone, getTimeZoneList } from "../../../utils/helpers"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import {
  appointmentsGetUrl,
  appointmentsUpdateUrl,
  honeybadger,
} from "../../../utils/App"
import { timeOptions } from "./options"
import Theme from "../../../styles/theme"
import {
  Container,
  ModalWrapper,
  ModalOptions,
  TabsContainer,
  ButtonTab,
  CronofyElementsContainer,
  ToolboxContainer,
  ToolWrapper,
  Tool,
  ItemsColumn,
  ActionsContainer,
  Action,
  CronofyOverlay,
} from "./styles"

const LoadableCronofyWrapper = loadable(() =>
  import("../../atoms/CronofyWrapper")
)
// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "transparent",
    marginTop: "1px",
    marginBottom: "2px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

const Agenda = ({ firebase }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const [ruleToShow, setRuleToShow] = useState(null)
  const [userTypeView, setUserTypeView] = useState("medical_work_hours")
  const [isModalOpen, setIsModalOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = useState(false)
  const [isTimezoneModalOpen, toggleTimezoneModal] = useState(false)
  const [userTimezone, setUserTimezone] = useState("")
  const [isModified, setIsModified] = useState(false)
  const [rulesToSave, setRulesToSave] = useState([])
  const [currentRule, setCurrentRule] = useState([])
  const [saveRulesModal, setSaveRulesModal] = useState(false)
  const [locationPathToGo, setLocationPathToGo] = useState("")
  const [timeSettings, setTimeSettings] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [rulesHourChanged, setRulesHourChanged] = useState(false)
  const [loadingAgendaData, setLoadingAgendaData] = useState(true)
  const [appointmentType, setAppointmentType] = useState("in_person")
  const [editMode, setEditMode] = useState(false)
  const userType = get(user, ["medicProfile", "userType"])
  const [profile, setProfile] = useState(null)
  const [feesFormValues, setFeesFormValues] = useState({
    // Consulta Virtual
    virtualAppointmentEnabled: false,
    virtualConsultation: 0,
    patient_time: 0,

    // Consulta Presencial
    facingConsultation: 0,
    patient_time_in_person: 0,

    // Visita a domicilio
    homeAppointmentEnabled: false,
    homeConsultation: 0,
    homeVisitTime: 0,

    // Servicio de preguntas
    questions: 0,
    questionsEnabled: false,

    chargeAdvanceAppointments: false,
    chargeCashAppointments: false,
  })

  const userTypeOptions = [
    {
      value: "medical_work_hours",
      label: t("type_patient"),
    },
    {
      value: "medical_visitors_hours",
      label: t("type_visitor"),
    },
  ]

  const appointmentTypeOptions = [
    {
      value: "in_person",
      label: t("apt_face_to_face"),
    },
    {
      value: "virtual",
      label: t("apt_virtual"),
    },
  ]

  const durationOptions = [
    {
      value: 15,
      label: t("web_client.minutes", { minutes: "15" }),
    },
    {
      value: 30,
      label: t("web_client.minutes", { minutes: "30" }),
    },
    {
      value: 45,
      label: t("web_client.minutes", { minutes: "45" }),
    },
    {
      value: 60,
      label: t("web_client.minutes", { minutes: "60" }),
    },
  ]

  const timezones = React.useMemo(() => getTimeZoneList(), [])
  const localTimeZone = React.useMemo(() => getLocalTimeZone(), [])

  useEffect(() => {
    if (!isTimezoneModalOpen) {
      const userTZ = get(
        user,
        "timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      if (userTZ) {
        setUserTimezone(userTZ)
      } else {
        const defaultTimezone = timezones.find(
          (timeZone) =>
            localTimeZone.name() === timeZone.name ||
            timeZone.group.includes(localTimeZone.name())
        )
        setUserTimezone(defaultTimezone.name)
      }
    }
  }, [user])

  const handleModalCancel = () => {
    setIsModalOpen(false)
    history.push("/sessions")
  }

  const handleFinish = () => {
    setIsModalOpen(false)
    const userEmail = user.isImpersonating
      ? user.impersonatingEmail
      : user.email
    window.location.href = `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${constants.cronofy.clientId}&redirect_uri=${constants.cronofy.redirectURL}/settings&scope=read_write&state=${userEmail}`
  }

  // eslint-disable-next-line no-unused-vars
  const checkRules = async () => {
    try {
      const authToken = await firebase.getIdToken()
      if (authToken) {
        const response = await fetch(appointmentsGetUrl, {
          method: "POST",
          body: JSON.stringify({
            user: user.isImpersonating ? user.impersonatingEmail : user.email,
          }),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
        const rules = await response.json()
        if (rules && rules.availability_rules.length > 0) {
          if (
            rules?.availability_rules?.some(
              (r) =>
                r?.availability_rule_id === `${userTypeView}_${appointmentType}`
            )
          ) {
            setCurrentRule(
              rules?.availability_rules?.find(
                (r) =>
                  r?.availability_rule_id ===
                  `${userTypeView}_${appointmentType}`
              )
            )
          }
          await firebase.updateProvider(
            { allowScheduling: true, indexDocument: true },
            user.isImpersonating
              ? user.impersonatingEmail.toLowerCase()
              : user.email.toLowerCase()
          )
        } else {
          await firebase.updateProvider(
            { allowScheduling: false, indexDocument: true },
            user.isImpersonating
              ? user.impersonatingEmail.toLowerCase()
              : user.email.toLowerCase()
          )
          addToast(t("web_client.add_rules"), { appearance: "info" })
        }
      }
    } catch (e) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const local = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return local.data()
      }
      const Profile = await getProfile()
      setProfile(Profile)
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    if (profile) {
      if (get(profile, ["cronofySub"])) {
        checkRules()
        setRuleToShow(0)
      } else {
        setIsModalOpen(true)
      }
    }
  }, [profile])

  useEffect(() => {
    const getTimeSettings = async () => {
      try {
        const fees = await firebase.getFees({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        })
        const feesData = fees.data()
        const virtualEnabled = get(feesData, "virtual.enabled", true)
        setFeesFormValues((currentValues) => ({
          ...currentValues,
          virtualConsultation: get(feesData, "virtual.consultationFee"),
          patient_time: get(feesData, "virtual.consultationTime"),
          virtualAppointmentEnabled: virtualEnabled,
          facingConsultation: get(feesData, "faceToFace.consultationFee"),
          patient_time_in_person: get(feesData, "faceToFace.consultationTime"),
          homeConsultation: get(feesData, "home.consultationFee"),
          homeVisitTime: get(feesData, "home.consultationTime"),
          homeAppointmentEnabled: get(feesData, "home.enabled"),
          questions: get(feesData, "questions.consultationFee"),
          questionsEnabled: get(feesData, "questions.enabled"),
          chargeAdvanceAppointments: get(feesData, "chargeAdvanceAppointments"),
          chargeCashAppointments: get(feesData, "chargeCashAppointments"),
        }))

        if (!virtualEnabled) {
          addToast(t("no_virtual_sessions"), { appearance: "info" })
        }

        const getSettings = await firebase.getSettings({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
          key: "times",
        })

        if (getSettings.data()) {
          setTimeSettings(getSettings.data())
        }
        setLoadingAgendaData(false)
      } catch (error) {
        setLoadingAgendaData(false)
      }
    }
    if (firebase) {
      setLoadingAgendaData(true)
      getTimeSettings()
      checkRules()
    }
  }, [firebase, userTypeView, appointmentType])

  const onChangeDuration = async (selectData, key) => {
    setFeesFormValues((currentValues) => ({
      ...currentValues,
      [key]: selectData.value,
    }))
    setTimeSettings((timeSettingsState) => ({
      ...timeSettingsState,
      [key]: selectData.value,
    }))
    setRulesHourChanged(true)
  }

  const updateMultiDay = (prevRule) => {
    const newRule = { ...prevRule }
    // bloquear no habilitar
    const startKey =
      appointmentType === "virtual" ? "start_time" : "start_time_in_person"
    const endKey =
      appointmentType === "virtual" ? "end_time" : "end_time_in_person"

    const newStartHourFormatted =
      timeSettings?.[startKey]?.toString()?.substring(0, 2) || 0
    const newEndHourFormatted =
      timeSettings?.[endKey]?.toString()?.substring(0, 2) || 0
    const prevRuleStartHourFormatted =
      prevRule.start_time?.toString()?.substring(0, 2) || 0
    const prevRuleEndHourFormatted =
      prevRule.end_time?.toString()?.substring(0, 2) || 0
    // Si el nuevo inicio es mayor a la hora de fin del horario actual
    // Si el nuevo fin es menor a la hora de inicio del horario actual
    if (
      (newStartHourFormatted &&
        newStartHourFormatted > prevRuleEndHourFormatted) ||
      (newEndHourFormatted && newEndHourFormatted < prevRuleStartHourFormatted)
    ) {
      return null
    }
    // Si la nueva hora de inicio es mayor a la hora de inicio actual y menor a la hora de fin del actual
    if (
      newStartHourFormatted &&
      newStartHourFormatted > prevRuleStartHourFormatted &&
      newStartHourFormatted <= prevRuleEndHourFormatted
    ) {
      newRule.start_time = timeSettings[startKey]
    }
    // Si la nueva hora de fin es menor a la hora final actual y mayor a la hora de inicio actual
    if (
      newEndHourFormatted &&
      newEndHourFormatted < prevRuleEndHourFormatted &&
      newEndHourFormatted >= prevRuleStartHourFormatted
    ) {
      newRule.end_time = timeSettings[endKey]
    }
    return newRule
  }

  const updateSingleDay = (rule) => {
    const newRule = { ...rule }
    const startKey =
      appointmentType === "virtual" ? "start_time" : "start_time_in_person"
    const endKey =
      appointmentType === "virtual" ? "end_time" : "end_time_in_person"
    const newStartHourFormatted =
      timeSettings?.[startKey]?.toString()?.substring(0, 2) || 0
    const ruleStartHourFormatted =
      rule.start_time?.toString()?.substring(0, 2) || 0
    const newEndHourFormatted =
      timeSettings?.[endKey]?.toString()?.substring(0, 2) || 0
    const ruleEndHourFormatted = rule.end_time?.toString()?.substring(0, 2) || 0
    if (
      newStartHourFormatted &&
      timeSettings?.[startKey] &&
      rule.start_time !== timeSettings[startKey] &&
      newStartHourFormatted > ruleStartHourFormatted
    ) {
      newRule.start_time = timeSettings[startKey]
    }
    if (
      newEndHourFormatted &&
      timeSettings?.[endKey] &&
      rule.end_time !== timeSettings[endKey] &&
      newEndHourFormatted < ruleEndHourFormatted
    ) {
      newRule.end_time = timeSettings[endKey]
    }
    return newRule
  }

  const updateCalendarRules = async (needsToRedirect) => {
    try {
      const authToken = await firebase.getIdToken()
      let newRules = {}
      // const startKey =
      //   appointmentType === "virtual" ? "start_time" : "start_time_in_person"
      // const endKey =
      //   appointmentType === "virtual" ? "end_time" : "end_time_in_person"
      if (rulesHourChanged) {
        const days = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ]
        const singleDays = days.filter(
          (day) =>
            currentRule?.weekly_periods?.filter((rule) => rule.day === day)
              .length === 1
        )
        const updatedPeriods = currentRule?.weekly_periods.map((rule) => {
          let updatedRule
          if (singleDays.includes(rule.day)) {
            updatedRule = updateSingleDay(rule)
          } else {
            const actualRule = rule
            // const nextRule = currentRule?.weekly_periods[index + 1]
            updatedRule = updateMultiDay(actualRule)
          }
          return updatedRule
        })
        newRules = {
          ...currentRule,
          weekly_periods: updatedPeriods.filter(
            (up) => up !== null && Object.keys(up)?.length > 0
          ),
        }
      } else {
        newRules = rulesToSave
      }
      await fetch(appointmentsUpdateUrl, {
        method: "POST",
        body: JSON.stringify({
          user: user.isImpersonating ? user.impersonatingEmail : user.email,
          availability_rule_id: newRules.availability_rule_id,
          tzid: newRules.tzid,
          weekly_periods: newRules.weekly_periods,
        }),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      await firebase.updateProvider(
        { allowScheduling: true, indexDocument: true },
        user.isImpersonating
          ? user.impersonatingEmail.toLowerCase()
          : user.email.toLowerCase()
      )
      setSaveRulesModal(false)
      setRulesHourChanged(false)
      checkRules()
      addToast(t("web_client.saved_successfully"), { appearance: "success" })
      if (needsToRedirect) {
        setTimeout(() => {
          history.push(locationPathToGo)
        }, 1000)
      } else {
        setEditMode(false)
      }
    } catch (error) {
      honeybadger.notify(error)
      setSaveRulesModal(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleModalCancelSaveRules = () => {
    setIsModified(false)
    setRulesToSave([])
    setSaveRulesModal(false)
    history.push(locationPathToGo)
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (nextLocation.pathname !== "Agenda" && isModified) {
      setSaveRulesModal(true)
      setIsModified(false)
      setLocationPathToGo(nextLocation.pathname)
      return false
    }
    setLocationPathToGo(nextLocation.pathname)
    handleModalCancelSaveRules()
    return true
  }

  useEffect(() => {
    setLoadingAgendaData(true)
    setTimeout(() => {
      setLoadingAgendaData(false)
    }, 500)
  }, [i18n.language])

  return (
    <SectionsContainer>
      <>
        <Prompt when={isModified} message={handleBlockedNavigation} />
        {saveRulesModal && (
          <CustomModal
            modalOpen={saveRulesModal}
            setModalOpen={setIsModalOpen}
            showClose={false}
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{t("web_client.calendar_unsave_changes")}</p>
                  <div>
                    <button type="button" onClick={handleModalCancelSaveRules}>
                      {t("cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => updateCalendarRules(true)}
                    >
                      {t("confirm")}
                    </button>
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </CustomModal>
        )}
      </>
      <Container>
        <TabsContainer>
          <ButtonTab
            type="button"
            isActive={ruleToShow === 0}
            onClick={() => setRuleToShow(0)}
          >
            {t("availability")}
          </ButtonTab>
          {/* <ButtonTab
            type="button"
            isActive={ruleToShow === 1}
            onClick={() => setRuleToShow(1)}
          >
            {t("agenda")}
          </ButtonTab> */}
          <ButtonTab
            type="button"
            isActive={ruleToShow === 2}
            onClick={() => setRuleToShow(2)}
          >
            {t("agenda")}
          </ButtonTab>
          <ButtonTab
            type="button"
            isActive={ruleToShow === 3}
            onClick={() => setRuleToShow(3)}
            withMinWidth
          >
            {t("calendar_view")}
          </ButtonTab>
          {ruleToShow === 0 && (
            <ActionsContainer>
              {!editMode ? (
                <>
                  <Action onClick={() => setEditMode(true)}>
                    <EditIcon />
                    {t("edit")}
                  </Action>
                  <Action>
                    <GearIcon />
                    {t("web_client.menu_settings")}
                  </Action>
                </>
              ) : (
                <>
                  <Action onClick={() => setEditMode(false)}>{`X ${t(
                    "cancel"
                  )}`}</Action>
                  <Action
                    onClick={async () => {
                      setLoadingAgendaData(true)
                      try {
                        await firebase.saveProviderSettings(
                          timeSettings,
                          "times",
                          user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email
                        )
                        await firebase.saveFeesData({
                          email: user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email,
                          data: {
                            virtual: {
                              consultationTime:
                                Number(feesFormValues.patient_time) || 0,
                            },
                            faceToFace: {
                              consultationTime:
                                feesFormValues.patient_time_in_person,
                            },
                            home: {
                              consultationTime:
                                Number(feesFormValues.homeVisitTime) || 0,
                            },
                          },
                        })
                        if (
                          rulesHourChanged ||
                          (rulesToSave && Object.keys(rulesToSave).length >= 1)
                        ) {
                          await updateCalendarRules(false)
                        } else {
                          setEditMode(false)
                        }
                        setLoadingAgendaData(false)
                      } catch {
                        setLoadingAgendaData(false)
                        addToast(t("unexpected_error"), { appearance: "error" })
                      }
                    }}
                  >
                    <CheckIcon />
                    {t("save")}
                  </Action>
                </>
              )}
            </ActionsContainer>
          )}
        </TabsContainer>
        {ruleToShow === 0 && (
          <ToolboxContainer>
            <ToolWrapper>
              <Tool>
                <UserIcon />
                <ItemsColumn isFirstRow>
                  <p>{t("client_label")}</p>
                  <Select
                    styles={customStyles}
                    defaultValue={userTypeView}
                    value={userTypeOptions.find(
                      (option) => option.value === userTypeView // get(timeSettings, "medical_visitor_time")
                    )}
                    options={userTypeOptions}
                    onChange={(option) => setUserTypeView(option.value)}
                    width="200px"
                    borderRadius="20px"
                    isDisabled
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <VideoIcon />
                <ItemsColumn isFirstRow>
                  <p>{t("type_label")}</p>
                  <Select
                    label={t("type_label")}
                    styles={customStyles}
                    defaultValue={appointmentTypeOptions[0]}
                    value={appointmentTypeOptions.find(
                      (option) => option.value === appointmentType // get(timeSettings, "medical_visitor_time")
                    )}
                    options={appointmentTypeOptions}
                    onChange={(option) => setAppointmentType(option.value)}
                    width="250px"
                    borderRadius="20px"
                    isDisabled={!feesFormValues?.virtualAppointmentEnabled}
                  />
                </ItemsColumn>
              </Tool>
            </ToolWrapper>
            <ToolWrapper>
              <Tool>
                <VideoIcon />
                <ItemsColumn>
                  <p>{t("web_client.duration")}</p>
                  <Select
                    label={t("web_client.duration")}
                    styles={customStyles}
                    defaultValue={durationOptions[0]}
                    value={durationOptions.find(
                      (option) =>
                        option.value ===
                        get(
                          feesFormValues,
                          userTypeView === "medical_work_hours"
                            ? appointmentType === "virtual"
                              ? "patient_time"
                              : "patient_time_in_person"
                            : appointmentType === "virtual"
                            ? "medical_visitor_time"
                            : "medical_visitor_time_in_person",
                          30
                        )
                    )}
                    options={durationOptions}
                    onChange={(option) =>
                      onChangeDuration(
                        option,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "patient_time"
                            : "patient_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_time"
                          : "medical_visitor_time_in_person"
                      )
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <ClockIcon />
                <ItemsColumn>
                  <p>{t("start_time")}</p>
                  <Select
                    label={t("start_time")}
                    styles={customStyles}
                    defaultValue={timeOptions[0]}
                    value={timeOptions.find(
                      (option) =>
                        option.value ===
                        get(
                          timeSettings,
                          userTypeView === "medical_work_hours"
                            ? appointmentType === "virtual"
                              ? "start_time"
                              : "start_time_in_person"
                            : appointmentType === "virtual"
                            ? "medical_visitor_start_time"
                            : "medical_visitor_start_time_in_person",
                          "09:00"
                        )
                    )}
                    options={timeOptions}
                    onChange={(option) =>
                      onChangeDuration(
                        option,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "start_time"
                            : "start_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_start_time"
                          : "medical_visitor_start_time_in_person"
                      )
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <ClockIcon />
                <ItemsColumn>
                  <p>{t("end_time")}</p>
                  <Select
                    label={t("end_time")}
                    styles={customStyles}
                    defaultValue={timeOptions[0]}
                    value={timeOptions.find(
                      (option) =>
                        option.value ===
                        get(
                          timeSettings,
                          userTypeView === "medical_work_hours"
                            ? appointmentType === "virtual"
                              ? "end_time"
                              : "end_time_in_person"
                            : appointmentType === "virtual"
                            ? "medical_visitor_end_time"
                            : "medical_visitor_end_time_in_person",
                          "19:00"
                        )
                    )}
                    options={timeOptions}
                    onChange={(option) =>
                      onChangeDuration(
                        option,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "end_time"
                            : "end_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_end_time"
                          : "medical_visitor_end_time_in_person"
                      )
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <GlobeIcon />
                {timezones && timezones.length > 0 && (
                  <ItemsColumn>
                    <p>{t("timezone")}</p>
                    <button
                      style={{
                        marginTop: 11,
                        paddingLeft: 10,
                        cursor: editMode ? "pointer" : "default",
                      }}
                      type="button"
                      onClick={() => toggleTimezoneModal(true)}
                      disabled={!editMode}
                    >
                      {userTimezone}
                    </button>
                  </ItemsColumn>
                )}
              </Tool>
            </ToolWrapper>
          </ToolboxContainer>
        )}
        <CronofyElementsContainer isEdit={editMode} isAgenda={ruleToShow === 2}>
          {(userTypeView === "medical_work_hours" ||
            userTypeView === "medical_visitors_hours") &&
            ruleToShow === 0 &&
            !loadingAgendaData && (
              <div style={{ position: "relative" }} className="notranslate">
                <CronofyOverlay isEdit={editMode} />
                <LoadableCronofyWrapper
                  withOptions
                  isCalendar
                  cronofyPermissions={["managed_availability"]}
                  cronofyElement="AvailabilityRules"
                  cronofyTargetId="cronofy-availability-viewer"
                  cronofyOptions={{
                    locale: i18n.language || "es",
                    availability_rule_id: `${userTypeView}_${appointmentType}`,
                    config: {
                      start_time: get(
                        timeSettings,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "start_time"
                            : "start_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_start_time"
                          : "medical_visitor_start_time_in_person",
                        "09:00"
                      ),
                      end_time: get(
                        timeSettings,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "end_time"
                            : "end_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_end_time"
                          : "medical_visitor_end_time_in_person",
                        "19:00"
                      ),
                      duration: get(
                        feesFormValues,
                        userTypeView === "medical_work_hours"
                          ? appointmentType === "virtual"
                            ? "patient_time"
                            : "patient_time_in_person"
                          : appointmentType === "virtual"
                          ? "medical_visitor_time"
                          : "medical_visitor_time_in_person",
                        30
                      ),
                    },
                    styles: {
                      colors: {
                        available: Theme.color.white.regular,
                        unavailable: Theme.color.agenda.unavailable,
                      },
                      prefix: "DocRules",
                    },
                    callback: async (res) => {
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_edited"
                        )
                      ) {
                        setIsModified(true)
                        setRulesToSave(res.availability_rule)
                        setCurrentRule(res.availability_rule)
                      }
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_saved"
                        )
                      ) {
                        await firebase.updateProvider(
                          { allowScheduling: true, indexDocument: true },
                          user.isImpersonating
                            ? user.impersonatingEmail.toLowerCase()
                            : user.email.toLowerCase()
                        )
                        setIsModified(false)
                        setEditMode(false)
                        setRulesToSave([])
                        setCurrentRule(res?.availability_rule)
                        addToast(t("web_client.saved_successfully"), {
                          appearance: "success",
                        })
                      }
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_not_found"
                        )
                      ) {
                        setRulesToSave({
                          availability_rule_id: `${userTypeView}_${appointmentType}`,
                          calendar_ids: [],
                          tzid: userTimezone,
                          weekly_periods: [
                            {
                              day: "monday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "tuesday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "wednesday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "thursday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "friday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                          ],
                        })
                        setEditMode(true)
                        addToast(t("rulte_not_found"), {
                          appearance: "info",
                        })
                      }
                      setNotification(JSON.stringify(res.notification))
                    },
                    tzid: userTimezone,
                  }}
                  key="cronofy-availability-viewer"
                >
                  <div
                    style={{ width: "100%", height: "auto" }}
                    id="cronofy-availability-viewer"
                    className="notranslate"
                  />
                </LoadableCronofyWrapper>
              </div>
            )}
          {ruleToShow === 2 && (
            <LoadableCronofyWrapper
              isCalendar
              cronofyPermissions={["agenda"]}
              cronofyElement="Agenda"
              cronofyTargetId="cronofy-agenda"
              key="cronofy-agenda"
              cronofyOptions={{
                tzid: userTimezone,
              }}
            >
              <div
                style={{ width: "100%", height: "auto" }}
                id="cronofy-agenda"
              />
            </LoadableCronofyWrapper>
          )}
          {ruleToShow === 3 && <Scheduler />}
        </CronofyElementsContainer>
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("web_client.calendar_sync")}</p>
                <div>
                  <button type="button" onClick={handleModalCancel}>
                    {t("cancel")}
                  </button>
                  <button type="button" onClick={handleFinish}>
                    {t("confirm")}
                  </button>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      <TimeZoneModal
        user={user}
        firebase={firebase}
        isOpen={isTimezoneModalOpen}
        onFinish={(z) => {
          setUserTimezone(z)
          toggleTimezoneModal(false)
        }}
      />
    </SectionsContainer>
  )
}

Agenda.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompAgenda = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(Agenda)
export default CompAgenda
