/* eslint-disable no-underscore-dangle */
import get from "lodash.get"
import merge from "lodash.merge"
import replace from "lodash.replace"
import trim from "lodash.trim"
import isNumber from "lodash.isnumber"
import isEmpty from "lodash.isempty"
import timezone from "jstz"
import { getTimeZones } from "@vvo/tzdb"
import constants from "./constants"

export const getSectionAffix = (index) =>
  `${index < 9 ? `0${index + 1}` : index + 1}`
export const isClient = typeof window !== "undefined"
export const toKebabCase = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "")
export const getSectionId = (index, title) =>
  `${getSectionAffix(index)}-${toKebabCase(title)}`
export const formattedDated = (type, dates) => {
  const startDate = dates.start.toDate()
  const endDate = dates.end ? dates.end.toDate() : null
  if (type.includes("day")) {
    return `${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()}`
  }
  if (type.includes("month")) {
    return `${startDate.getDate()}/${startDate.getMonth()} - ${endDate.getDate()}/${endDate.getMonth()}`
  }
  return `${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()}`
}
export const debounce = (delay, fn) => {
  let timeout
  return function resolve(...args) {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      fn(...args)
      timeout = null
    }, delay)
  }
}

export const secondsToDate = (firebaseDate, field) => {
  if (firebaseDate !== null) {
    const seconds = get(firebaseDate, field, null)
    if (seconds !== null && typeof Number) {
      return new Date(seconds * 1000)
    }
  }
  return null
}

export const handleErrorsForm = (e, t) => {
  switch (e.code) {
    case "auth/email-already-exists":
      return t("error_email_already_in_use")
    case "auth/email-already-in-use":
      return t("error_email_already_in_use")
    case "auth/invalid-email":
      return t("error_auth_invalid_email")
    case "auth/user-disabled":
      return t("error_auth_user_disabled")
    case "auth/weak-password":
      return t("web_client.error_auth_user_invalid_password")
    case "auth/user-not-found":
    case "auth/wrong-password":
      return t("error_auth_user_not_found_or_wrong_password")
    default:
      return e.message
  }
}

export const prepareMessage = (
  user,
  serverTimeOffset,
  message,
  type,
  targetTokens
) => {
  // Common message properties
  let msgBody = {
    _id: message.uploadId || message._id, // || uuid.v4(), This is the message ID

    // This is the sender Data
    user: message.user || {
      _id: user.email,
      name: get(user, ["medicProfile", "displayName"], user.displayName),
    },

    // These are the recipent tokens for sending push notifications
    tokens: targetTokens || [],

    // The message type
    type: type || get(message, "type", "text"),

    createdAt: message.uploading ? Date.now() : Date.now() + serverTimeOffset,
  }

  switch (msgBody.type) {
    // When sending audio files
    case "audio":
      msgBody = merge(msgBody, {
        audio: get(message, "url"),
        uploadId: get(message, "uploadId", "*"),
        mime: get(message, "mime"),
        filename:
          get(message, "originalFileName") ||
          get(message, "originalPath", "").split("/").pop(),
        filepath: get(message, "originalPath", ""),
      })
      break

    // When sending image files
    case "image":
      msgBody = merge(msgBody, {
        thumb: get(message, "url"),
        image: replace(get(message, "url"), "-thumb.", "-original."),
        uploadId: get(message, "uploadId", "*"),
        mime: get(message, "mime"),
        filename:
          get(message, "originalFileName") ||
          get(message, "originalPath", "").split("/").pop(),
        filepath: get(message, "originalPath", ""),
      })
      break

    // When sending video files
    case "video":
      msgBody = merge(msgBody, {
        // thumb: get(message, 'url'),
        thumb: null,
        video: get(message, "url"),
        uploadId: get(message, "uploadId", "*"),
        mime: get(message, "mime"),
        filename:
          get(message, "originalFileName") ||
          get(message, "originalPath", "").split("/").pop(),
        filepath: get(message, "originalPath", ""),
      })
      break

    // When sending video files
    case "document":
    case "other":
      msgBody = merge(msgBody, {
        document: get(message, "url"),
        uploadId: get(message, "uploadId", "*"),
        mime: get(message, "mime"),
        filename:
          get(message, "originalFileName") ||
          get(message, "originalPath", "").split("/").pop(),
        filepath: get(message, "originalPath", ""),
      })
      break

    // When sending a tip or recommendation
    case "tip":
      msgBody = merge(msgBody, {
        tip: trim(get(message, "tip", "")),
      })
      break

    // When sending location
    case "location":
      msgBody = merge(msgBody, {
        location: get(message, "location.coords", null),
        uploadId: get(message, "uploadId", "*"),
      })
      break

    // When sending system messages
    case "system":
      msgBody = merge(msgBody, {
        text: trim(get(message, "text", "")),
        system: true,
        flag: get(message, "flag", null),
      })
      break

    // When user is sending his/her medicalForm
    case "medical":
      msgBody = merge(msgBody, {
        medical: get(message, "form", null),
      })
      break

    // When doctor creates a new prescription
    case "loaderPrescription":
      msgBody = merge(msgBody, {
        prescription: get(message, "form", null),
        sentDate: get(message, "sentDate", null),
      })
      break

    // When doctor creates a new clinical study
    case "loaderClinical":
      msgBody = merge(msgBody, {
        clinical: get(message, "form", null),
        sentDate: get(message, "sentDate", null),
      })
      break

    // When doctor creates a new medical image reference
    case "loaderMedicalImages":
      msgBody = merge(msgBody, {
        medicalImages: get(message, "form", null),
        sentDate: get(message, "sentDate", null),
      })
      break

    // When sending regular text messages
    default:
      if (isEmpty(trim(get(message, "text", "")))) {
        msgBody = merge(msgBody, {
          empty: true,
        })
      } else {
        msgBody = merge(msgBody, {
          text: trim(get(message, "text", "")),
        })
      }
  }
  return msgBody
}

export const ToDecimals = (number) => {
  const numberString = Number(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&.")

  return numberString
}

export const getLocalTimeZone = () => timezone.determine()

export const getTimeZoneList = () => getTimeZones()

const getValueFromObject = (dataObject, key, defaultValue = null) =>
  get(dataObject, key, defaultValue)

export const checkMissingData = (profile, specificSection) => {
  const bankData = {
    personal_data: {
      name: getValueFromObject(profile, "name"),
      surname1: getValueFromObject(profile, "surname1"),
      surname2: getValueFromObject(profile, "surname2"),
      gender: getValueFromObject(profile, "gender"),
      country: getValueFromObject(profile, "country"),
      idCard: getValueFromObject(profile, "idCard"),
      phoneNumber: getValueFromObject(profile, "phoneNumber"),
      email: getValueFromObject(profile, "email"),
      photoURL: getValueFromObject(profile, "photoURL"),
    },
    academic: {
      personalSign: getValueFromObject(profile, "personalSign"),
      title: getValueFromObject(profile.academic, "title"),
      profession: getValueFromObject(profile.academic, "profession"),
      bureau: getValueFromObject(profile.academic, "bureau"),
      university: getValueFromObject(profile.academic, "university"),
      license: getValueFromObject(profile.academic, "license"),
    },
    business: {
      countryBusiness: getValueFromObject(profile, "countryBusiness"),
      address: getValueFromObject(profile.business, "address"),
    },
    fees: {
      bankName: getValueFromObject(profile.business, "bankName"),
      bankAccountType: getValueFromObject(profile.business, "bankAccountType"),
      bankAccountCurrency: getValueFromObject(
        profile.business,
        "bankAccountCurrency"
      ),
      bankAccount: getValueFromObject(profile.business, "bankAccount"),
    },
  }

  const propertiesMissing = []
  const sectionsMissing = []
  if (specificSection) {
    Object.keys(bankData[specificSection]).forEach((value) => {
      if (
        !isNumber(bankData[specificSection][value]) &&
        isEmpty(bankData[specificSection][value])
      ) {
        propertiesMissing.push(value)
        sectionsMissing.push(specificSection)
      }
      return value
    })
    return { sectionsMissing, propertiesMissing }
  }

  Object.keys(bankData).forEach((section) => {
    Object.keys(bankData[section]).forEach((value) => {
      if (
        !isNumber(bankData[section][value]) &&
        isEmpty(bankData[section][value])
      ) {
        propertiesMissing.push(value)
        sectionsMissing.push(section)
      }
      return value
    })
  })

  return { sectionsMissing, propertiesMissing }
}

export const getCurrencyConversionAmount = (user, exchanges, sessionPrice) => {
  const getUserCountry = get(
    user,
    ["medicProfile", "countryBusiness"],
    user.country
  )

  const getCurrencyCode = constants.countries.find(
    (country) => country.value === getUserCountry
  )

  const currencyAmount = exchanges.options
    ? sessionPrice / exchanges.options[getUserCountry]
    : 0

  return {
    currencyAmount,
    currencyCode: get(getCurrencyCode, "currencyCode", ""),
  }
}

export const showBrowserNotification = (title, body) => {
  const browserURL =
    "https://firebasestorage.googleapis.com/v0/b/docv3-prod.appspot.com/o"

  try {
    return new Notification(title, {
      body,
      icon: `${browserURL}/emails%2Fbrand.png?alt=media&token=11d66f38-c4ec-42f1-a862-76d8c8cc570e`,
    })
  } catch (err) {
    console.log("err ==>", err)
  }

  return true
}

export const capitalizeAllWords = (sentence) => {
  if (sentence) {
    const words = trim(sentence).split(" ")
    if (words.length > 0) {
      return words
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ")
    }
    return sentence
  }
  return ""
}

export const convertLocalPriceToUsd = (localPrice, localExchangePrice) => {
  const localPriceConverted = parseFloat(
    Number(localPrice) / Number(localExchangePrice)
  )

  return localPriceConverted
}

export const convertUsdToLocalPrice = (usdPrice, localExchangePrice) => {
  const usdPriceConverted = parseFloat(
    Number(usdPrice) * Number(localExchangePrice)
  )

  return usdPriceConverted
}

export const getParameterByName = (key) =>
  new URL(window.location.href).searchParams.get(key)
