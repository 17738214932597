import React, { useEffect, useState } from "react"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import {
  Box,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  VStack,
  Text,
} from "@chakra-ui/react"
import Countries from "./data/countries.json"

const CountryFormControl = chakra(FormControl, {
  baseStyle: {
    display: { base: "auto", md: "inline-flex" },
    mt: 2,
    mb: 2,
  },
})

const CountryFormLabel = chakra(FormLabel, {
  baseStyle: {
    minW: { base: "auto", md: "180px" },
    mt: 3,
    ml: 6,
  },
})

const CountrySelect = chakra(Select, {
  baseStyle: {
    minW: { base: "auto", md: "532px" },
    my: 1,
    border: "1px solid #E2E8F0 !important",
  },
})

const CountriesSelector = ({ onChange, errors, values, hasSubmitions }) => {
  const { t } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  useEffect(() => {
    if (values?.country) {
      const countrySelected = Countries.find(
        (country) => country.iso2 === values.country
      )
      if (countrySelected) {
        setSelectedCountry(countrySelected)
      }
    }
  }, [values])

  useEffect(() => {
    if (selectedCountry) {
      setSelectedState(selectedCountry.states[0])
      setSelectedCity(selectedCountry.states[0].cities[0])
    }
  }, [selectedCountry])

  useEffect(() => {
    if (values?.state && selectedCountry && selectedCountry?.states) {
      const selectedStateValue = selectedCountry.states.find(
        (i) => i.name === values.state
      )
      if (selectedStateValue) {
        setSelectedState(selectedStateValue)
      }
    }
    if (values?.city && selectedState && selectedState?.cities) {
      const selectedCityValue = selectedState.cities.find(
        (i) => i.name === values.city
      )
      if (selectedCityValue) {
        setSelectedCity(selectedCityValue)
      }
    }
    if (values?.district && selectedCity && selectedCity?.districts) {
      const districtSelected = selectedCity.districts.find(
        (district) => district.name === values.district
      )
      setSelectedDistrict(districtSelected)
    }
  }, [selectedCountry, selectedState, selectedCity])

  const countryError = values?.country === null || get(errors, "country", false)
  const stateError = values?.state === null || get(errors, "state", false)
  const cityError = values?.city === null || get(errors, "city", false)
  const districtError = get(errors, "district", false)
  return (
    <>
      <CountryFormControl isInvalid={countryError && hasSubmitions >= 1}>
        <CountryFormLabel>
          <Text
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            color="#1A202C"
            mt="5px"
          >
            * {t("q7_business_country_label")}
          </Text>
        </CountryFormLabel>
        <VStack alignItems="flex-start">
          <Box>
            <CountrySelect
              placeholder={
                get(selectedCountry, "name", "") ||
                t("select_type", {
                  type: t("country"),
                })
              }
              onChange={(e) => {
                const countrySelected = Countries.find(
                  (country) => country.iso2 === e.target.value
                )
                onChange("country", get(countrySelected, "iso2"))
                setSelectedCountry(countrySelected)
                if (
                  get(selectedCountry, "name", false) !== countrySelected?.name
                ) {
                  setSelectedState(null)
                  setSelectedCity(null)
                  setSelectedDistrict("")
                  onChange("state", null)
                  onChange("city", null)
                  onChange("district", "")
                }
              }}
              id="country"
              name="country"
            >
              {Countries.map((country) => (
                <option key={country.id} value={country.iso2}>
                  {country.name}
                </option>
              ))}
            </CountrySelect>
          </Box>
          <Box>
            <FormErrorMessage mt={-1} mb={-2}>
              {countryError}
            </FormErrorMessage>
          </Box>
        </VStack>
      </CountryFormControl>
      {selectedCountry && (
        <CountryFormControl isInvalid={stateError && hasSubmitions >= 1}>
          <CountryFormLabel>
            <Text
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              color="#1A202C"
              mt="5px"
            >
              * {t(`web_client.country_state_label_${selectedCountry.iso2}`)}
            </Text>
          </CountryFormLabel>
          <VStack alignItems="flex-start">
            <Box>
              <CountrySelect
                placeholder={
                  get(selectedState, "name", "") ||
                  t(`web_client.country_state_label_${selectedCountry.iso2}`)
                }
                onChange={(e) => {
                  const stateSelected = selectedCountry.states.find(
                    (i) => i.name === e.target.value
                  )
                  onChange("state", stateSelected.name)
                  setSelectedState(stateSelected)
                }}
                id="state"
                name="state"
              >
                {selectedCountry.states.map((state) => (
                  <option key={state.id} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </CountrySelect>
            </Box>
            <Box>
              <FormErrorMessage mt={-1} mb={-2}>
                {stateError}
              </FormErrorMessage>
            </Box>
          </VStack>
        </CountryFormControl>
      )}
      {selectedCity && (
        <CountryFormControl isInvalid={cityError && hasSubmitions >= 1}>
          <CountryFormLabel>
            <Text
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              color="#1A202C"
              mt="5px"
            >
              * {t(`web_client.country_city_label_${selectedCountry.iso2}`)}
            </Text>
          </CountryFormLabel>
          <VStack alignItems="flex-start">
            <Box>
              <CountrySelect
                placeholder={t(
                  get(selectedCity, "name", "") ||
                    `web_client.country_city_label_${selectedCountry.iso2}`
                )}
                onChange={(e) => {
                  const selectedCityValue = selectedState.cities.find(
                    (i) => i.name === e.target.value
                  )
                  onChange("city", selectedCityValue.name)
                  setSelectedCity(selectedCityValue)
                }}
                id="city"
                name="city"
              >
                {selectedState.cities.map((city) => (
                  <option key={city.id} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </CountrySelect>
            </Box>
            <Box>
              <FormErrorMessage mt={-1} mb={-2}>
                {cityError}
              </FormErrorMessage>
            </Box>
          </VStack>
        </CountryFormControl>
      )}
      {selectedCity && selectedCity.districts && (
        <CountryFormControl isInvalid={districtError && hasSubmitions >= 1}>
          <CountryFormLabel>
            <Text
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              color="#1A202C"
              mt="5px"
            >
              * {t("web_client.country_district_label_CR")}
            </Text>
          </CountryFormLabel>
          <VStack alignItems="flex-start">
            <Box>
              <CountrySelect
                placeholder={
                  get(selectedDistrict, "name", "") ||
                  t("web_client.country_district_label_CR")
                }
                onChange={(e) => {
                  const selectedDistrictValue = selectedCity.districts.find(
                    (i) => i.name === e.target.value
                  )
                  onChange("district", selectedDistrictValue.name)
                  setSelectedDistrict(selectedDistrictValue)
                }}
                id="district"
                name="district"
              >
                {selectedCity.districts.map((district) => (
                  <option key={district.name} value={district.name}>
                    {district.name}
                  </option>
                ))}
              </CountrySelect>
            </Box>
            <Box>
              <FormErrorMessage mt={-1} mb={-2}>
                {stateError}
              </FormErrorMessage>
            </Box>
          </VStack>
        </CountryFormControl>
      )}
    </>
  )
}

export default CountriesSelector
