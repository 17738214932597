import React from "react"
import moment from "moment"
import { useHistory } from "react-router-dom"

// Components
import { Flex, Button } from "@chakra-ui/react"
import { ChatIcon } from "../../../../atoms/Common"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const Conversations = ({ chats, contact, isFromQuestions, session }) => {
  const history = useHistory()

  const getChatTime = (chat) => {
    if (chat?.startedAt && chat?.finishedAt) {
      return `${moment(chat.finishedAt).diff(
        moment(chat.startedAt),
        "minutes"
      )} minutos`
    }
    if (chat?.startedAt && !chat?.finishedAt && chat?.modifiedAt) {
      return `${moment(chat.modifiedAt).diff(
        moment(chat.startedAt),
        "minutes"
      )} minutos`
    }

    return "Dato no disponible"
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent={chats && chats.length === 0 ? "center" : "flex-start"}
      alignItems={chats && chats.length === 0 ? "center" : "flex-start"}
      height="calc(100vh - 240px)"
      width="100%"
      overflow="auto"
      mt="30px"
    >
      {chats && chats.length === 0 ? (
        <EmptyMessage>{`${
          contact?.type === "medical_visitor" ? "Visitador" : "Paciente"
        } no tiene histórico de citas aún.`}</EmptyMessage>
      ) : (
        chats
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((c) => (
            <DataRow mb="15px" key={c?.id}>
              <CustomText>
                {c?.startedAt &&
                  moment(c.startedAt).format("MMM DD, YYYY").toUpperCase()}
              </CustomText>
              <CustomText>
                {c?.startedAt &&
                  moment(c.startedAt).format("HH:mm:ss").toUpperCase()}
              </CustomText>
              <CustomText>{getChatTime(c)}</CustomText>
              <Button
                h="35px"
                width="48px"
                borderRadius="8px"
                padding="10px, 12px, 10px, 12px"
                background="#EDF2F7"
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="0px"
                opacity="0.8"
                _hover={{
                  background: "#EDF2F7",
                  transform: "scale(1.1)",
                  opacity: 1,
                }}
                onClick={() =>
                  history.push("/history", {
                    selectedSession: c.id,
                    contact,
                    isFromQuestions,
                    question: session,
                  })
                }
              >
                <ChatIcon />
              </Button>
            </DataRow>
          ))
      )}
    </Flex>
  )
}

export default Conversations
