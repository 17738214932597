import React, { useState, useRef, useEffect, useContext } from "react"
import * as Yup from "yup"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import SignaturePad from "react-signature-canvas"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  Image,
  Textarea,
  useMediaQuery,
} from "@chakra-ui/react"

import { SubmitButton, ModalWrapper, Buttons, ButtonWrapper } from "./styles"
import Theme from "../../../styles/theme"

// Components
import { WithChakra } from "../../../components/atoms/WithChakra"
import CustomModal from "../../../components/atoms/CustomModal"
import ImageUploader from "../../../components/molecules/ImageUploader"
import CountriesSelector from "../../../components/chakra/CountriesSelector"
import LocationSelector from "../../../components/chakra/LocationSelector"

// Utils
import { AuthUserContext } from "../../../context/Session"
import { honeybadger } from "../../../utils/App"

// Resources
import cameraPlaceholder from "../imgs/cameraPlaceholder.png"
import signaturePlaceHolder from "../imgs/signature_placeholder.png"

const PrescriptionInformationBase = ({
  goToBack,
  goToNext,
  firebase,
  userCreatedData,
  cardShowed,
  formValues,
  setNextCard,
}) => {
  const user = useContext(AuthUserContext)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const sigCanvas = useRef({})
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [coordsError, showCoordsError] = useState(false)
  const [userCoords, setUserCords] = useState({})

  const formRef = useRef()

  const handleSaveLocalSignature = (signImage) => {
    const fileName = `${userCreatedData.activationToken}-sign.png`
    const uploadDir = `providers/${userCreatedData.uid}`
    const uploadTask = firebase.uploadProviderFile({
      file: signImage,
      uploadDir,
      fileName,
    })
    uploadTask.on("state_changed", null, null, () => {
      firebase
        .getMediaURL({ uploadDir, fileName })
        .then((url) => {
          formRef.current.setFieldValue("personalSign", url)
        })
        .catch(() => {
          addToast(t("error_failed_file_upload"), { appearance: "error" })
        })
    })
  }

  const handleReadFile = (event) => {
    const file = event.target.files[0]
    if (file.type && file.type.indexOf("image") === -1) {
      return
    }

    const reader = new FileReader()

    reader.addEventListener("load", (e) => {
      formRef.current.setFieldValue({ personalSign: e.target.result })
      handleSaveLocalSignature(e.target.result)
    })

    reader.readAsDataURL(file)
  }

  const clear = () => {
    setIsSigned(false)
    sigCanvas.current.clear()
  }

  const save = () => {
    setIsButtonLoading(true)
    const signImage = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png")
    const fileName = `${userCreatedData.activationToken}-sign.png`
    const uploadDir = `providers/${userCreatedData.uid}`
    const uploadTask = firebase.uploadProviderFile({
      file: signImage,
      uploadDir,
      fileName,
    })
    uploadTask.on("state_changed", null, null, () => {
      firebase
        .getMediaURL({ uploadDir, fileName })
        .then((url) => {
          formRef.current.setFieldValue("personalSign", url)
          setIsButtonLoading(false)
          setIsModalOpen(false)
        })
        .catch(() => {
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    })
  }

  const getImagesSnapshots = async () => {
    let uploadBusinessDir = null
    let uploadBusinessStampDir = null
    let snapshotLogo = null
    let snapshotStamp = null

    if (formRef.current.values.companyLogo) {
      uploadBusinessDir = `providers/${userCreatedData.email}/business/${formRef.current.values.companyLogo.name}`
      snapshotLogo =
        Object.keys(formRef.current.values.companyLogo).length > 0
          ? await firebase.uploadToStorage(
              uploadBusinessDir,
              formRef.current.values.companyLogo.blob
            )
          : null
    }

    if (formRef.current.values.companyStamp) {
      uploadBusinessStampDir = `providers/${userCreatedData.email}/business/${formRef.current.values.companyStamp.name}`
      snapshotStamp =
        Object.keys(formRef.current.values.companyStamp).length > 0
          ? await firebase.uploadToStorage(
              uploadBusinessStampDir,
              formRef.current.values.companyStamp.blob
            )
          : null
    }

    const tempImageLogoURL = snapshotLogo
      ? await snapshotLogo.ref.getDownloadURL()
      : null
    const tempImageStampURL = snapshotStamp
      ? await snapshotStamp.ref.getDownloadURL()
      : null

    return {
      tempImageLogoURL,
      tempImageStampURL,
    }
  }

  useEffect(() => {
    if (formValues) {
      if (formValues) {
        formRef.current.resetForm({
          values: {
            personalSign: get(formValues, "personalSign", ""),
            // business
            companyLogo: get(formValues, "businessForm.companyLogo", ""),
            companyStamp: get(formValues, "businessForm.companyStamp", ""),
            country: get(formValues, "businessForm.address.country", null),
            state: get(formValues, "businessForm.address.state", null),
            city: get(formValues, "businessForm.address.city", null),
            district: get(formValues, "businessForm.address.district", "null"),
            addressDetails: get(
              formValues,
              "businessForm.address.addressDetails",
              ""
            ),
            coordinates: get(
              formValues,
              "businessForm.address.coordinates",
              ""
            ),
          },
        })
      }
    }
  }, [formValues])

  const successCallback = (position) => {
    setUserCords(position?.coords)
  }

  const errorCallback = () => {
    addToast(t("location_error"), { appearance: "error" })
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }, [])

  const getPicture = (formValuesData, picture, placeholderImg) => {
    if (formValuesData) {
      return formValuesData
    }

    if (picture && picture.blobUrl) {
      return picture.blobUrl
    }

    if (picture && !picture.blobUrl) {
      return picture
    }

    return placeholderImg
  }

  return (
    <AccordionItem borderTop="none">
      <Formik
        innerRef={formRef}
        initialValues={{
          personalSign: "",
          // business
          companyLogo: "",
          companyStamp: "",
          country: null,
          state: null,
          city: null,
          district: null,
          addressDetails: null,
          coordinates: null,
          // end business
        }}
        validationSchema={Yup.object({
          personalSign: Yup.string().required(t("error_all_fields_required")),
          country: Yup.string()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
          state: Yup.string()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
          city: Yup.string()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
          district: Yup.string().typeError(t("error_all_fields_required")),
          addressDetails: Yup.string()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
          coordinates: Yup.string()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
        })}
        onSubmit={async (values) => {
          try {
            if (
              values?.coordinates?.latitude ||
              values?.coordinates?.longitude
            ) {
              showCoordsError(false)
              if (userCreatedData) {
                setIsCreating(true)
                const snapshots = await getImagesSnapshots()

                await firebase.updateProvider(
                  {
                    registerStepCompleted: 3,
                    personalSign: values.personalSign,
                    countryBusiness: values.country,
                  },
                  userCreatedData.email.toLowerCase()
                )

                user.setUser({
                  ...user,
                  medicProfile: {
                    ...user.medicProfile,
                    personalSign: values.personalSign,
                  },
                })

                const companyLogo =
                  snapshots.tempImageLogoURL === null ||
                  snapshots.tempImageLogoURL.includes("undefined")
                    ? get(formValues, "businessForm.companyLogo", null)
                    : snapshots.tempImageLogoURL

                const companyStamp =
                  snapshots.tempImageStampURL === null ||
                  snapshots.tempImageStampURL.includes("undefined")
                    ? get(formValues, "businessForm.companyStamp", null)
                    : snapshots.tempImageStampURL

                const addressData = {
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  addressDetails: values.addressDetails,
                  coordinates: values.coordinates?.latitude
                    ? values.coordinates
                    : null,
                }
                if (values.district !== "null") {
                  Object.assign(addressData, { district: values.district })
                }
                await firebase.saveCompanyData({
                  email: userCreatedData.email,
                  data: {
                    companyLogo,
                    companyStamp,
                    address: addressData,
                  },
                })
                const updateLsUser = {
                  ...userCreatedData,
                  countryBusiness: values.country,
                  businessForm: {
                    address: addressData,
                  },
                }
                localStorage.setItem(
                  "userRegister",
                  JSON.stringify(updateLsUser)
                )
                localStorage.setItem("registerStepCompleted", 3)
                setIsCreating(false)
                goToNext()
              }
            } else {
              showCoordsError(true)
            }
          } catch (err) {
            honeybadger.notify("Register - Prescription Information Section", {
              message: "Error on Submit / Prescription Information Section",
              action: "submit",
              context: {
                formValues: values,
                error: err,
              },
            })
            setIsCreating(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        }}
      >
        {(props) => (
          <Form>
            <h2>
              <AccordionButton
                background="#FFF"
                _hover={{ background: "#FFF" }}
              >
                <Box flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    flex="1"
                    justifyContent="flex-start"
                    alignItems="center"
                    borderBottom="1px solid #EDF2F7"
                  >
                    <Flex
                      width="32px"
                      height="32px"
                      borderRadius="32px"
                      background={`${cardShowed >= 3 ? "#38A169" : "#000"}`}
                      color={`${cardShowed >= 3 ? "#000" : "#FFF"}`}
                      justifyContent="center"
                      alignItems="center"
                      marginRight="10px"
                    >
                      3
                    </Flex>
                    <Flex minHeight="84px" padding="10px 0" alignItems="center">
                      <Text fontSize="18px" fontWeight="700" color="#171923">
                        {t("web_client.register_prescription_title")}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <AccordionIcon
                  onClick={() => {
                    if (userCreatedData.registerStepCompleted > 2) {
                      setNextCard(2)
                      localStorage.setItem("registerStepCompleted", 2)
                    }
                  }}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderBottomRightRadius="10px"
              borderBottomLeftRadius="10px"
              padding="0px"
            >
              <Text
                fontSize="12px"
                fontWeight="400"
                color="#718096"
                margin="0 auto"
                width={isMobile ? "90%" : "504px"}
                textAlign="center"
              >
                {t("web_client.register_prescription_description")}
              </Text>
              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  height={isMobile ? "auto" : "147px"}
                >
                  <Flex
                    flexDirection="column"
                    alignItems={isMobile ? "center" : "flex-start"}
                    marginLeft="12px"
                  >
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="20px"
                          color="#1A202C"
                          marginBottom="16px"
                        >
                          {t("web_client.register_logo_title")}
                        </Text>
                        <ImageUploader
                          fieldName="companyLogo"
                          width={64}
                          height={64}
                          isRegister
                          src={getPicture(
                            get(formValues, "businessForm.companyLogo", null),
                            props.values.companyLogo,
                            cameraPlaceholder
                          )}
                          onImageLoaded={props.setFieldValue}
                        />
                      </Flex>
                      <Text fontSize="14px" fontWeight="400" color="#4A5568">
                        {t("web_client.register_logo_description")}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems={isMobile ? "center" : "flex-start"}
                    marginLeft="12px"
                  >
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="20px"
                          color="#1A202C"
                          marginBottom="16px"
                          textAlign="left"
                        >
                          {t("web_client.register_stamp_title")}
                        </Text>
                        <ImageUploader
                          fieldName="companyStamp"
                          width={64}
                          height={64}
                          isRegister
                          src={getPicture(
                            get(formValues, "businessForm.companyStamp", null),
                            props.values.companyStamp,
                            cameraPlaceholder
                          )}
                          onImageLoaded={props.setFieldValue}
                          showError={
                            !props.values.companyStamp && props.submitCount > 0
                          }
                        />
                      </Flex>
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="#4A5568"
                        width="161px"
                      >
                        {t("web_client.register_stamp_description")}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems={isMobile ? "center" : "flex-start"}
                    marginLeft="12px"
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="16px"
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text
                        fontSize="14px"
                        fontWeight="600"
                        lineHeight="20px"
                        color="#1A202C"
                      >
                        {t("web_client.signature")}
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        border={`1px solid ${
                          !props.values.personalSign && props.submitCount > 0
                            ? "#ff0000"
                            : "#E2E8F0"
                        }`}
                      >
                        <Image
                          objectFit="cover"
                          width="90px"
                          height="64px"
                          src={getPicture(
                            get(formValues, "personalSign", null),
                            props.values.personalSign,
                            signaturePlaceHolder
                          )}
                          alt="signature"
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                        width="242px"
                        height="60px"
                      >
                        <ButtonWrapper>
                          <Button
                            colorScheme="blue"
                            background="transparent"
                            width="100"
                            height="24px"
                            borderRadius="6px"
                            border="1px solid #E2E8F0"
                            color="#000"
                            fontSize="12px"
                            fontWeight="400"
                          >
                            {t("web_client.signature_local_file_button")}
                          </Button>
                          <input
                            type="file"
                            name="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleReadFile}
                          />
                        </ButtonWrapper>
                        <Button
                          colorScheme="blue"
                          background="transparent"
                          width="100"
                          height="24px"
                          borderRadius="6px"
                          border="1px solid #E2E8F0"
                          color="#000"
                          fontSize="12px"
                          fontWeight="400"
                          onClick={() => setIsModalOpen(true)}
                        >
                          {t("web_client.signature_draw_button")}
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Box
                  backgroundColor="#F7FAFC"
                  width="100%"
                  paddingLeft={isMobile ? "20px" : "0"}
                  marginTop="20px"
                >
                  <CountriesSelector
                    onChange={props.setFieldValue}
                    errors={props.errors}
                    values={props.values}
                    hasSubmitions={props.submitCount}
                  />
                </Box>
                <Box width="100%" backgroundColor="#F7FAFC">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width="97%"
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="600" color="#1A202C">
                        {t("address_street1_label")}
                      </Text>
                    </Flex>
                    <Box>
                      <Textarea
                        _hover={{}}
                        variant="outline"
                        _placeholder={{
                          color: "gray.500",
                        }}
                        borderColor={
                          props.errors.addressDetails && props.submitCount >= 1
                            ? "red.500"
                            : "#E2E8F0"
                        }
                        focusBorderColor="#E2E8F0"
                        borderRadius="6px"
                        color="gray.500"
                        fontSize="16px"
                        placeholder=""
                        width={isMobile ? "320px" : "528px"}
                        height="32px"
                        type="text"
                        name="addressDetails"
                        id="addressDetails"
                        onChange={(e) =>
                          props.setFieldValue("addressDetails", e.target.value)
                        }
                        defaultValue={get(
                          formValues,
                          "businessForm.address.addressDetails",
                          null
                        )}
                      />
                      {props.errors.addressDetails && props.submitCount >= 1 && (
                        <Text
                          fontSize="12px"
                          color="red.500"
                          fontWeight="500"
                          width="500px"
                        >
                          {props.errors.addressDetails}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Box
                  width="100%"
                  backgroundColor="#F7FAFC"
                  borderBottomRightRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width="97%"
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="600" color="#1A202C">
                        {t("address_street_cords")}
                      </Text>
                    </Flex>
                    <Box>
                      <LocationSelector
                        setCustomValue={props.setFieldValue}
                        errors={{ cordsError: coordsError }}
                        values={props.values}
                        userDefaultCoords={userCoords}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </AccordionPanel>
            <AccordionPanel pb={4}>
              {!isEmpty(props.errors) && props.submitCount >= 1 && (
                <Text
                  fontSize="12px"
                  color="rgb(98, 0, 238)"
                  fontWeight="500"
                  width="500px"
                  mb="20px"
                >
                  {t("error_all_fields_required_remember")}
                </Text>
              )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="220px"
              >
                <Button
                  colorScheme="blackAlpha"
                  width="104px"
                  height="40px"
                  color="#000"
                  fontSize="16px"
                  fontWeight="bold"
                  onClick={goToBack}
                >
                  {t("go_back")}
                </Button>
                <Button
                  colorScheme="blue"
                  background="#576EF5"
                  width="104"
                  height="40px"
                  borderRadius="6px"
                  color="#FFF"
                  fontSize="18px"
                  fontWeight="normal"
                  isLoading={isCreating}
                  type="submit"
                >
                  {t("next")}
                </Button>
              </Box>
            </AccordionPanel>
          </Form>
        )}
      </Formik>
      <CustomModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
        <ModalWrapper isFull>
          <SignaturePad
            ref={sigCanvas}
            onEnd={() => {
              setIsSigned(true)
            }}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          <Buttons>
            <SubmitButton
              isLoading={isButtonLoading}
              type="submit"
              onClick={save}
              color={Theme.color.secondary}
              disabled={!isSigned}
              small
            >
              {t("web_client.set_signature")}
            </SubmitButton>
            <SubmitButton
              isLoading={isButtonLoading}
              type="submit"
              onClick={clear}
              color={Theme.color.primary}
              small
            >
              {t("web_client.clear")}
            </SubmitButton>
          </Buttons>
        </ModalWrapper>
      </CustomModal>
    </AccordionItem>
  )
}

const PrescriptionInformation = compose(
  withRouter,
  WithChakra
)(PrescriptionInformationBase)
export default PrescriptionInformation
