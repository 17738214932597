/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from "react"
import * as Yup from "yup"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import { v4 as uuidv4 } from "uuid"
import CryptoJS from "crypto-js"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { withRouter } from "react-router-dom"
import { Formik, Form } from "formik"
import { Visibility } from "@styled-icons/material/Visibility"
import { VisibilityOff } from "@styled-icons/material/VisibilityOff"
import {
  Box,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  InputRightElement,
  InputGroup,
  FormControl,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react"

import { PasswordMessagesContainer, PasswordInfoMessage } from "./styles"

import { WithChakra } from "../../../components/atoms/WithChakra"

// Components
import Reference from "../../../components/chakra/Reference"
import CustomInput from "../../../components/chakra/CustomInput"
import ImageUploader from "../../../components/molecules/ImageUploader"
import CustomSelect from "../../../components/atoms/CustomSelect"

// Utils
import {
  createProvider,
  generatePassword,
  honeybadger,
} from "../../../utils/App"
import countries from "../../../utils/Constants/Countries"

import profilePlaceholder from "../imgs/profilePlaceholder.png"

const GeneralInformationBase = ({
  firebase,
  goToBack,
  goToNext,
  userTypeSelected,
  cardShowed,
  userEmailFromLogin,
  userCreatedData,
  setNextCard,
  setIsFromBack,
  isFromBack,
}) => {
  const createUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [isCreating, setIsCreating] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)
  const [emailAlreadyInUse, setEmailIsAlreadyUsed] = useState(false)

  const formRef = useRef()

  const genderOptions = [
    { value: "M", label: t("male") },
    { value: "F", label: t("female") },
  ]

  const isPhone = (value) =>
    // eslint-disable-next-line no-useless-escape
    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/.test(
      value
    )

  useEffect(() => {
    if (userCreatedData) {
      formRef.current.resetForm({
        values: {
          name: userCreatedData.name || "",
          surname1: userCreatedData.surname1 || "",
          surname2: userCreatedData.surname2 || "",
          email: userCreatedData.email || userEmailFromLogin,
          phoneNumber: userCreatedData.phoneNumber || "",
          gender: userCreatedData.gender || "",
          country: userCreatedData.country || "",
          password: userCreatedData.password || "",
          confirmPassword: userCreatedData.password || "",
          medicalAssistantName: userCreatedData.medicalAssistantName || "",
          medicalAssistantEmail: userCreatedData.medicalAssistantEmail || "",
          medicalAssistantLastName:
            userCreatedData.medicalAssistantLastName || "",
          reference: userCreatedData.reference || "noReference",
          referenceType: userCreatedData.referenceType || "noReference",
          referenceCountry: userCreatedData.referenceCountry || null,
          organizationReference: userCreatedData.organizationReference || null,
          organizationAgent: userCreatedData.organizationAgent || null,
          photoURL: userCreatedData.photoURL || null,
        },
      })
    }
  }, [userCreatedData])

  const createAssistant = async (encryptedUser) => {
    try {
      const token = await firebase.getIdToken()
      const getAssistant = await firebase.getProfile({
        email: get(encryptedUser, "medicalAssistantEmail"),
      })
      const assistantData = getAssistant.data()
      if (
        get(assistantData, "email") !==
        encryptedUser.medicalAssistantEmail.toLowerCase()
      ) {
        const getPassword = generatePassword()
        await fetch(createUserUrl, {
          method: "POST",
          body: JSON.stringify({
            user: {
              email: encryptedUser.medicalAssistantEmail.toLowerCase(),
              password: getPassword,
              displayName: `${encryptedUser.medicalAssistantName} ${encryptedUser.medicalAssistantLastName}`,
              phoneNumber: "",
              userType: "medicalAssistant",
            },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then(async () => {
          try {
            // This timeout gives time for the firebase trigger to be executed
            setTimeout(async () => {
              await fetch(mailerUrl, {
                method: "POST",
                body: JSON.stringify({
                  data: {
                    templateId: 2870998, // invite-assitant
                    email: encryptedUser.medicalAssistantEmail.toLowerCase(),
                    variables: {
                      subject:
                        "Te han invitado como asistente de la plataforma DOC",
                      providerName: encryptedUser.name,
                      displayName: `${encryptedUser.medicalAssistantName} ${encryptedUser.medicalAssistantLastName}`,
                      password: getPassword,
                      websiteLink: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
                      assistantName: encryptedUser.medicalAssistantName,
                    },
                  },
                }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "App-Environment":
                    process.env.NODE_ENV === "development" ? "stage" : "prod",
                  "Content-Type": "application/json",
                },
              })
            }, 2500)
          } catch (e) {
            addToast(t("unexpected_error"), {
              appearance: "error",
              placement: "bottom-center",
            })
          }
        })
      } else {
        // This timeout gives time for the firebase trigger to be executed
        setTimeout(async () => {
          await fetch(mailerUrl, {
            method: "POST",
            body: JSON.stringify({
              data: {
                templateId: 4322695, // invite-previously-created-assistant
                email: encryptedUser.medicalAssistantEmail.toLowerCase(),
                variables: {
                  subject:
                    "Te han invitado como asistente de la plataforma DOC",
                  providerName: encryptedUser.name,
                  displayName: `${encryptedUser.medicalAssistantName} ${encryptedUser.medicalAssistantLastName}`,
                  websiteLink: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
                  assistantName: encryptedUser.medicalAssistantName,
                },
              },
            }),
            headers: {
              Authorization: `Bearer ${token}`,
              "App-Environment":
                process.env.NODE_ENV === "development" ? "stage" : "prod",
              "Content-Type": "application/json",
            },
          })
        }, 2500)
      }

      const displayAssistantName = `${get(
        assistantData,
        "name",
        encryptedUser.medicalAssistantName
      )} ${get(
        assistantData,
        "surname1",
        encryptedUser.medicalAssistantLastName
      )}`

      await firebase.upsertMedicalAssistantProvider({
        providerEmail: encryptedUser.email.toLowerCase(),
        assistantData: {
          displayName: `${encryptedUser.medicalAssistantName} ${encryptedUser.medicalAssistantLastName}`,
          phoneNumber: "",
          email: encryptedUser.medicalAssistantEmail.toLowerCase(),
          active: true,
          name: encryptedUser.medicalAssistantName,
          surname1: encryptedUser.medicalAssistantLastName,
        },
      })

      await firebase.saveAssistantData({
        email: encryptedUser.medicalAssistantEmail.toLowerCase(),
        data: {
          displayName: displayAssistantName,
          name: get(assistantData, "name", encryptedUser.medicalAssistantName),
          surname1: get(
            assistantData,
            "surname1",
            encryptedUser.medicalAssistantLastName
          ),
          email: encryptedUser.medicalAssistantEmail.toLowerCase(),
          active: true,
          status: "active",
          country: encryptedUser.country,
          userType: "medicalAssistant",
          phoneNumber: "",
        },
        provider: {
          uid: encryptedUser.uid,
          displayName: `${encryptedUser?.name} ${encryptedUser?.surname1} ${encryptedUser?.surname2}`,
          email: encryptedUser.email.toLowerCase(),
          photoURL: encryptedUser.photoURL,
          profession: "",
          name: encryptedUser?.name,
          surname1: encryptedUser?.surname1,
          surname2: encryptedUser?.surname2,
          title: "",
          country: get(encryptedUser, "country", ""),
        },
      })

      addToast(t("web_client.saved_successfully"), {
        appearance: "success",
        placement: "bottom-center",
      })
    } catch (e) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
      setIsCreating(false)
    }
  }

  const sendConfirmationEmail = async (
    targetEmail,
    activationCode,
    userCreated
  ) => {
    try {
      const token = await firebase.getIdToken()
      await fetch(mailerUrl, {
        method: "POST",
        body: JSON.stringify({
          data: {
            templateId: 2856068, // Confirm Reference
            email: targetEmail,
            variables: {
              providerName: `${get(userCreated, "name", "")} ${get(
                userCreated,
                "surname1",
                ""
              )} ${get(userCreated, "surname2", "")}`,
              tokenLink: `https://${window.location.hostname}/confirm?code=${activationCode}&token=${targetEmail}`,
            },
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
    } catch (e) {
      window.Honeybadger?.notify(e)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowVerifyPassword = () => {
    setShowVerifyPassword(!showVerifyPassword)
  }

  const createAccount = async (values) => {
    const authToken = await firebase.getIdToken()
    const createdUser = await fetch(createProvider, {
      method: "POST",
      body: JSON.stringify({
        user: {
          email: values.email.toLowerCase(),
          password: CryptoJS.AES.encrypt(
            values?.password,
            "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
          ).toString(),
          displayName: `${values.name} ${values.surname1} ${values.surname2}`,
          name: values.name,
          surname1: values.surname1,
          surname2: values.surname2,
          phoneNumber: values.phoneNumber,
          country: values.country,
          userType: userTypeSelected,
          license: "",
        },
      }),
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    })
    const data = await createdUser.json()
    if (data.error) {
      setIsCreating(false)
      if (data.error.message.includes("The email address is already in use")) {
        addToast(t("error_email_already_in_use"), {
          appearance: "error",
        })
        setEmailIsAlreadyUsed(true)
      } else {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    } else {
      await firebase.login({
        email: values.email,
        password: values.password,
      })

      let tempImageURL = null
      if (values.photoURL) {
        const uploadDir = `providers/avatar/${data.createdUser.uid}/${values.photoURL.name}`
        const snapshot =
          Object.keys(values.photoURL).length > 0
            ? await firebase.uploadToStorage(uploadDir, values.photoURL.blob)
            : null
        tempImageURL = snapshot ? await snapshot.ref.getDownloadURL() : null
      }

      const code = uuidv4()

      const encryptedUser = {
        ...values,
        password: CryptoJS.AES.encrypt(
          values?.password,
          "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
        ).toString(),
        confirmPassword: CryptoJS.AES.encrypt(
          values?.confirmPassword,
          "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
        ).toString(),
        uid: data.createdUser.uid,
        photoURL: tempImageURL,
        activationToken: code,
      }

      await firebase.updateProvider(
        {
          photoURL: tempImageURL,
          reference: values.reference,
          referenceType: values.referenceType,
          activationToken: code,
          registerStepCompleted: 1,
          organizationReference: values.organizationReference,
          organizationAgent: values.organizationAgent,
          referenceCountry: get(values, "referenceCountry", ""),
          gender: values.gender,
          country: values.country,
        },
        values.email.toLowerCase()
      )

      if (values.referenceType === "medical_visitor") {
        if (values.organizationAgent) {
          await sendConfirmationEmail(
            values.organizationAgent,
            code,
            encryptedUser
          )
        }
      }

      if (encryptedUser && encryptedUser.medicalAssistantEmail) {
        await createAssistant(encryptedUser)
      }

      await firebase.saveFeesData({
        email: values.email.toLowerCase(),
        data: {
          virtual: {
            consultationFee: 0,
            consultationTime: null,
            enabled: false,
          },
          faceToFace: {
            consultationFee: 0,
            consultationTime: null,
            enabled: true, // faceToFace is required, so it always is enabled
          },
          home: {
            consultationFee: 0,
            consultationTime: null,
            enabled: false,
          },
          questions: {
            consultationFee: 0,
            enabled: false,
          },
          chargeAdvanceAppointments: false,
          chargeCashAppointments: false,
        },
      })

      localStorage.setItem("userRegister", JSON.stringify(encryptedUser))
      localStorage.setItem("registerStepCompleted", 1)
      setEmailIsAlreadyUsed(false)
      setIsCreating(false)
      goToNext()
    }
  }

  const updateUser = async (values) => {
    let userUpdated = null
    let tempImageURL = null
    if (values.password !== userCreatedData.password) {
      try {
        await firebase.updatePassword(values.password)

        const encryptedUser = {
          ...values,
          password: CryptoJS.AES.encrypt(
            values?.password,
            "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
          ).toString(),
        }
        userUpdated = encryptedUser
        addToast(t("password_updated"), { appearance: "success" })
      } catch (e) {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    try {
      const addValues = {
        ...userUpdated,
        ...values,
        uid: get(userCreatedData, "uid", ""),
        photoURL: get(userCreatedData, "photoURL", ""),
        activationToken: get(userCreatedData, "activationToken", ""),
        password: CryptoJS.AES.encrypt(
          values?.password,
          "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
        ).toString(),
        confirmPassword: CryptoJS.AES.encrypt(
          values?.confirmPassword,
          "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
        ).toString(),
        reference: get(values, "reference", ""),
        referenceType: get(values, "referenceType", ""),
        organizationReference: get(values, "organizationReference", ""),
        organizationAgent: get(values, "organizationAgent", ""),
        referenceCountry: get(values, "referenceCountry", ""),
      }
      userUpdated = addValues

      if (values.photoURL) {
        const uploadDir = `providers/avatar/${userCreatedData.uid}/${values.photoURL.name}`
        const snapshot =
          Object.keys(values.photoURL).length > 0
            ? await firebase.uploadToStorage(uploadDir, values.photoURL.blob)
            : null
        tempImageURL = snapshot ? await snapshot.ref.getDownloadURL() : null
        userUpdated.photoURL = tempImageURL
      }

      await firebase.updateProvider(
        {
          photoURL: get(userUpdated, "photoURL", ""),
          registerStepCompleted: 1,
          reference: get(values, "reference", ""),
          referenceType: get(values, "referenceType", ""),
          organizationReference: get(values, "organizationReference", ""),
          organizationAgent: get(values, "organizationAgent", ""),
          referenceCountry: get(values, "referenceCountry", ""),
          gender: get(values, "gender", ""),
          name: get(userUpdated, "name", ""),
          surname1: get(userUpdated, "surname1", ""),
          surname2: get(userUpdated, "surname2", ""),
          phoneNumber: get(userUpdated, "phoneNumber", ""),
          country: get(userUpdated, "country", ""),
        },
        values.email.toLowerCase()
      )

      if (
        userCreatedData.medicalAssistantEmail &&
        values.medicalAssistantEmail &&
        userCreatedData.medicalAssistantEmail !== values.medicalAssistantEmail
      ) {
        await createAssistant(userUpdated)
      }

      const getUserFromLS = localStorage.getItem("userRegister")
      const parseUser = getUserFromLS ? JSON.parse(getUserFromLS) : {}
      const setUserNewData = {
        ...parseUser,
        ...userUpdated,
      }
      localStorage.setItem("userRegister", JSON.stringify(setUserNewData))
      localStorage.setItem("registerStepCompleted", 1)
      setIsCreating(false)
      goToNext()
    } catch (e) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const getPhoto = (photoURL) => {
    if (photoURL && photoURL.blobUrl) {
      return photoURL.blobUrl
    }
    if (photoURL && !photoURL.blobUrl) {
      return photoURL
    }
    return profilePlaceholder
  }

  return (
    <AccordionItem>
      <Formik
        innerRef={formRef}
        initialValues={{
          name: "",
          surname1: "",
          surname2: "",
          email: userEmailFromLogin,
          phoneNumber: "",
          gender: "",
          country: "",
          password: "",
          confirmPassword: "",
          medicalAssistantName: "",
          medicalAssistantEmail: "",
          medicalAssistantLastName: "",
          reference: "noReference",
          referenceType: "noReference",
          organizationReference: null,
          organizationAgent: null,
          photoURL: null,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(t("error_all_fields_required")),
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
          phoneNumber: Yup.string()
            .test(
              "phoneNumber",
              t("web_client.error_invalid_phone_number"),
              (value) => value && isPhone(value)
            )
            .required(t("error_all_fields_required")),
          country: Yup.string().required(t("error_all_fields_required")),
          password: Yup.string().required(t("error_all_fields_required")),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            t("web_client.error_password_must_match")
          ),
          medicalAssistantName: Yup.string(),
          medicalAssistantEmail: Yup.string().email(
            t("error_auth_invalid_email")
          ),
          medicalAssistantLastName: Yup.string(),
          surname1: Yup.string().required(t("error_all_fields_required")),
          surname2: Yup.string().required(t("error_all_fields_required")),
        })}
        onSubmit={async (values) => {
          try {
            setIsCreating(true)
            if (userCreatedData.registerStepCompleted >= 1 || isFromBack) {
              await updateUser(values)
            } else {
              await createAccount(values)
            }
          } catch (err) {
            honeybadger.notify("Register - General Information Section", {
              message: "Error on Submit / Register General Information Section",
              action: "submit",
              context: {
                formValues: values,
                error: err,
              },
            })
            setIsCreating(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        }}
      >
        {(props) => (
          <Form>
            <h2>
              <AccordionButton
                background="#FFF"
                _hover={{ background: "#FFF" }}
                borderTopRightRadius="10px"
                borderTopLeftRadius="10px"
              >
                <Box flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    flex="1"
                    justifyContent="flex-start"
                    alignItems="center"
                    borderBottom="1px solid #EDF2F7"
                  >
                    <Flex
                      width="32px"
                      height="32px"
                      borderRadius="32px"
                      background={`${cardShowed >= 1 ? "#38A169" : "#000"}`}
                      color={`${cardShowed >= 1 ? "#000" : "#FFF"}`}
                      justifyContent="center"
                      alignItems="center"
                      marginRight="10px"
                    >
                      1
                    </Flex>
                    <Flex minHeight="84px" padding="10px 0" alignItems="center">
                      <Text fontSize="18px" fontWeight="700" color="#171923">
                        {t("web_client.register_information_title")}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <AccordionIcon
                  onClick={() => {
                    if (userCreatedData.registerStepCompleted > 0) {
                      setNextCard(0)
                      setIsFromBack(true)
                      localStorage.setItem("registerStepCompleted", 1)
                    }
                  }}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderBottomRightRadius="10px"
              borderBottomLeftRadius="10px"
              padding="0px"
            >
              <Flex alignItems="center" marginLeft="12px">
                <ImageUploader
                  fieldName="photoURL"
                  width={64}
                  height={64}
                  isRegister
                  src={getPhoto(props.values.photoURL)}
                  onImageLoaded={props.setFieldValue}
                />
                <Text fontSize="14px" fontWeight="400" color="#4A5568">
                  {t("web_client.profile_pic")}
                </Text>
              </Flex>
              <Flex
                marginTop="23px"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("q1_info_name_label")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t("q1_info_name_placeholder")}
                      width="320px"
                      height="32px"
                      type="text"
                      name="name"
                      id="name"
                      showErrorMessage={props.errors.name && props.touched.name}
                      handleInputOnBlur={() => props.setTouched({ name: true })}
                      errorMessage={props.errors.name}
                      handleInputChange={(e) =>
                        props.setFieldValue("name", e.target.value)
                      }
                      defaultValue={props.values.name}
                    />
                  </Flex>
                </Box>

                <Box width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("q2_info_surname1_label")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t("q2_info_surname1_placeholder")}
                      width="320px"
                      height="32px"
                      type="text"
                      name="surname1"
                      id="surname1"
                      showErrorMessage={
                        props.errors.surname1 && props.touched.surname1
                      }
                      handleInputOnBlur={() =>
                        props.setTouched({ surname1: true })
                      }
                      errorMessage={props.errors.surname1}
                      handleInputChange={(e) =>
                        props.setFieldValue("surname1", e.target.value)
                      }
                      defaultValue={props.values.surname1}
                    />
                  </Flex>
                </Box>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("q3_info_surname2_label")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t("q3_info_surname2_placeholder")}
                      width="320px"
                      height="32px"
                      type="text"
                      name="surname2"
                      id="surname2"
                      showErrorMessage={
                        props.errors.surname2 && props.touched.surname2
                      }
                      handleInputOnBlur={() =>
                        props.setTouched({ surname2: true })
                      }
                      errorMessage={props.errors.surname2}
                      handleInputChange={(e) =>
                        props.setFieldValue("surname2", e.target.value)
                      }
                      defaultValue={props.values.surname2}
                    />
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("email_address")}
                    </Text>
                  </Flex>
                  <Box>
                    <CustomInput
                      placeholder={t("q1_profile_email_placeholder")}
                      width="320px"
                      height="32px"
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      showErrorMessage={
                        (props.errors.email && props.touched.email) ||
                        emailAlreadyInUse
                      }
                      errorMessage={
                        emailAlreadyInUse
                          ? t("error_email_already_in_use")
                          : props.errors.email
                      }
                      handleInputOnBlur={() =>
                        props.setTouched({ email: true })
                      }
                      handleInputChange={(e) => {
                        props.setFieldValue("email", e.target.value)
                        if (emailAlreadyInUse) {
                          setEmailIsAlreadyUsed(false)
                        }
                      }}
                      defaultValue={props.values.email}
                      isReadOnly={
                        userCreatedData.registerStepCompleted >= 1 || isFromBack
                      }
                    />
                  </Box>
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("q7_info_phone_label")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t("q7_info_phone_help")}
                      width="320px"
                      height="32px"
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      showErrorMessage={
                        props.errors.phoneNumber && props.touched.phoneNumber
                      }
                      errorMessage={props.errors.phoneNumber}
                      handleInputOnBlur={() =>
                        props.setTouched({ phoneNumber: true })
                      }
                      handleInputChange={(e) =>
                        props.setFieldValue("phoneNumber", e.target.value)
                      }
                      defaultValue={props.values.phoneNumber}
                    />
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.gender")}
                    </Text>
                  </Flex>
                  <Box
                    width="320px"
                    height="32px"
                    justifyContent="center"
                    alignItems="center"
                    zIndex="1002"
                  >
                    <CustomSelect
                      formKey="gender"
                      lngKey="q4_info_gender_nullOption"
                      handleChange={props.setFieldValue}
                      items={genderOptions}
                      value={props.values.gender}
                    />
                  </Box>
                </Flex>
              </Flex>

              <Box backgroundColor="#F7FAFC" width="100%">
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.citizenship")}
                    </Text>
                  </Flex>
                  <Box
                    width="320px"
                    height="32px"
                    justifyContent="center"
                    alignItems="center"
                    zIndex="1001"
                  >
                    <CustomSelect
                      items={countries}
                      formKey="country"
                      lngKey="web_client.citizenship"
                      handleChange={(key, value) => {
                        props.setFieldValue(key, value)
                      }}
                      showError={props.errors.country && props.submitCount > 0}
                      value={props.values.country}
                    />
                  </Box>
                </Flex>
              </Box>
            </AccordionPanel>

            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderRadius="10px"
              padding="0px"
            >
              <Flex
                alignItems="center"
                borderBottom="1px solid #EDF2F7"
                minHeight="84px"
                padding="10px 0"
              >
                <Text
                  fontSize="18px"
                  fontWeight="700"
                  color="#171923"
                  marginLeft="24px"
                >
                  {t("web_client.settings_navbar_security")}
                </Text>
              </Flex>
              <Flex
                marginTop="23px"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                  width={isMobile ? "100%" : "80%"}
                  padding={isMobile ? "10px 20px" : "0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                    height="40px"
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("password")}
                    </Text>
                  </Flex>

                  <Stack>
                    <FormControl>
                      <InputGroup size="md">
                        <CustomInput
                          placeholder={t("title_capture_password")}
                          width={isMobile ? "340px" : "358px"}
                          height="40px"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          autoComplete="off"
                          showErrorMessage={
                            props.errors.password && props.touched.password
                          }
                          errorMessage={props.errors.password}
                          handleInputOnBlur={() =>
                            props.setTouched({ password: true })
                          }
                          handleInputChange={(e) =>
                            props.setFieldValue("password", e.target.value)
                          }
                          defaultValue={props.values.password}
                          avoidCopyAndPaste
                        />

                        <InputRightElement>
                          <Button size="sm" onClick={toggleShowPassword}>
                            {showPassword ? (
                              <Visibility size="24" />
                            ) : (
                              <VisibilityOff size="24" />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <PasswordMessagesContainer>
                      <PasswordInfoMessage
                        isEmpty={!props.values.password}
                        isValid={props.values.password.match(/[0-9]+/)}
                      >
                        {t("password_contain_number")}
                      </PasswordInfoMessage>
                      <PasswordInfoMessage
                        isEmpty={!props.values.password}
                        isValid={props.values.password.match(/[a-zA-Z]+/)}
                      >
                        {t("password_contain_letters")}
                      </PasswordInfoMessage>
                      <PasswordInfoMessage
                        isEmpty={!props.values.password}
                        isValid={props.values.password.match(
                          // eslint-disable-next-line no-useless-escape
                          /[\!\#\$\^\&\*\-\_\+\=\|\\\/\"\[\]\{\}\(\)]+/
                        )}
                      >
                        {t("password_contain_symbols")}
                      </PasswordInfoMessage>
                      <PasswordInfoMessage
                        isEmpty={!props.values.password}
                        isValid={props.values.password.length >= 6}
                      >
                        {t("password_minimun")}
                      </PasswordInfoMessage>
                    </PasswordMessagesContainer>
                  </Stack>
                </Flex>

                <Box
                  width="100%"
                  backgroundColor="#F7FAFC"
                  borderBottomRightRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("verify_password")}
                      </Text>
                    </Flex>
                    <Stack>
                      <FormControl>
                        <InputGroup size="md">
                          <CustomInput
                            placeholder={t("confirm_password")}
                            width={isMobile ? "340px" : "358px"}
                            height="40px"
                            type={showVerifyPassword ? "text" : "password"}
                            name="confirmPassword"
                            id="confirmPassword"
                            showErrorMessage={
                              props.errors.confirmPassword &&
                              props.touched.confirmPassword
                            }
                            errorMessage={props.errors.confirmPassword}
                            handleInputOnBlur={() =>
                              props.setTouched({ confirmPassword: true })
                            }
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "confirmPassword",
                                e.target.value
                              )
                            }
                            defaultValue={props.values.confirmPassword}
                            avoidCopyAndPaste
                          />

                          <InputRightElement>
                            <Button
                              size="sm"
                              onClick={toggleShowVerifyPassword}
                            >
                              {showVerifyPassword ? (
                                <Visibility size="24" />
                              ) : (
                                <VisibilityOff size="24" />
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </Stack>
                  </Flex>
                </Box>
              </Flex>
            </AccordionPanel>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderRadius="10px"
              padding="0px"
            >
              <Reference
                values={props.values}
                setFieldValue={props.setFieldValue}
                firebase={firebase}
              />
            </AccordionPanel>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderRadius="10px"
              padding="0px"
            >
              <Flex
                alignItems="center"
                borderBottom="1px solid #EDF2F7"
                minHeight="84px"
                padding="10px 0"
                marginBottom="12px"
              >
                <Text
                  fontSize="18px"
                  fontWeight="700"
                  color="#171923"
                  marginLeft="24px"
                >
                  {t("web_client.add_medical_assistant_title")}
                </Text>
              </Flex>

              <Text
                fontSize="12px"
                fontWeight="400"
                color="#718096"
                margin="0 auto"
                width={isMobile ? "100%" : "600px"}
                textAlign="center"
              >
                {t("web_client.add_medical_assistant_description")}
              </Text>

              <Box width="100%" backgroundColor="#F7FAFC">
                <Flex
                  marginTop="23px"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#1A202C"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    {t("name")}
                  </Text>
                  <CustomInput
                    placeholder=""
                    width="320px"
                    height="32px"
                    type="text"
                    name="medicalAssistantName"
                    id="medicalAssistantName"
                    showErrorMessage={
                      props.errors.medicalAssistantName &&
                      props.touched.medicalAssistantName
                    }
                    errorMessage={props.errors.medicalAssistantName}
                    handleInputOnBlur={() =>
                      props.setTouched({ medicalAssistantName: true })
                    }
                    handleInputChange={(e) =>
                      props.setFieldValue(
                        "medicalAssistantName",
                        e.target.value
                      )
                    }
                    defaultValue={props.values.medicalAssistantName}
                  />
                </Flex>
              </Box>

              <Box width="100%">
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#1A202C"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    {t("lastname")}
                  </Text>
                  <CustomInput
                    placeholder=""
                    width="320px"
                    height="32px"
                    type="text"
                    name="medicalAssistantLastName"
                    id="medicalAssistantLastName"
                    showErrorMessage={
                      props.errors.medicalAssistantLastName &&
                      props.touched.medicalAssistantLastName
                    }
                    errorMessage={props.errors.medicalAssistantLastName}
                    handleInputOnBlur={() =>
                      props.setTouched({ medicalAssistantLastName: true })
                    }
                    handleInputChange={(e) =>
                      props.setFieldValue(
                        "medicalAssistantLastName",
                        e.target.value
                      )
                    }
                    defaultValue={props.values.medicalAssistantLastName}
                  />
                </Flex>
              </Box>

              <Box
                width="100%"
                borderBottomRightRadius="10px"
                borderBottomLeftRadius="10px"
                backgroundColor="#F7FAFC"
              >
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#1A202C"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    {t("email_address")}
                  </Text>
                  <CustomInput
                    placeholder=""
                    width="320px"
                    height="32px"
                    type="text"
                    name="medicalAssistantEmail"
                    id="medicalAssistantEmail"
                    showErrorMessage={
                      props.errors.medicalAssistantEmail &&
                      props.touched.medicalAssistantEmail
                    }
                    errorMessage={props.errors.medicalAssistantEmail}
                    handleInputOnBlur={() =>
                      props.setTouched({ medicalAssistantEmail: true })
                    }
                    handleInputChange={(e) =>
                      props.setFieldValue(
                        "medicalAssistantEmail",
                        e.target.value
                      )
                    }
                    defaultValue={props.values.medicalAssistantEmail}
                  />
                </Flex>
              </Box>
            </AccordionPanel>
            <AccordionPanel pb={4}>
              {!isEmpty(props.errors) &&
                isEmpty(props.errors.medicalAssistantEmail) && (
                  <Text
                    fontSize="12px"
                    color="rgb(98, 0, 238)"
                    fontWeight="500"
                    width="500px"
                    mb="20px"
                  >
                    {t("error_all_fields_required_remember")}
                  </Text>
                )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="220px"
              >
                <Button
                  colorScheme="blackAlpha"
                  width="104px"
                  height="40px"
                  color="#000"
                  fontSize="16px"
                  fontWeight="bold"
                  onClick={goToBack}
                >
                  {t("go_back")}
                </Button>
                <Button
                  colorScheme="blue"
                  background="#576EF5"
                  width="104"
                  height="40px"
                  borderRadius="6px"
                  color="#FFF"
                  fontSize="18px"
                  fontWeight="normal"
                  type="submit"
                  isLoading={isCreating}
                >
                  {t("next")}
                </Button>
              </Box>
            </AccordionPanel>
          </Form>
        )}
      </Formik>
    </AccordionItem>
  )
}

const GeneralInformation = compose(
  withRouter,
  WithChakra
)(GeneralInformationBase)

export default GeneralInformation
