import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import SignaturePad from "react-signature-canvas"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import debounce from "lodash.debounce"

import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { v4 as uuidv4 } from "uuid"
import { useLocation } from "react-router-dom"
import { Flex, Box } from "@chakra-ui/react"

// Components
import CustomModal from "../../atoms/CustomModal"
import Reference from "../../chakra/Reference"

import { WithChakra } from "../../atoms/WithChakra"

// Children
import { Personal, Academic, Business, Fees } from "./Children"

// Utils
import { withFirebase } from "../../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import { checkMissingData } from "../../../utils/helpers"
import { getProviderPricingUrl } from "../../../utils/App"

import {
  Wrapper,
  Header,
  Container,
  SubmitButton,
  Tabs,
  Tab,
  ModalWrapper,
  Buttons,
  NavLabel,
  ModalOptions,
} from "./styles"

import Theme from "../../../styles/theme"

const Account = ({ firebase }) => {
  const user = useContext(AuthUserContext)
  const { addToast } = useToasts()
  const sigCanvas = useRef({})
  const { t } = useTranslation()
  const { state } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imageURL, setImageURL] = useState(null)
  const [imageCompanyURL, setImageCompanyURL] = useState(null)
  const [imageCompanyStampURL, setImageCompanyStampURL] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [formValues, setFormValues] = useState({})
  const [isWithChangesModalOpen, setIsWithChangesModalOpen] = useState(false)
  const [academicFormValues, setAcademicFormValues] = useState({})
  const [selectedLanguagesState, setSelectedLanguages] = useState([])
  const [businessFormValues, setBusinessFormValues] = useState({})
  const [feesFormValues, setFeesFormValues] = useState({
    // Consulta Virtual
    virtualAppointmentEnabled: false,
    virtualConsultation: 0,
    patient_time: 0,

    // Consulta Presencial
    facingConsultation: 0,
    patient_time_in_person: 0,

    // Visita a domicilio
    homeAppointmentEnabled: false,
    homeConsultation: 0,
    homeVisitTime: 0,

    // Servicio de preguntas
    questions: 0,
    questionsEnabled: false,

    chargeAdvanceAppointments: false,
    chargeCashAppointments: false,
  })
  const [uploadingImage, setUploadingImage] = useState(false)
  const [savingValues, setSavingValues] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [isLoadedImage, setIsLoadedImage] = useState({
    avatar: false,
    sign: false,
    company: false,
  })
  const [withChanges, setWithChanges] = useState(false)
  const [sectionsMissing, setSectionsMissing] = useState({})
  const [propertiesMissing, setPropertiesMissing] = useState({})
  const [getMissingProperties, setGetMissingProperties] = useState(false)
  const [isLicenseReadOnly, setIsLicenseReadOnly] = useState(true)
  const [providerPricing, setProviderPricing] = useState({})
  const userType = get(user, ["medicProfile", "userType"])
  const [coordsError, showCoordsError] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const profile = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return profile.data()
      }
      const Profile = await getProfile()
      if (Profile) {
        setFormValues(Profile)
        const getBusinessForm = async () => {
          try {
            const getBusinessData = await firebase.getBusiness({
              email: user.isImpersonating
                ? user.impersonatingEmail
                : user.email,
            })
            setBusinessFormValues(getBusinessData.data())
            const getAcademic = await firebase.getAcademic({
              email: user.isImpersonating
                ? user.impersonatingEmail
                : user.email,
            })
            const academicData = getAcademic.data()
            setAcademicFormValues(academicData)
            setIsLicenseReadOnly(!!get(academicData, "license", false))
            const getFeesData = await firebase.getFees({
              email: user.isImpersonating
                ? user.impersonatingEmail
                : user.email,
            })
            const feesData = getFeesData.data()
            setFeesFormValues((currentValues) => ({
              ...currentValues,
              virtualConsultation: get(feesData, "virtual.consultationFee"),
              patient_time: get(feesData, "virtual.consultationTime"),
              virtualAppointmentEnabled: get(
                feesData,
                "virtual.enabled",
                false
              ),
              facingConsultation: get(feesData, "faceToFace.consultationFee"),
              patient_time_in_person: get(
                feesData,
                "faceToFace.consultationTime",
                15
              ),
              homeConsultation: get(feesData, "home.consultationFee", null),
              homeVisitTime: get(feesData, "home.consultationTime", null),
              homeAppointmentEnabled: get(feesData, "home.enabled", false),
              questions: get(feesData, "questions.consultationFee", null),
              questionsEnabled: get(feesData, "questions.enabled", null),
              chargeAdvanceAppointments: get(
                feesData,
                "chargeAdvanceAppointments",
                false
              ),
              chargeCashAppointments: get(
                feesData,
                "chargeCashAppointments",
                false
              ),
            }))

            setSelectedLanguages(get(Profile, "languages", []))
          } catch (e) {
            window.Honeybadger?.notify(e)
          }
        }
        getBusinessForm()
      }
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    if (firebase && user) {
      // const Profile = get(user, ["medicProfile"])
      const allUserData = {
        ...formValues,
        academic: { ...academicFormValues },
        business: { ...businessFormValues },
        sessionPrice: feesFormValues.virtualConsultation,
      }

      const missingData = withChanges
        ? checkMissingData(
            allUserData,
            activeTab === 0
              ? "personal_data"
              : activeTab === 1
              ? "academic"
              : "business"
          )
        : checkMissingData(allUserData)

      if (!isEmpty(missingData)) {
        setPropertiesMissing({})
        setSectionsMissing({})
        missingData.propertiesMissing.forEach((property) => {
          setPropertiesMissing((properties) => ({
            ...properties,
            [property]: true,
          }))
        })
        missingData.sectionsMissing.forEach((section) => {
          setSectionsMissing((sections) => ({ ...sections, [section]: true }))
        })
      }
    }
  }, [
    formValues,
    academicFormValues,
    businessFormValues,
    getMissingProperties,
    user,
    feesFormValues.virtualConsultation,
  ])

  const clear = () => {
    setIsSigned(false)
    setIsButtonLoading(false)
    sigCanvas.current.clear()
  }

  const save = () => {
    setUploadingImage(true)
    const signImage = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png")
    const fileName = `${uuidv4()}-sign.png`
    const uploadDir = `providers/${formValues.uid}`
    const uploadTask = firebase.uploadProviderFile({
      file: signImage,
      uploadDir,
      fileName,
    })
    uploadTask.on("state_changed", null, null, () => {
      firebase
        .getMediaURL({ uploadDir, fileName })
        .then((url) => {
          setUploadingImage(false)
          setImageURL(url)
          setFormValues((currentValues) => ({
            ...currentValues,
            personalSign: url,
          }))
          setIsModalOpen(false)
        })
        .catch(() => {
          addToast(t("error_failed_file_upload"), { appearance: "error" })
        })
    })
  }

  const handleSaveLocalSignature = (signImage) => {
    setUploadingImage(true)
    const fileName = `${uuidv4()}-sign.png`
    const uploadDir = `providers/${formValues.uid}`
    const uploadTask = firebase.uploadProviderFile({
      file: signImage,
      uploadDir,
      fileName,
    })
    uploadTask.on("state_changed", null, null, () => {
      firebase
        .getMediaURL({ uploadDir, fileName })
        .then((url) => {
          setUploadingImage(false)
          setImageURL(url)
          setFormValues((currentValues) => ({
            ...currentValues,
            personalSign: url,
          }))
        })
        .catch(() => {
          addToast(t("error_failed_file_upload"), { appearance: "error" })
        })
    })
  }

  const getPricing = async () => {
    try {
      const authToken = await firebase.getIdToken()
      if (authToken) {
        const params = {
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        }
        const url = new URL(getProviderPricingUrl)
        Object.keys(params).forEach((k) => {
          url.searchParams.append(k, params[k])
        })

        const providerPricingData = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })

        if (providerPricingData.ok) {
          const pricing = await providerPricingData.json()
          setProviderPricing(pricing)
        }
      }
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  useEffect(() => {
    if (user) {
      getPricing()
    }
  }, [user])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(0)
      setTimeout(() => {
        setActiveTab(state?.activeTab)
      }, 800)
    }
  }, [])

  const getImagesSnapshots = async () => {
    const email = user.isImpersonating ? user.impersonatingEmail : user.email
    try {
      const uploadDir = avatarUrl
        ? `providers/avatar/${email}/${formValues.photoURL.name}`
        : null
      const uploadBusinessDir = imageCompanyURL
        ? `providers/${email}/business/${businessFormValues.companyLogo.name}`
        : null
      const uploadBusinessStampDir = imageCompanyStampURL
        ? `providers/${email}/business/${businessFormValues.companyStamp.name}`
        : null
      const snapshot =
        typeof formValues.photoURL === "object" &&
        uploadDir &&
        Object.keys(formValues.photoURL).length > 0
          ? await firebase.uploadToStorage(uploadDir, formValues.photoURL.blob)
          : null
      const snapshotLogo =
        uploadBusinessDir &&
        typeof businessFormValues.companyLogo === "object" &&
        Object.keys(businessFormValues.companyLogo).length > 0
          ? await firebase.uploadToStorage(
              uploadBusinessDir,
              businessFormValues.companyLogo.blob
            )
          : null
      const snapshotStamp =
        uploadBusinessStampDir &&
        typeof businessFormValues.companyStamp === "object" &&
        Object.keys(businessFormValues.companyStamp).length > 0
          ? await firebase.uploadToStorage(
              uploadBusinessStampDir,
              businessFormValues.companyStamp.blob
            )
          : null
      const tempImageURL = snapshot
        ? await snapshot.ref.getDownloadURL()
        : get(formValues, "photoURL", null)
      const tempImageLogoURL = snapshotLogo
        ? await snapshotLogo.ref.getDownloadURL()
        : get(businessFormValues, "companyLogo", null)
      const tempImageStampURL = snapshotStamp
        ? await snapshotStamp.ref.getDownloadURL()
        : get(businessFormValues, "companyStamp", null)
      return {
        tempImageURL,
        tempImageLogoURL,
        tempImageStampURL,
      }
    } catch (err) {
      window.Honeybadger?.notify(err)
      return {
        tempImageURL: get(formValues, "photoURL", null),
        tempImageLogoURL: get(businessFormValues, "companyLogo", null),
        tempImageStampURL: get(businessFormValues, "companyStamp", null),
      }
    }
  }

  const saveFees = async () => {
    await firebase.saveFeesData({
      email: user.isImpersonating ? user.impersonatingEmail : user.email,
      data: {
        virtual: {
          consultationFee: Number(feesFormValues.virtualConsultation) || 0,
          consultationTime: Number(feesFormValues.patient_time) || 0,
          enabled: feesFormValues.virtualAppointmentEnabled,
        },
        faceToFace: {
          consultationFee: Number(feesFormValues.facingConsultation),
          consultationTime: feesFormValues.patient_time_in_person,
          enabled: true, // faceToFace is required, so it always is enabled
        },
        home: {
          consultationFee: Number(feesFormValues.homeConsultation) || 0,
          consultationTime: Number(feesFormValues.homeVisitTime) || 0,
          enabled: feesFormValues.homeAppointmentEnabled,
        },
        questions: {
          consultationFee: Number(feesFormValues.questions) || 0,
          enabled: feesFormValues.questionsEnabled,
        },
        chargeAdvanceAppointments: feesFormValues.chargeAdvanceAppointments,
        chargeCashAppointments: feesFormValues.chargeCashAppointments,
      },
    })
    getPricing()
  }

  const saveFeesDebounce = async () => {
    debounce(saveFees, 300)()
  }

  useEffect(() => {
    if (
      feesFormValues?.virtualConsultation !== 0 ||
      feesFormValues?.facingConsultation !== 0 ||
      feesFormValues?.homeConsultation !== 0
    ) {
      saveFeesDebounce()
    }
  }, [feesFormValues])

  const handleClick = async () => {
    setSavingValues(true)

    const snapshots = await getImagesSnapshots()
    try {
      if (academicFormValues && Object.keys(academicFormValues).length > 0) {
        await firebase.saveAcademicData({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
          data: academicFormValues,
        })
      }

      if (businessFormValues && Object.keys(businessFormValues).length > 0) {
        await firebase.saveCompanyData({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
          data: {
            ...businessFormValues,
            companyLogo: snapshots.tempImageLogoURL,
            companyStamp: snapshots.tempImageStampURL,
          },
        })
      }

      let isAcademicFormReady = !!academicFormValues
      let isBusinessFormReady = formValues.status !== "active"
      if (academicFormValues) {
        Object.keys(academicFormValues).forEach((key) => {
          if (!academicFormValues[key]) {
            isAcademicFormReady = false
          }
        })
      }
      if (businessFormValues) {
        Object.keys(businessFormValues).forEach((key) => {
          if (key === "companyPhone" && !businessFormValues[key]) {
            isBusinessFormReady = false
          }
        })
      }

      saveFees()

      await firebase.savePersonalData({
        email: user.isImpersonating ? user.impersonatingEmail : user.email,
        data: {
          ...formValues,
          sessionPrice: get(feesFormValues, "virtualConsultation", 0),
          profession: get(academicFormValues, "profession", null),
          languages: selectedLanguagesState,
          countryBusiness: businessFormValues?.address?.country || null,
          academicFormReady: isAcademicFormReady,
          businessFormReady: isBusinessFormReady,
          title: academicFormValues?.title || null,
          indexDocument: true,
          photoURL: snapshots.tempImageURL,
          userType: "provider",
          displayName: `${get(formValues, "name")} ${get(
            formValues,
            "surname1"
          )} ${get(formValues, "surname2")}`,
        },
      })

      await firebase.updateProfile(
        "displayName",
        `${formValues.name} ${formValues.surname1} ${formValues.surname2} `
      )

      if (userType === "provider") {
        user.setUser({
          ...user,
          medicProfile: {
            ...user.medicProfile,
            ...formValues,
            gender: formValues?.gender || null,
            referenceType: formValues?.referenceType || null,
            referenceCountry: formValues?.referenceCountry || null,
            organizationReference: formValues?.organizationReference || null,
            organizationAgent: formValues?.organizationAgent || null,
            reference: formValues?.reference || null,
            photoURL: snapshots.tempImageURL,
            displayName: `${formValues.name} ${formValues.surname1} ${formValues.surname2}`,
            languages: selectedLanguagesState,
            countryBusiness: businessFormValues?.address?.country || null,
          },
        })
      }
      addToast(t("web_client.saved_successfully"), {
        appearance: "success",
      })
      setSavingValues(false)
      setWithChanges(false)
      setGetMissingProperties(!getMissingProperties)
    } catch (e) {
      setSavingValues(false)
      window.Honeybadger?.notify(e)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
    setWithChanges(true)
  }

  const handleAcademicInputChange = (e) => {
    e.persist()
    setAcademicFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
    setWithChanges(true)
  }

  const handleBusinessInputChange = (e) => {
    e.persist()
    if (e.target.value) {
      setBusinessFormValues((currentValues) => ({
        ...currentValues,
        [e.target.name]: e.target.value,
      }))
      setWithChanges(true)
    }
  }

  const onChangeView = (data, key = "") => {
    setAcademicFormValues((currentValues) => ({
      ...currentValues,
      [key]: key === "title" ? data.label : data.value,
      professionLangLabel: key === "profession" ? data.label : null,
    }))
    setWithChanges(true)
  }

  const onChangeBusinessView = (type, key = "") => {
    if (type.value) {
      setBusinessFormValues((currentValues) => ({
        ...currentValues,
        [key]: type.value,
      }))
      setWithChanges(true)
    }
  }

  const onChangePersonalView = (data, key = "") => {
    if (data.value) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [key]: key === "title" ? data.label : data.value,
        professionLangLabel: key === "profession" ? data.label : null,
      }))
      setWithChanges(true)
    }
  }

  const onChangeReference = (key, value) => {
    if (value) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [key]: value,
      }))
      setWithChanges(true)
    }
  }

  const handleReadFile = (event) => {
    const file = event.target.files[0]
    if (file.type && file.type.indexOf("image") === -1) {
      return
    }

    const reader = new FileReader()

    reader.addEventListener("load", (e) => {
      setFormValues((currentValues) => ({
        ...currentValues,
        personalSign: e.target.result,
      }))
      handleSaveLocalSignature(e.target.result)
    })

    reader.readAsDataURL(file)
  }

  const handleTabChange = (tab) => {
    if (withChanges) {
      setIsWithChangesModalOpen(true)
    } else {
      setActiveTab(tab)
    }
  }

  if (Object.keys(formValues).length === 0) return null

  return (
    <Wrapper>
      <Header>
        <SubmitButton
          isLoading={savingValues}
          type="submit"
          onClick={handleClick}
          color={Theme.color.secondary}
          disabled={!withChanges}
        >
          {t("save")}
        </SubmitButton>
      </Header>
      <Container>
        <Tabs>
          <Tab
            onClick={() => handleTabChange(0)}
            isActive={activeTab === 0}
            withChanges={withChanges}
          >
            <NavLabel
              missingData={get(sectionsMissing, "personal_data", false)}
            >
              {t("web_client.personal")}
            </NavLabel>
          </Tab>
          <Tab
            onClick={() => handleTabChange(1)}
            isActive={activeTab === 1}
            withChanges={withChanges}
          >
            <NavLabel missingData={get(sectionsMissing, "academic", false)}>
              {t("web_client.academic")}
            </NavLabel>
          </Tab>
          <Tab
            onClick={() => handleTabChange(2)}
            isActive={activeTab === 2}
            withChanges={withChanges}
          >
            <NavLabel missingData={get(sectionsMissing, "business", false)}>
              {t("web_client.business")}
            </NavLabel>
          </Tab>
          <Tab
            onClick={() => handleTabChange(3)}
            isActive={activeTab === 3}
            withChanges={withChanges}
          >
            <NavLabel missingData={get(sectionsMissing, "fees", false)}>
              {t("fees")}
            </NavLabel>
          </Tab>
          <Tab
            onClick={() => handleTabChange(4)}
            isActive={activeTab === 4}
            withChanges={withChanges}
          >
            <NavLabel>{t("web_client.reference_section_title")}</NavLabel>
          </Tab>
        </Tabs>
        {activeTab === 0 && (
          <Personal
            formValues={formValues}
            propertiesMissing={propertiesMissing}
            setAvatarUrl={setAvatarUrl}
            setFormValues={setFormValues}
            handleInputChange={handleInputChange}
            onChangePersonalView={onChangePersonalView}
            setWithChanges={setWithChanges}
            firebase={firebase}
          />
        )}
        {activeTab === 1 && (
          <Academic
            isLicenseReadOnly={isLicenseReadOnly}
            selectedLanguagesState={selectedLanguagesState}
            formValues={formValues}
            propertiesMissing={propertiesMissing}
            academicFormValues={academicFormValues}
            isLoadedImage={isLoadedImage}
            imageURL={imageURL}
            setIsLoadedImage={setIsLoadedImage}
            handleAcademicInputChange={handleAcademicInputChange}
            onChangeView={onChangeView}
            handleReadFile={handleReadFile}
            onChangePersonalView={onChangePersonalView}
            setSelectedLanguages={setSelectedLanguages}
            setIsModalOpen={setIsModalOpen}
            firebase={firebase}
            setWithChanges={setWithChanges}
          />
        )}
        {activeTab === 2 && (
          <Business
            propertiesMissing={propertiesMissing}
            coordsError={coordsError}
            businessFormValues={businessFormValues}
            setImageCompanyURL={setImageCompanyURL}
            setImageCompanyStampURL={setImageCompanyStampURL}
            setBusinessFormValues={setBusinessFormValues}
            showCoordsError={showCoordsError}
            onChangeBusinessView={onChangeBusinessView}
            setWithChanges={setWithChanges}
          />
        )}
        {activeTab === 3 && (
          <Fees
            formValues={formValues}
            feesFormValues={feesFormValues}
            providerPricing={providerPricing}
            businessFormValues={businessFormValues}
            propertiesMissing={propertiesMissing}
            onChangeBusinessView={onChangeBusinessView}
            handleBusinessInputChange={handleBusinessInputChange}
            setFeesFormValues={setFeesFormValues}
            setWithChanges={setWithChanges}
            firebase={firebase}
          />
        )}
        {activeTab === 4 && (
          <Flex
            background="#F7FAFC"
            flexDirection="column"
            width="800px"
            borderRadius="10px"
            borderColor="gray.200"
          >
            <Reference
              firebase={firebase}
              values={formValues}
              setFieldValue={onChangeReference}
            />
          </Flex>
        )}
      </Container>
      <CustomModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
        <ModalWrapper isFull>
          <SignaturePad
            ref={sigCanvas}
            onEnd={() => {
              setIsSigned(true)
            }}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          <Buttons>
            <SubmitButton
              isLoading={uploadingImage}
              type="submit"
              onClick={save}
              color={Theme.color.secondary}
              disabled={!isSigned}
              small
            >
              {t("web_client.set_signature")}
            </SubmitButton>
            <SubmitButton
              isLoading={isButtonLoading}
              type="submit"
              onClick={clear}
              color={Theme.color.primary}
              small
            >
              {t("web_client.clear")}
            </SubmitButton>
          </Buttons>
        </ModalWrapper>
      </CustomModal>
      <CustomModal
        modalOpen={isWithChangesModalOpen}
        setModalOpen={setIsWithChangesModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>{t("pending_changes")}</p>
              <Box mt="50px">
                <button
                  type="button"
                  onClick={() => setIsWithChangesModalOpen(false)}
                >
                  {t("confirm")}
                </button>
              </Box>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
    </Wrapper>
  )
}

Account.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompAccount = compose(
  WithChakra,
  withFirebase,
  withAuthorization(condition)
)(Account)

export default CompAccount
