import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"

// Components
import { Flex, Button } from "@chakra-ui/react"
import { PlusCircle } from "@styled-icons/fa-solid"
import { RemoveRedEye } from "@styled-icons/material/RemoveRedEye"
import { SpeakerNotes } from "@styled-icons/material/SpeakerNotes"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const MedicalImages = ({ medicalImages, setCurrentCode, onOpen }) => {
  const { t } = useTranslation()
  return (
    <Flex
      flexDirection="column"
      justifyContent={
        medicalImages && medicalImages.length === 0 ? "center" : "flex-start"
      }
      alignItems={
        medicalImages && medicalImages.length === 0 ? "center" : "flex-start"
      }
      height="calc(100vh - 240px)"
      width="100%"
      overflow="auto"
    >
      {medicalImages && medicalImages.length === 0 ? (
        <EmptyMessage>{t("web_client.medical_images_empty")}</EmptyMessage>
      ) : (
        <Flex mt="30px" width="100%" flexDirection="column">
          {medicalImages
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((c) => (
              <DataRow mb="15px" key={c?.id}>
                <CustomText>
                  {moment(c.createdAt).format("MMM DD, YYYY").toUpperCase()}
                </CustomText>
                <CustomText>{c.code}</CustomText>
                {c.resultUrl ? (
                  <Button
                    h="35px"
                    width="48px"
                    borderRadius="8px"
                    padding="10px, 12px, 10px, 12px"
                    background="#EDF2F7"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="0px"
                    marginRight="10px"
                    opacity="0.8"
                    _hover={{
                      background: "#EDF2F7",
                      transform: "scale(1.1)",
                      opacity: 1,
                    }}
                    onClick={() => window.open(c.resultUrl, "_blank")}
                  >
                    <SpeakerNotes size="22px" />
                  </Button>
                ) : (
                  <Button
                    h="35px"
                    width="48px"
                    borderRadius="8px"
                    padding="10px, 12px, 10px, 12px"
                    background="#EDF2F7"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="0px"
                    marginRight="10px"
                    opacity="0.8"
                    _hover={{
                      background: "#EDF2F7",
                      transform: "scale(1.1)",
                      opacity: 1,
                    }}
                    onClick={() => {
                      setCurrentCode({ id: c.id, code: c.code })
                      onOpen()
                    }}
                  >
                    <PlusCircle size="22px" />
                  </Button>
                )}
                <Button
                  h="35px"
                  width="48px"
                  borderRadius="8px"
                  padding="10px, 12px, 10px, 12px"
                  background="#EDF2F7"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin="0px"
                  opacity="0.8"
                  _hover={{
                    background: "#EDF2F7",
                    transform: "scale(1.1)",
                    opacity: 1,
                  }}
                  onClick={() => window.open(c.pdfUrl, "_blank")}
                >
                  <RemoveRedEye size="22px" />
                </Button>
              </DataRow>
            ))}
        </Flex>
      )}
    </Flex>
  )
}

export default MedicalImages
