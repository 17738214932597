import React from "react"

const CheckIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74996 12.1275L3.62246 9L2.55746 10.0575L6.74996 14.25L15.75 5.25L14.6925 4.1925L6.74996 12.1275Z"
      fill="#323232"
    />
  </svg>
)

export default CheckIcon
