import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { PersonPinCircle } from "@styled-icons/material/PersonPinCircle"

const MessageLocation = ({ currentMessage }) => (
  <>
    <Wrapper>
      <a
        href={`http://www.google.com/maps/place/${currentMessage.location.latitude},${currentMessage.location.longitude}`}
        target="blank"
      >
        <PersonPinCircle size="80" />
      </a>
    </Wrapper>
  </>
)

const Wrapper = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > a {
    text-decoration: none;
    & > svg {
      color: white;
    }
    &:visited {
      color: white;
    }
  }
`

MessageLocation.propTypes = {
  currentMessage: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    }),
  }).isRequired,
}

export default MessageLocation
