import React from "react"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import Select from "react-select"

// Components
import { Flex, Box } from "@chakra-ui/react"
import { InitialAvatar } from "../../../../atoms/Card/styles"

// Styles
import {
  Card,
  Title,
  CustomText,
  Label,
  DataContainer,
  DataRow,
  EmptyMessage,
} from "../../styles"

// Utils
import { capitalizeAllWords, secondsToDate } from "../../../../../utils/helpers"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const currentYear = new Date().getFullYear()
const getBirthdate = (target) => {
  let age = "0"
  const birthDate = secondsToDate(get(target, "birthDate", null), "seconds")
  if (birthDate) {
    age = currentYear - birthDate.getFullYear()
  }
  return age
}

const MedicalProfile = ({
  contact,
  dependents,
  currentDependent,
  dependentsWithOwner,
  currentTarget,
  currentTargetInfo,
  medicalData,
  setCurrentDependent,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Card>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={23}
          borderBottom="1px solid #edf2f7;"
          marginBottom={5}
        >
          <Title>{t("general_information")}</Title>
          {dependents.length > 0 && (
            <div>
              <Box as="span" ml="10px">
                ({dependents.length}) {t("dependents")}:
              </Box>
              <Select
                styles={customStyles}
                defaultValue={currentDependent}
                value={dependentsWithOwner.find(
                  (option) => option.value === get(currentDependent, "value")
                )}
                options={dependentsWithOwner}
                onChange={(option) => setCurrentDependent(option || null)}
                placeholder="Seleccione"
                isClearable
                width="230px"
              />
            </div>
          )}
        </Flex>
        <InitialAvatar margin>
          {get(contact, ["photoURL"], "local") !== "local" ? (
            <img
              src={get(contact, ["photoURL"])}
              alt="avatar"
              style={{ width: 40 }}
            />
          ) : (
            <span>{get(contact, ["displayName", "0"], "").toUpperCase()}</span>
          )}
        </InitialAvatar>
        <DataContainer>
          <DataRow mb="15px">
            <Label>{t("name")}:</Label>
            <CustomText>
              {currentDependent && !currentDependent.isParent
                ? currentDependent.label
                : capitalizeAllWords(get(contact, "displayName", "Nombre"))}
            </CustomText>
          </DataRow>
          <DataRow mb="15px">
            <Label>{t("q1_profile_email_label")}:</Label>
            <CustomText>{contact.email}</CustomText>
          </DataRow>
          <DataRow mb="15px">
            <Label>{t("web_client.phone")}:</Label>
            <CustomText>
              {currentDependent && !currentDependent.isParent
                ? "N/A"
                : contact.phoneNumber}
            </CustomText>
          </DataRow>
          <DataRow mb="15px">
            <Label>{t("web_client.age")}:</Label>
            <CustomText>
              {currentDependent && !currentDependent.isParent
                ? "N/A"
                : t("target_age", {
                    age: getBirthdate(currentTarget),
                  })}
            </CustomText>
          </DataRow>
          <DataRow mb="15px">
            <Label>{t("web_client.gender")}:</Label>
            <CustomText>
              {currentDependent && !currentDependent.isParent
                ? get(currentDependent, "dependentGender", "N/A")
                : get(currentTarget, "gender", "N/A")}
            </CustomText>
          </DataRow>
        </DataContainer>
      </Card>
      <Card>
        <Title>
          {currentDependent && !currentDependent.isParent
            ? `Historial médico(Dependiente)`
            : t("medical_history")}
        </Title>
        <DataContainer>
          {medicalData ? (
            <>
              <DataRow mb="15px">
                <Label>{t("weight")}:</Label>
                <CustomText>{get(currentTargetInfo, "weight")}</CustomText>
              </DataRow>
              <DataRow mb="15px">
                <Label>{t("height")}:</Label>
                <CustomText>{get(currentTargetInfo, "height")}</CustomText>
              </DataRow>
              <DataRow mb="15px">
                <Label>{t("blood_pressure")}:</Label>
                <CustomText>{get(currentTargetInfo, "hta", "N/A")}</CustomText>
              </DataRow>
              <DataRow mb="15px">
                <Label>{t("q1_medical_alergicToMedication_label")}</Label>
                <CustomText>
                  {currentTargetInfo.alergicToMedication ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.alergicToMedication && (
                <DataRow isChild mb="15px">
                  <Label>{t("medicines")}</Label>
                  <CustomText>
                    {currentTargetInfo.alergicMedicationList}
                  </CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q3_medical_otherAlergies_label")}</Label>
                <CustomText>
                  {currentTargetInfo.otherAlergies ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.otherAlergies && (
                <DataRow isChild mb="15px">
                  <Label>{t("others")}</Label>
                  <CustomText>{currentTargetInfo.otherAlergiesList}</CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q5_medical_fatherAlive_label")}</Label>
                <CustomText>
                  {currentTargetInfo.fatherAlive ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.fatherAlive && (
                <>
                  <DataRow isChild mb="15px">
                    <Label>{t("medical_disease_father")}</Label>
                    <CustomText>
                      {currentTargetInfo.fatherHasDiseases ? "Sí" : "No"}
                    </CustomText>
                  </DataRow>
                  {currentTargetInfo.fatherHasDiseases && (
                    <DataRow isChild mb="15px">
                      <Label>
                        {t("q6_medical_fatherDiseases_label_small")}
                      </Label>
                      <CustomText>
                        {currentTargetInfo.fatherDiseases}
                      </CustomText>
                    </DataRow>
                  )}
                </>
              )}
              <DataRow mb="15px">
                <Label>{t("q7_medical_motherAlive_label")}</Label>
                <CustomText>
                  {currentTargetInfo.motherAlive ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.motherAlive && (
                <>
                  <DataRow isChild mb="15px">
                    <Label>{t("medical_disease_mother")}</Label>
                    <CustomText>
                      {currentTargetInfo.motherHasDiseases ? "Sí" : "No"}
                    </CustomText>
                  </DataRow>
                  {currentTargetInfo.motherHasDiseases && (
                    <DataRow isChild mb="15px">
                      <Label>
                        {t("q6_medical_fatherDiseases_label_small")}
                      </Label>
                      <CustomText>
                        {currentTargetInfo.motherDiseases}
                      </CustomText>
                    </DataRow>
                  )}
                </>
              )}
              <DataRow mb="15px">
                <Label>{t("brothers_question")}</Label>
                <CustomText>
                  {currentTargetInfo.hasBothersOrSisters ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.hasBothersOrSisters && (
                <>
                  <DataRow isChild mb="15px">
                    <Label>{t("brothers_quantity")}</Label>
                    <CustomText>
                      {currentTargetInfo.numberOfBothersAndSister}
                    </CustomText>
                  </DataRow>
                  <DataRow mb="15px">
                    <Label>{t("fathers_diseases")}</Label>
                    <CustomText>
                      {currentTargetInfo.brothersOrSistersHaveDiseases
                        ? "Sí"
                        : "No"}
                    </CustomText>
                  </DataRow>
                  {currentTargetInfo.brothersOrSistersHaveDiseases && (
                    <DataRow isChild mb="15px">
                      <Label>
                        {t("q6_medical_fatherDiseases_label_small")}
                      </Label>
                      <CustomText>
                        {currentTargetInfo.brothersOrSistersDiseases}
                      </CustomText>
                    </DataRow>
                  )}
                </>
              )}
              <DataRow mb="15px">
                <Label>{t("q12_medical_regularMedication_label")}</Label>
                <CustomText>
                  {currentTargetInfo.regularMedication ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.regularMedication && (
                <DataRow isChild mb="15px">
                  <Label>{t("chronic_medications")}</Label>
                  <CustomText>
                    {currentTargetInfo.regularMedicationList}
                  </CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q14_medical_doYouSmoke_label")}</Label>
                <CustomText>
                  {currentTargetInfo.doYouSmoke ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.doYouSmoke && (
                <>
                  <DataRow isChild mb="15px">
                    <Label>{t("q15_medical_cigarettesPerDay_label")}</Label>
                    <CustomText>
                      {currentTargetInfo.cigarettesPerDay}
                    </CustomText>
                  </DataRow>
                  <DataRow isChild mb="15px">
                    <Label>{t("q16_medical_yearsSmoking_label")}</Label>
                    <CustomText>{currentTargetInfo.yearsSmoking}</CustomText>
                  </DataRow>
                </>
              )}
              <DataRow mb="15px">
                <Label>{t("q17_medical_otherSubstances_label")}</Label>
                <CustomText>
                  {currentTargetInfo.otherSubstances ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.otherSubstances && (
                <DataRow isChild mb="15px">
                  <Label>{t("q18_medical_otherSubstancesList_label")}</Label>
                  <CustomText>
                    {currentTargetInfo.otherSubstancesList}
                  </CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q19_medical_doYouDrink_label")}</Label>
                <CustomText>
                  {currentTargetInfo.doYouDrink ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.doYouDrink && (
                <DataRow isChild mb="15px">
                  <Label>{t("drinks_frecuency")}</Label>
                  <CustomText>{currentTargetInfo.drinkFrequency}</CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("medical_exercise")}</Label>
                <CustomText>
                  {currentTargetInfo.doYouExercise ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.doYouExercise && (
                <DataRow isChild mb="15px">
                  <Label>{t("exercise_frecuency")}</Label>
                  <CustomText>{currentTargetInfo.exerciseFrequency}</CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q23_medical_anySurgery_label")}</Label>
                <CustomText>
                  {currentTargetInfo.anySurgery ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.anySurgery && (
                <DataRow isChild mb="15px">
                  <Label>{t("q24_medical_surgeryList_label")}</Label>
                  <CustomText>{currentTargetInfo.surgeryList}</CustomText>
                </DataRow>
              )}
              <DataRow mb="15px">
                <Label>{t("q25_medical_anyChronicIllness_label")}</Label>
                <CustomText>
                  {currentTargetInfo.anyChronicIllness ? "Sí" : "No"}
                </CustomText>
              </DataRow>
              {currentTargetInfo.anyChronicIllness && (
                <DataRow isChild mb="15px">
                  <Label>{t("q26_medical_chronicIllnessList_label")}</Label>
                  <CustomText>
                    {currentTargetInfo.chronicIllnessList}
                  </CustomText>
                </DataRow>
              )}
            </>
          ) : (
            <EmptyMessage>{t("web_client.empty_form")}</EmptyMessage>
          )}
        </DataContainer>
      </Card>
    </>
  )
}

export default MedicalProfile
