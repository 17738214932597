import React, { useState, useEffect, useRef, useContext } from "react"
import * as Yup from "yup"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import capitalize from "lodash.capitalize"
import isArray from "lodash.isarray"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Formik, Form } from "formik"
import {
  Box,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  Textarea,
  useMediaQuery,
} from "@chakra-ui/react"
import { AuthUserContext } from "../../../context/Session"

// Components
import { WithChakra } from "../../../components/atoms/WithChakra"
import CustomInput from "../../../components/chakra/CustomInput"
import CustomSelect from "../../../components/atoms/CustomSelect"

// Utils
import { honeybadger } from "../../../utils/App"

const ProfessionalInformationBase = ({
  goToNext,
  firebase,
  userCreatedData,
  cardShowed,
  formValues,
  goToBack,
  setNextCard,
}) => {
  const user = useContext(AuthUserContext)
  const { t, i18n } = useTranslation()
  const { addToast } = useToasts()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [isCreating, setIsCreating] = useState(false)
  const lang = get(i18n, "language", "es").split("-")
  const [bureaus, setBureaus] = useState([])
  const [titles, setTitles] = useState([])
  const [specialities, setSpecialities] = useState([])

  const formRef = useRef()

  const languagesOptions = [
    { value: "german", label: t("languages.german") },
    { value: "spanish", label: t("languages.spanish") },
    { value: "french", label: t("languages.french") },
    { value: "english", label: t("languages.english") },
    { value: "italian", label: t("languages.italian") },
    { value: "portuguese", label: t("languages.portuguese") },
  ]

  const enLanguagesOptions = [
    { value: "english", label: t("languages.english") },
    { value: "french", label: t("languages.french") },
    { value: "german", label: t("languages.german") },
    { value: "italian", label: t("languages.italian") },
    { value: "portuguese", label: t("languages.portuguese") },
    { value: "spanish", label: t("languages.spanish") },
  ]

  useEffect(() => {
    const prepareForm = async () => {
      const bureausOptions = await firebase.getBureausByCountry(
        userCreatedData.country
      )
      const titleOptions = await firebase.getFormOptions("titles")
      const specs = await firebase.getFormOptions("specialities")
      const specsData = specs.data()
      const titleOptionsData = titleOptions.data()
      const selectOptionsBureaus = []
      bureausOptions?.data()?.options?.forEach((doc) => {
        selectOptionsBureaus.push({ value: doc, label: doc })
      })

      setBureaus(selectOptionsBureaus)
      setTitles(
        get(titleOptionsData, "mixed", []).map((title) => ({
          value: lang[0] === "en" ? title?.es : title?.en,
          label: capitalize(lang[0] === "en" ? title?.en : title?.es),
        }))
      )
      setSpecialities(
        get(specsData, "mixed", []).map((s) => ({
          value: s?.en,
          label: capitalize(lang[0] === "en" ? s?.en : s?.es),
        }))
      )
    }
    if (firebase) {
      if (userCreatedData && cardShowed === 1) {
        prepareForm()
      }
    }
  }, [firebase, userCreatedData, i18n.language])

  useEffect(() => {
    if (formValues) {
      formRef.current.resetForm({
        values: {
          idCard: get(formValues, "idCard", ""),
          // academic
          profession: get(formValues, "academicForm.profession", ""),
          bureau: get(formValues, "academicForm.bureau", ""),
          university: get(formValues, "academicForm.university", ""),
          license: get(formValues, "academicForm.license", ""),
          biography: get(formValues, "academicForm.biography", ""),
          other: get(formValues, "academicForm.other", ""),
          title: get(formValues, "academicForm.title", ""),
          // end academic
          languages: get(userCreatedData, "languages", null),
          academicFormReady: get(formValues, "academicFormReady", false),
        },
      })
    }
  }, [formValues])

  return (
    <AccordionItem borderTop="none">
      <Formik
        innerRef={formRef}
        initialValues={{
          idCard: "",
          // academic
          profession: "",
          bureau: "",
          university: "",
          license: "",
          biography: "",
          other: "",
          title: "",
          // end academic
          languages: null,
          academicFormReady: false,
        }}
        validationSchema={Yup.object({
          idCard: Yup.string().required(t("error_all_fields_required")),
          profession: Yup.string().required(t("error_all_fields_required")),
          bureau: Yup.string().required(t("error_all_fields_required")),
          university: Yup.string().required(t("error_all_fields_required")),
          license: Yup.string().required(t("error_all_fields_required")),
          other: Yup.string().required(t("error_all_fields_required")),
          title: Yup.string().required(t("error_all_fields_required")),
          languages: Yup.array()
            .min(1)
            .required(t("error_all_fields_required")),
          biography: Yup.string().required(t("error_all_fields_required")),
        })}
        onSubmit={async (values) => {
          try {
            const getProfessionLangLabel = specialities.find(
              (spec) => spec?.value === values.profession
            )

            if (userCreatedData) {
              setIsCreating(true)

              await firebase.updateProvider(
                {
                  academicFormReady: true,
                  registerStepCompleted: 2,
                  idCard: values.idCard,
                  languages: values.languages,
                  profession: values.profession,
                  title: get(values, "title", "Dr"),
                  professionLangLabel: getProfessionLangLabel?.label || null,
                },
                userCreatedData.email.toLowerCase()
              )

              user.setUser({
                ...user,
                professionLangLabel: getProfessionLangLabel?.label || null,
                medicProfile: {
                  ...user.medicProfile,
                  idCard: values.idCard,
                  languages: values.languages,
                  title: get(values, "title", "Dr"),
                },
              })

              await firebase.saveAcademicData({
                email: userCreatedData.email,
                data: {
                  license: get(values, "license", ""),
                  university: values.university,
                  biography: values.biography,
                  other: values.other,
                  profession: values.profession,
                  bureau: values.bureau,
                  title: get(values, "title", "Dr"),
                  professionLangLabel: getProfessionLangLabel?.label || null,
                },
              })

              if (userCreatedData.medicalAssistantEmail) {
                await firebase.saveAssistantData({
                  email: userCreatedData.medicalAssistantEmail,
                  data: {},
                  provider: {
                    uid: userCreatedData.uid,
                    displayName: `${userCreatedData?.name} ${userCreatedData?.surname1} ${userCreatedData?.surname2}`,
                    email: userCreatedData.email,
                    photoURL: userCreatedData.photoURL,
                    profession: values.profession,
                    title: get(values, "title", "Dr"),
                    name: userCreatedData?.name,
                    surname1: userCreatedData?.surname1,
                    surname2: userCreatedData?.surname2,
                    professionLangLabel: getProfessionLangLabel?.label || null,
                  },
                })
              }

              const getUserFromLS = localStorage.getItem("userRegister")
              const parseUser = JSON.parse(getUserFromLS)
              const setUserNewData = {
                ...parseUser,
                academicFormReady: true,
                idCard: values.idCard,
                languages: values.languages,
                profession: values.profession,
                professionLangLabel: getProfessionLangLabel?.label || null,
                academic: {
                  license: get(values, "license", ""),
                  university: values.university,
                  biography: values.biography,
                  other: values.other,
                  profession: values.profession,
                  bureau: values.bureau,
                  title: get(values, "title", "Dr"),
                },
              }
              localStorage.setItem(
                "userRegister",
                JSON.stringify(setUserNewData)
              )
              localStorage.setItem("registerStepCompleted", 2)
              setIsCreating(false)
              goToNext()
            }
          } catch (err) {
            honeybadger.notify("Register - Professional Information Section", {
              message: "Error on Submit / Professional Information Section",
              action: "submit",
              context: {
                formValues: values,
                error: err,
              },
            })
            setIsCreating(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        }}
      >
        {(props) => (
          <Form>
            <h2>
              <AccordionButton
                background="#FFF"
                _hover={{ background: "#FFF" }}
              >
                <Box flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    flex="1"
                    justifyContent="flex-start"
                    alignItems="center"
                    borderBottom="1px solid #EDF2F7"
                  >
                    <Flex
                      width="32px"
                      height="32px"
                      borderRadius="32px"
                      background={`${cardShowed >= 2 ? "#38A169" : "#000"}`}
                      color={`${cardShowed >= 2 ? "#000" : "#FFF"}`}
                      justifyContent="center"
                      alignItems="center"
                      marginRight="10px"
                    >
                      2
                    </Flex>
                    <Flex minHeight="84px" alignItems="center" padding="10px 0">
                      <Text fontSize="18px" fontWeight="700" color="#171923">
                        {t("web_client.professional_info_title")}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <AccordionIcon
                  onClick={() => {
                    if (userCreatedData.registerStepCompleted > 1) {
                      setNextCard(1)
                      localStorage.setItem("registerStepCompleted", 1)
                    }
                  }}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderBottomRightRadius="10px"
              borderBottomLeftRadius="10px"
              padding="0px"
            >
              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.professional_dni_label")}
                    </Text>
                  </Flex>
                  <CustomInput
                    placeholder=""
                    width="320px"
                    height="32px"
                    type="text"
                    name="idCard"
                    id="idCard"
                    showErrorMessage={
                      props.errors.idCard && props.touched.idCard
                    }
                    handleInputOnBlur={() => props.setTouched({ idCard: true })}
                    errorMessage={props.errors.idCard}
                    handleInputChange={(e) =>
                      props.setFieldValue("idCard", e.target.value)
                    }
                    defaultValue={get(formValues, "idCard", null)}
                  />
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("web_client.professional_specialty_label")}
                      </Text>
                    </Flex>
                    <Box
                      width="320px"
                      height="32px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CustomSelect
                        formKey="profession"
                        lngKey="web_client.academic_profession_label"
                        handleChange={(key, value) => {
                          props.setFieldValue(key, value)
                        }}
                        items={specialities}
                        showError={
                          props.errors.profession && props.submitCount > 0
                        }
                        value={props.values.profession}
                      />
                    </Box>
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.professional_entity_label")}
                    </Text>
                  </Flex>
                  <Box
                    width={isMobile ? "320px" : "400px"}
                    height="32px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CustomSelect
                      formKey="bureau"
                      lngKey="q3_academic_bureau_help"
                      items={bureaus}
                      handleChange={props.setFieldValue}
                      showError={props.errors.bureau && props.submitCount > 0}
                      value={props.values.bureau}
                    />
                  </Box>
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="#1A202C"
                        width="180px"
                      >
                        {t("web_client.professional_code_label")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder=""
                      width="320px"
                      height="32px"
                      type="text"
                      name="license"
                      id="license"
                      showErrorMessage={
                        props.errors.license && props.touched.license
                      }
                      handleInputOnBlur={() =>
                        props.setTouched({ license: true })
                      }
                      errorMessage={props.errors.license}
                      handleInputChange={(e) =>
                        props.setFieldValue("license", e.target.value)
                      }
                      defaultValue={get(
                        formValues,
                        "academicForm.license",
                        null
                      )}
                    />
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.professional_university_label")}
                    </Text>
                  </Flex>
                  <CustomInput
                    placeholder=""
                    width="320px"
                    height="32px"
                    type="text"
                    name="university"
                    id="university"
                    showErrorMessage={
                      props.errors.university && props.touched.university
                    }
                    handleInputOnBlur={() =>
                      props.setTouched({ university: true })
                    }
                    errorMessage={props.errors.university}
                    handleInputChange={(e) =>
                      props.setFieldValue("university", e.target.value)
                    }
                    defaultValue={get(
                      formValues,
                      "academicForm.university",
                      null
                    )}
                  />
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="#1A202C"
                        width="180px"
                      >
                        {t("additional_cv_information")}
                      </Text>
                    </Flex>
                    <Textarea
                      _hover={{}}
                      variant="outline"
                      _placeholder={{
                        color: "gray.500",
                      }}
                      borderColor="#E2E8F0"
                      focusBorderColor="#E2E8F0"
                      borderRadius="6px"
                      color="gray.500"
                      fontSize="16px"
                      placeholder=""
                      width="320px"
                      height="32px"
                      type="text"
                      name="biography"
                      id="biography"
                      onChange={(e) =>
                        props.setFieldValue("biography", e.target.value)
                      }
                      defaultValue={get(
                        formValues,
                        "academicForm.biography",
                        null
                      )}
                    />
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("additional_information")}
                    </Text>
                  </Flex>
                  <Textarea
                    _hover={{}}
                    variant="outline"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    borderColor="#E2E8F0"
                    focusBorderColor="#E2E8F0"
                    borderRadius="6px"
                    color="gray.500"
                    fontSize="16px"
                    placeholder={t("web_client.register_other_placeholder")}
                    width="320px"
                    height="32px"
                    type="text"
                    name="other"
                    id="other"
                    onChange={(e) =>
                      props.setFieldValue("other", e.target.value)
                    }
                    defaultValue={get(formValues, "academicForm.other", null)}
                  />
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("q1_academic_title_label")}
                      </Text>
                    </Flex>
                    <Box
                      width="320px"
                      height="32px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CustomSelect
                        formKey="title"
                        lngKey="q1_academic_title_error"
                        handleChange={(key, value) => {
                          const findValue = titles.find(
                            (title) => title?.value === value
                          )
                          props.setFieldValue(key, findValue?.label || value)
                        }}
                        items={titles}
                        showError={props.errors.title && props.submitCount > 0}
                        value={props.values.title}
                      />
                    </Box>
                  </Flex>
                </Box>
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                  borderBottomRightRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="14px" fontWeight="400" color="#1A202C">
                      {t("web_client.professional_languages_label")}
                    </Text>
                  </Flex>
                  <Box
                    width="320px"
                    minHeight="32px"
                    justifyContent="center"
                    alignItems="center"
                    padding="20px 0"
                  >
                    <CustomSelect
                      formKey="languages"
                      lngKey="web_client.register_select_languages"
                      handleChange={(key, values) => {
                        if (isArray(values)) {
                          const selectedLanguages = []
                          values.forEach((language) =>
                            selectedLanguages.push(language.value)
                          )
                          props.setFieldValue(key, selectedLanguages)
                        } else {
                          props.setFieldValue([values])
                        }
                      }}
                      items={
                        lang[0] === "en" ? enLanguagesOptions : languagesOptions
                      }
                      isMultiple
                      showError={
                        props.errors.languages && props.submitCount > 0
                      }
                    />
                  </Box>
                </Flex>
              </Flex>
            </AccordionPanel>
            <AccordionPanel pb={4}>
              {!isEmpty(props.errors) && props.submitCount >= 1 && (
                <Text
                  fontSize="12px"
                  color="rgb(98, 0, 238)"
                  fontWeight="500"
                  width="500px"
                  mb="20px"
                >
                  {t("error_all_fields_required_remember")}
                </Text>
              )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="220px"
              >
                <Button
                  colorScheme="blackAlpha"
                  width="104px"
                  height="40px"
                  color="#000"
                  fontSize="16px"
                  fontWeight="bold"
                  onClick={goToBack}
                >
                  {t("go_back")}
                </Button>
                <Button
                  colorScheme="blue"
                  background="#576EF5"
                  width="104"
                  height="40px"
                  borderRadius="6px"
                  color="#FFF"
                  fontSize="18px"
                  fontWeight="normal"
                  isLoading={isCreating}
                  type="submit"
                >
                  {t("next")}
                </Button>
              </Box>
            </AccordionPanel>
          </Form>
        )}
      </Formik>
    </AccordionItem>
  )
}

const ProfessionalInformation = compose(
  withRouter,
  WithChakra
)(ProfessionalInformationBase)
export default ProfessionalInformation
