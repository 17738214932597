import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import get from "lodash.get"
import { useTranslation } from "react-i18next"

// Components
import CustomSelect from "../../../../atoms/CustomSelect"
import Input from "../../../../atoms/Input"
import Tooltip from "../../../../atoms/Tooltip"
import Switch from "../../../../atoms/Switch"

// Utils
import constants from "../../../../../utils/constants"

// Styles
import {
  Section,
  StyledForm,
  FeeSectionContainer,
  StyledText,
  RowContainer,
  StyledBoldText,
  AllCenter,
  Loader,
} from "../../styles"
import Theme from "../../../../../styles/theme"

const customStyles = {
  control: (provided, selectState) => ({
    ...provided,
    width: selectState.selectProps.width,
    borderRadius: selectState.selectProps.borderRadius,
    borderColor: selectState.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
    marginBottom: 25,
  }),
  singleValue: (provided, selectState) => {
    const opacity = selectState.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

export const Fees = ({
  formValues,
  feesFormValues,
  providerPricing,
  businessFormValues,
  propertiesMissing,
  onChangeBusinessView,
  handleBusinessInputChange,
  setFeesFormValues,
  setWithChanges,
  firebase,
}) => {
  const { t } = useTranslation()
  const [bankOptions, setBankOptions] = useState([])

  const durationOptions = [
    {
      value: 15,
      label: t("web_client.minutes", { minutes: "15" }),
    },
    {
      value: 30,
      label: t("web_client.minutes", { minutes: "30" }),
    },
    {
      value: 45,
      label: t("web_client.minutes", { minutes: "45" }),
    },
    {
      value: 60,
      label: t("web_client.minutes", { minutes: "60" }),
    },
  ]

  useEffect(() => {
    const citizenCoutry = get(formValues, "countryBusiness")
    const currentCountry = get(
      businessFormValues,
      "address.country",
      citizenCoutry
    )
    const getBanks = async () => {
      const selectOptionsBanks = []
      try {
        const localBankOptions = await firebase.getBanksByCountry(
          currentCountry
        )

        localBankOptions.data().options.forEach((doc) => {
          selectOptionsBanks.push({ value: doc, label: doc })
        })

        setBankOptions(selectOptionsBanks)
      } catch (e) {
        window.Honeybadger?.notify(e)
      }
    }
    if (currentCountry && firebase) {
      getBanks()
    }
  }, [firebase])

  const getDefaultAccountType = () => {
    const type = constants.accountsType.find(
      (b) => b.value === get(businessFormValues, "bankAccountType")
    )
    return type
      ? {
          value: type.value,
          label: t(type.label),
        }
      : null
  }

  const getDefaultAccountCurrencyType = () => {
    const type = constants.accountsCurrencyType.find(
      (b) => b.value === get(businessFormValues, "bankAccountCurrency")
    )
    return type
      ? {
          value: type.value,
          label: t(type.label),
        }
      : null
  }

  const countryData = constants.countries.find(
    (country) =>
      country.value ===
      get(businessFormValues, "address.country", formValues?.country)
  )

  return (
    <Section>
      {providerPricing?.pricing && bankOptions?.length > 0 ? (
        <StyledForm>
          <Tooltip title={t("q8_business_bankName_label_tooltip")} isDropdown>
            <Select
              styles={customStyles}
              defaultValue={bankOptions.find(
                (b) => b.value === get(businessFormValues, "bankName")
              )}
              label={t("q8_business_bankName_label")}
              options={bankOptions}
              onChange={(option) => onChangeBusinessView(option, "bankName")}
              placeholder={t("q8_business_bankName_label")}
              width="100%"
              borderRadius="25px"
              showError={get(propertiesMissing, "bankName", false)}
            />
          </Tooltip>
          <Tooltip
            title={t("q9_business_bankAccountType_label_tooltip")}
            isDropdown
          >
            <Select
              styles={customStyles}
              defaultValue={getDefaultAccountType()}
              label={t("q9_business_bankAccountType_label")}
              options={constants.accountsType.map((i) => ({
                value: i.value,
                label: t(i.label),
              }))}
              onChange={(option) =>
                onChangeBusinessView(option, "bankAccountType")
              }
              placeholder={t("q9_business_bankAccountType_label")}
              width="100%"
              borderRadius="25px"
              showError={get(propertiesMissing, "bankAccountType", false)}
            />
          </Tooltip>

          <Tooltip
            title={t("q9_business_bankAccountCurrency_label_tooltip")}
            isDropdown
          >
            <Select
              styles={customStyles}
              defaultValue={getDefaultAccountCurrencyType()}
              label={t("q9_business_bankAccountCurrency_label")}
              options={constants.accountsCurrencyType.map((i) => ({
                value: i.value,
                label: t(i.label),
              }))}
              onChange={(option) =>
                onChangeBusinessView(option, "bankAccountCurrency")
              }
              placeholder={t("q9_business_bankAccountCurrency_label")}
              width="100%"
              borderRadius="25px"
              showError={get(propertiesMissing, "bankAccountCurrency", false)}
            />
          </Tooltip>
          <Tooltip title={t("q9_business_bankAccount_label_tooltip")}>
            <Input
              icon="account"
              value={get(businessFormValues, "bankAccount")}
              onChange={handleBusinessInputChange}
              labelName={t("q9_business_bankAccount_label")}
              type="text"
              name="bankAccount"
              id="bankAccount"
              showError={get(propertiesMissing, "bankAccount", false)}
              required
            />
          </Tooltip>
          <FeeSectionContainer>
            <Input
              id="facingConsultation"
              name="facingConsultation"
              type="number"
              labelName={`${t("web_client.register_fees_facing_label")} *`}
              defaultValue={
                providerPricing?.pricing?.faceToFace?.totals.local
                  ?.sessionBasePrice
              }
              onChange={(e) => {
                e.persist()
                const getInputValue =
                  e.target.value > 0 ? Number(e.target.value) : e.target.value
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  facingConsultation: getInputValue,
                }))
                setWithChanges(true)
              }}
              errors={
                Number(feesFormValues.facingConsultation) <= 0
                  ? t("error_all_fields_required")
                  : null
              }
              prefix={get(countryData, "currencyCode", "")}
              touched
              showError={
                Number(feesFormValues.facingConsultation) <= 0
                  ? t("error_all_fields_required")
                  : null
              }
              required
            />
            <Input
              id="register_cash_doc_charges"
              name="register_cash_doc_charges"
              type="number"
              labelName={t("web_client.register_cash_doc_charges")}
              value={
                providerPricing?.pricing?.faceToFace?.totals.local
                  ?.sessionCashFees
              }
              prefix={get(countryData, "currencyCode", "")}
              readonly
            />
            <Input
              id="facingConsultationCashTotalCharges"
              name="facingConsultationCashTotalCharges"
              type="number"
              labelName={t("web_client.register_fees_cash_facing_total_label")}
              value={
                providerPricing?.pricing?.faceToFace?.totals.local
                  ?.sessionTotalCashPrice
              }
              prefix={get(countryData, "currencyCode", "")}
              readonly
            />
            <StyledText>
              {`${t("web_client.register_fees_facing_duration_label")}*`}
            </StyledText>
            <CustomSelect
              formKey="patient_time_in_person"
              lngKey="web_client.duration"
              items={durationOptions}
              mountEffect={false}
              showError={
                Number(get(feesFormValues, "patient_time_in_person")) <= 0
              }
              handleChange={(key, value) => {
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  [key]: value,
                }))
                setWithChanges(true)
              }}
              value={get(feesFormValues, "patient_time_in_person")}
            />
          </FeeSectionContainer>
          <RowContainer>
            <StyledBoldText>
              {t("web_client.register_virtual_switch")}
            </StyledBoldText>
            <Switch
              id="virtualAppointmentEnabled"
              value={feesFormValues.virtualAppointmentEnabled}
              checked={feesFormValues.virtualAppointmentEnabled}
              onChange={(e) => {
                e.persist()
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  virtualAppointmentEnabled: e.target.checked,
                }))
                setWithChanges(true)
              }}
            />
          </RowContainer>
          {feesFormValues.virtualAppointmentEnabled && (
            <FeeSectionContainer>
              <Input
                id="virtualConsultation"
                name="virtualConsultation"
                type="number"
                labelName={t("apt_virtual")}
                defaultValue={
                  providerPricing?.pricing?.virtual?.totals.local
                    ?.sessionBasePrice
                }
                onChange={(e) => {
                  e.persist()
                  const getInputValue =
                    e.target.value > 0 ? Number(e.target.value) : e.target.value
                  setFeesFormValues((currentValues) => ({
                    ...currentValues,
                    virtualConsultation: getInputValue,
                  }))
                  setWithChanges(true)
                }}
                errors={
                  Number(feesFormValues.virtualConsultation) <= 0
                    ? t("error_all_fields_required")
                    : null
                }
                prefix={get(countryData, "currencyCode", "")}
                touched
                showError={
                  Number(feesFormValues.virtualConsultation) <= 0
                    ? t("error_all_fields_required")
                    : null
                }
                required
              />
              <Input
                id="virtualConsultationDocCharges"
                name="virtualConsultationDocCharges"
                type="number"
                labelName={t("web_client.register_doc_charges")}
                value={
                  providerPricing?.pricing?.virtual?.totals.local
                    ?.sessionDocFees
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <Input
                id="virtualConsultationTotalCharges"
                name="virtualConsultationTotalCharges"
                type="number"
                labelName={t("web_client.register_total_charges")}
                value={
                  providerPricing?.pricing?.virtual?.totals.local
                    ?.sessionTotalPrice
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <StyledText>
                {`${t("web_client.register_virtual_duration")}*`}
              </StyledText>
              <CustomSelect
                formKey="patient_time"
                lngKey="web_client.duration"
                items={durationOptions}
                mountEffect={false}
                value={get(feesFormValues, "patient_time")}
                handleChange={(key, value) => {
                  setFeesFormValues((currentValues) => ({
                    ...currentValues,
                    [key]: value,
                  }))
                  setWithChanges(true)
                }}
                showError={Number(get(feesFormValues, "patient_time")) <= 0}
              />
            </FeeSectionContainer>
          )}
          <RowContainer>
            <StyledBoldText>
              {t("web_client.register_home_switch")}
            </StyledBoldText>
            <Switch
              id="homeAppointmentEnabled"
              value={feesFormValues.homeAppointmentEnabled}
              checked={feesFormValues.homeAppointmentEnabled}
              onChange={(e) => {
                e.persist()
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  homeAppointmentEnabled: e.target.checked,
                }))
                setWithChanges(true)
              }}
            />
          </RowContainer>
          {feesFormValues.homeAppointmentEnabled && (
            <FeeSectionContainer>
              <Input
                id="homeConsultation"
                name="homeConsultation"
                type="number"
                labelName={t("web_client.register_fees_home_label")}
                defaultValue={
                  providerPricing?.pricing?.home?.totals.local?.sessionBasePrice
                }
                onChange={(e) => {
                  e.persist()
                  const getInputValue =
                    e.target.value > 0 ? Number(e.target.value) : e.target.value
                  setFeesFormValues((currentValues) => ({
                    ...currentValues,
                    homeConsultation: getInputValue,
                  }))
                  setWithChanges(true)
                }}
                prefix={get(countryData, "currencyCode", "")}
              />
              <Input
                id="homeConsultationDocCharges"
                name="homeConsultationDocCharges"
                type="number"
                labelName={t("web_client.register_doc_charges")}
                value={
                  providerPricing?.pricing?.home?.totals.local?.sessionDocFees
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <Input
                id="homeConsultationTotalCharges"
                name="homeConsultationTotalCharges"
                type="number"
                labelName={t("web_client.register_fees_home_total_label")}
                value={
                  providerPricing?.pricing?.home?.totals.local
                    ?.sessionTotalPrice
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <Input
                id="homeConsultationCashDocCharges"
                name="homeConsultationCashDocCharges"
                type="number"
                labelName={t("web_client.register_cash_doc_charges")}
                value={
                  providerPricing?.pricing?.home?.totals.local?.sessionCashFees
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <Input
                id="homeConsultationCashTotalCharges"
                name="homeConsultationCashTotalCharges"
                type="number"
                labelName={t("web_client.register_fees_cash_home_total_label")}
                value={
                  providerPricing?.pricing?.home?.totals.local
                    ?.sessionTotalCashPrice
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <StyledText>
                {`${t("web_client.register_fees_home_duration_label")}*`}
              </StyledText>
              <CustomSelect
                formKey="homeVisitTime"
                lngKey="web_client.duration"
                items={durationOptions}
                mountEffect={false}
                value={get(feesFormValues, "homeVisitTime")}
                showError={Number(get(feesFormValues, "homeVisitTime")) <= 0}
                handleChange={(key, value) => {
                  setFeesFormValues((currentValues) => ({
                    ...currentValues,
                    [key]: value,
                  }))
                  setWithChanges(true)
                }}
              />
            </FeeSectionContainer>
          )}
          <RowContainer>
            <StyledBoldText>
              {t("web_client.register_questions_switch")}
            </StyledBoldText>
            <Switch
              id="questionsEnabled"
              value={feesFormValues.questionsEnabled}
              checked={feesFormValues.questionsEnabled}
              onChange={(e) => {
                e.persist()
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  questionsEnabled: e.target.checked,
                }))
                setWithChanges(true)
              }}
            />
          </RowContainer>

          {feesFormValues.questionsEnabled && (
            <FeeSectionContainer>
              <Input
                id="questions"
                name="questions"
                type="number"
                labelName={t("web_client.register_fees_questions")}
                defaultValue={
                  providerPricing?.pricing?.questions?.totals.local
                    ?.sessionBasePrice
                }
                onChange={(e) => {
                  e.persist()
                  const getInputValue =
                    e.target.value > 0 ? Number(e.target.value) : e.target.value
                  setFeesFormValues((currentValues) => ({
                    ...currentValues,
                    questions: getInputValue,
                  }))
                  setWithChanges(true)
                }}
                prefix={get(countryData, "currencyCode", "")}
              />
              <Input
                id="questionsDocCharges"
                name="questionsDocCharges"
                type="number"
                labelName={t("web_client.register_doc_charges")}
                value={
                  providerPricing?.pricing?.questions?.totals.local
                    ?.sessionDocFees
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
              <Input
                id="questionsTotalCharges"
                name="questionsTotalCharges"
                type="number"
                labelName={t("web_client.register_fees_questions_total_label")}
                value={
                  providerPricing?.pricing?.questions?.totals.local
                    ?.sessionTotalPrice
                }
                prefix={get(countryData, "currencyCode", "")}
                readonly
              />
            </FeeSectionContainer>
          )}
          <RowContainer>
            <StyledBoldText>
              {t("web_client.register_anticipated_switch_label")}
            </StyledBoldText>
            <Switch
              id="chargeAdvanceAppointments"
              value={feesFormValues.chargeAdvanceAppointments}
              checked={feesFormValues.chargeAdvanceAppointments}
              onChange={(e) => {
                e.persist()
                setWithChanges(true)
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  chargeAdvanceAppointments: e.target.checked,
                }))
              }}
            />
          </RowContainer>
          <RowContainer>
            <StyledBoldText>
              {t("web_client.register_cash_switch_label")}
            </StyledBoldText>
            <Switch
              id="chargeCashAppointments"
              value={feesFormValues.chargeCashAppointments}
              checked={feesFormValues.chargeCashAppointments}
              onChange={(e) => {
                e.persist()
                setWithChanges(true)
                setFeesFormValues((currentValues) => ({
                  ...currentValues,
                  chargeCashAppointments: e.target.checked,
                }))
              }}
            />
          </RowContainer>
        </StyledForm>
      ) : (
        <AllCenter>
          <Loader />
        </AllCenter>
      )}
    </Section>
  )
}

Fees.propTypes = {
  formValues: PropTypes.shape().isRequired,
  propertiesMissing: PropTypes.shape().isRequired,
  feesFormValues: PropTypes.shape().isRequired,
  providerPricing: PropTypes.shape().isRequired,
  businessFormValues: PropTypes.shape().isRequired,
  onChangeBusinessView: PropTypes.func.isRequired,
  handleBusinessInputChange: PropTypes.func.isRequired,
  setFeesFormValues: PropTypes.func.isRequired,
  setWithChanges: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default Fees
