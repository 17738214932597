import React, { useState, useEffect, useContext, useRef } from "react"
import * as Yup from "yup"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { withRouter } from "react-router-dom"
import { Formik, Form } from "formik"
import { useToasts } from "react-toast-notifications"
import {
  Box,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  Switch,
  useMediaQuery,
} from "@chakra-ui/react"
import debounce from "lodash.debounce"
import { AuthUserContext } from "../../../context/Session"

// Utils
import constants from "../../../utils/constants"
import { honeybadger, getProviderPricingUrl } from "../../../utils/App"

// Components
import { WithChakra } from "../../../components/atoms/WithChakra"
import CustomInput from "../../../components/chakra/CustomInput"
import CustomSelect from "../../../components/atoms/CustomSelect"

const FeesBase = ({
  goToBack,
  goToNext,
  userCreatedData,
  firebase,
  cardShowed,
}) => {
  const user = useContext(AuthUserContext)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [isCreating, setIsCreating] = useState(false)
  const [bankOptions, setBankOptions] = useState([])
  const [providerPricing, setProviderPricing] = useState({})

  const formRef = useRef()

  const durationOptions = [
    {
      value: 15,
      label: t("web_client.minutes", { minutes: "15" }),
    },
    {
      value: 30,
      label: t("web_client.minutes", { minutes: "30" }),
    },
    {
      value: 45,
      label: t("web_client.minutes", { minutes: "45" }),
    },
    {
      value: 60,
      label: t("web_client.minutes", { minutes: "60" }),
    },
  ]

  useEffect(() => {
    const currentCountry = get(userCreatedData, "countryBusiness", "country")

    const getBanks = async () => {
      const selectOptionsBanks = []
      try {
        const localBankOptions = await firebase.getBanksByCountry(
          currentCountry
        )
        localBankOptions?.data()?.options.forEach((doc) => {
          selectOptionsBanks.push({ value: doc, label: doc })
        })
        setBankOptions(selectOptionsBanks)
      } catch (e) {
        window.Honeybadger?.notify(e)
      }
    }
    if (currentCountry) {
      getBanks()
    }
  }, [firebase, userCreatedData])

  const getPricing = async () => {
    try {
      const authToken = await firebase.getIdToken()
      if (authToken) {
        const params = {
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        }
        const url = new URL(getProviderPricingUrl)
        Object.keys(params).forEach((k) => {
          url.searchParams.append(k, params[k])
        })

        const providerPricingData = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })

        if (providerPricingData.ok) {
          const pricing = await providerPricingData.json()
          setProviderPricing(pricing)
        }
      }
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  useEffect(() => {
    if (user && cardShowed === 3) {
      getPricing()
    }
  }, [user, cardShowed])

  const saveFees = async () => {
    await firebase.saveFeesData({
      email: user.isImpersonating ? user.impersonatingEmail : user.email,
      data: {
        virtual: {
          consultationFee:
            Number(formRef.current.values.virtualConsultation) || 0,
          consultationTime: Number(formRef.current.values.patient_time) || null,
          enabled: formRef.current.values.virtualAppointmentEnabled,
        },
        faceToFace: {
          consultationFee: Number(formRef.current.values.facingConsultation),
          consultationTime:
            formRef.current.values.patient_time_in_person || null,
          enabled: true, // faceToFace is required, so it always is enabled
        },
        home: {
          consultationFee: Number(formRef.current.values.homeConsultation) || 0,
          consultationTime:
            Number(formRef.current.values.homeVisitTime) || null,
          enabled: formRef.current.values.homeAppointmentEnabled,
        },
        questions: {
          consultationFee: Number(formRef.current.values.questions) || 0,
          enabled: formRef.current.values.questionsEnabled,
        },
        chargeAdvanceAppointments:
          formRef.current.values.chargeAdvanceAppointments,
        chargeCashAppointments: formRef.current.values.chargeCashAppointments,
      },
    })
    getPricing()
  }

  const saveFeesDebounce = async () => {
    debounce(saveFees, 300)()
  }

  const countryData = constants.countries.find(
    (country) =>
      country.value === get(userCreatedData, "countryBusiness", "country")
  )

  return (
    <AccordionItem borderTop="none">
      <Formik
        innerRef={formRef}
        initialValues={{
          // virtual
          virtualConsultation: null,
          patient_time: null,
          // end virtual

          // face to face
          facingConsultation: null,
          patient_time_in_person: null,
          // end face to face

          // home visit
          homeConsultation: null,
          homeVisitTime: null,
          // end home visit

          // questions
          questions: null,
          // end questions

          // Bank
          bankName: "",
          bankAccountType: "",
          bankAccountCurrency: "",
          bankAccount: "",
          // bankAccountCard: "",
          // End Bank

          // Switchs
          chargeAdvanceAppointments: false,
          chargeCashAppointments: false,

          virtualAppointmentEnabled: false,
          questionsEnabled: false,
          homeAppointmentEnabled: false,
          // End Switchs
          businessFormReady: false,
        }}
        validationSchema={Yup.object({
          facingConsultation: Yup.number()
            .required(t("error_all_fields_required"))
            .typeError(t("error_all_fields_required")),
          patient_time_in_person: Yup.number().required(
            t("error_all_fields_required")
          ),

          bankName: Yup.string().required(t("error_all_fields_required")),
          bankAccountType: Yup.string().required(
            t("error_all_fields_required")
          ),
          bankAccountCurrency: Yup.string().required(
            t("error_all_fields_required")
          ),
          bankAccount: Yup.string().required(t("error_all_fields_required")),
        })}
        onSubmit={async (values) => {
          try {
            if (userCreatedData) {
              setIsCreating(true)

              await firebase.updateProvider(
                {
                  registerStepCompleted: 4,
                  businessFormReady: true,
                  userType: "provider", // TODO: make a validation for patient
                  sessionPrice: get(values, "virtualConsultation", 1),
                  indexDocument: true,
                },
                userCreatedData.email.toLowerCase()
              )

              user.setUser({
                ...user,
                medicProfile: {
                  ...user.medicProfile,
                  sessionPrice: get(values, "virtualConsultation", 1),
                },
              })

              await firebase.saveCompanyData({
                email: userCreatedData.email,
                data: {
                  bankName: values.bankName,
                  bankAccountType: values.bankAccountType,
                  bankAccountCurrency: values.bankAccountCurrency,
                  bankAccount: values.bankAccount,
                  // bankAccountCard: values.bankAccountCard,
                },
              })

              await firebase.saveFeesData({
                email: userCreatedData.email,
                data: {
                  virtual: {
                    consultationFee: values.virtualConsultation || 0,
                    consultationTime: values.patient_time || 0,
                    enabled: values.virtualAppointmentEnabled,
                  },
                  faceToFace: {
                    consultationFee: values.facingConsultation,
                    consultationTime: values.patient_time_in_person,
                    enabled: true, // faceToFace is required, so it always is enabled
                  },
                  home: {
                    consultationFee: values.homeConsultation || 0,
                    consultationTime: values.homeVisitTime || 0,
                    enabled: values.homeAppointmentEnabled,
                  },
                  questions: {
                    consultationFee: values.questions || 0,
                    enabled: values.questionsEnabled,
                  },
                  chargeAdvanceAppointments: values.chargeAdvanceAppointments,
                  chargeCashAppointments: values.chargeCashAppointments,
                },
              })
              localStorage.clear()
              setIsCreating(false)
              goToNext()
            }
          } catch (err) {
            honeybadger.notify("Register - Fees Register Section", {
              message: "Error on Submit / Register Fees Section",
              action: "submit",
              context: {
                formValues: values,
                error: err,
              },
            })
            setIsCreating(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        }}
      >
        {(props) => (
          <Form>
            <h2>
              <AccordionButton
                background="#FFF"
                _hover={{ background: "#FFF" }}
                cursor="default"
              >
                <Box flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    flex="1"
                    justifyContent="flex-start"
                    alignItems="center"
                    borderBottom="1px solid #EDF2F7"
                  >
                    <Flex
                      width="32px"
                      height="32px"
                      borderRadius="32px"
                      background="#000"
                      color="#FFF"
                      justifyContent="center"
                      alignItems="center"
                      marginRight="10px"
                    >
                      4
                    </Flex>
                    <Flex minHeight="84px" padding="10px 0" alignItems="center">
                      <Text fontSize="18px" fontWeight="700" color="#171923">
                        {t("fees")}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              background="#FFF"
              mb={4}
              borderBottomRightRadius="10px"
              borderBottomLeftRadius="10px"
              padding="0px"
            >
              <Flex justifyContent="center" alignItems="center">
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  color="#000"
                  width={isMobile ? "90%" : "400px"}
                  textAlign="center"
                >
                  {t("web_client.register_fees_final_description")}
                </Text>
              </Flex>

              <Flex
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="space-between"
                alignItems={isMobile ? "flex-start" : "center"}
                width={isMobile ? "100%" : "80%"}
                minHeight="84px"
                padding={isMobile ? "10px 20px" : "10px 0"}
              >
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  marginLeft={isMobile ? "0" : "24px"}
                >
                  <Box marginRight="10px" height="22px">
                    *
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="#1A202C"
                    width="180px"
                  >
                    {t("web_client.register_fees_facing_label")}
                  </Text>
                </Flex>
                <CustomInput
                  defaultValue={
                    providerPricing?.pricing?.faceToFace?.totals.local
                      ?.sessionBasePrice
                  }
                  countryCurrency={get(countryData, "currencyCode", "")}
                  placeholder={t("web_client.register_local_money_placeholder")}
                  width="320px"
                  height="32px"
                  name="facingConsultation"
                  id="facingConsultation"
                  type="number"
                  showErrorMessage={
                    props.errors.facingConsultation &&
                    props.touched.facingConsultation
                  }
                  handleInputOnBlur={() =>
                    props.setTouched({
                      facingConsultation: true,
                    })
                  }
                  errorMessage={props.errors.facingConsultation}
                  handleInputChange={(e) => {
                    props.setFieldValue(
                      "facingConsultation",
                      Number(e.target.value)
                    )
                    saveFeesDebounce()
                  }}
                />
              </Flex>

              <Box backgroundColor="#F7FAFC" width="100%">
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      color="#1A202C"
                      width="180px"
                    >
                      {t("web_client.register_cash_doc_charges")}
                    </Text>
                  </Flex>
                  <CustomInput
                    countryCurrency={get(countryData, "currencyCode", "")}
                    placeholder={t(
                      "web_client.register_local_money_placeholder"
                    )}
                    width="320px"
                    height="32px"
                    type="number"
                    name="register_cash_doc_charges"
                    id="register_cash_doc_charges"
                    defaultValue={
                      providerPricing?.pricing?.faceToFace?.totals.local
                        ?.sessionCashFees
                    }
                    isReadOnly
                  />
                </Flex>
              </Box>

              <Flex
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="space-between"
                alignItems={isMobile ? "flex-start" : "center"}
                width={isMobile ? "100%" : "80%"}
                minHeight="84px"
                padding={isMobile ? "10px 20px" : "10px 0"}
              >
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  marginLeft={isMobile ? "0" : "24px"}
                >
                  <Box marginRight="10px" height="22px">
                    *
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="#1A202C"
                    width={isMobile ? "100%" : "170px"}
                  >
                    {t("web_client.register_fees_cash_facing_total_label")}
                  </Text>
                </Flex>
                <CustomInput
                  countryCurrency={get(countryData, "currencyCode", "")}
                  placeholder={t("web_client.register_local_money_placeholder")}
                  width="320px"
                  height="32px"
                  type="number"
                  name="facingConsultationCashTotalCharges"
                  id="facingConsultationCashTotalCharges"
                  defaultValue={
                    providerPricing?.pricing?.faceToFace?.totals.local
                      ?.sessionTotalCashPrice
                  }
                  isReadOnly
                />
              </Flex>

              <Box
                borderBottom="1px solid #E2E8F0"
                backgroundColor="#F7FAFC"
                width="100%"
              >
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width="90%"
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text fontSize="16px" fontWeight="400" color="#1A202C">
                      {t("web_client.register_fees_facing_duration_label")}
                    </Text>
                  </Flex>
                  <Box
                    width={isMobile ? "320px" : "360px"}
                    height="40px"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <CustomSelect
                      formKey="patient_time_in_person"
                      lngKey="web_client.duration"
                      items={durationOptions}
                      handleChange={(key, value) => {
                        props.setFieldValue(key, value)
                      }}
                      showError={
                        props.errors.patient_time_in_person &&
                        props.submitCount > 0
                      }
                      value={props.values.patient_time_in_person}
                    />
                  </Box>
                </Flex>
              </Box>

              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width="90%"
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Text fontSize="16px" fontWeight="600" color="#1A202C">
                        {t("web_client.register_virtual_switch")}
                      </Text>
                    </Flex>
                    <Flex
                      width={isMobile ? "320px" : "300px"}
                      height="40px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Switch
                        name="virtualAppointmentEnabled"
                        id="virtualAppointmentEnabled"
                        isChecked={props.values.virtualAppointmentEnabled}
                        onChange={(e) => {
                          e.persist()
                          props.setFieldValue(
                            "virtualAppointmentEnabled",
                            e.target.checked
                          )
                          saveFeesDebounce()
                        }}
                      />
                    </Flex>
                  </Flex>
                </Box>

                {props.values.virtualAppointmentEnabled && (
                  <>
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width={isMobile ? "100%" : "80%"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          color="#1A202C"
                          width="180px"
                        >
                          {t("apt_virtual")}
                        </Text>
                      </Flex>
                      <CustomInput
                        defaultValue={
                          providerPricing?.pricing?.virtual?.totals.local
                            ?.sessionBasePrice
                        }
                        countryCurrency={get(countryData, "currencyCode", "")}
                        placeholder={t(
                          "web_client.register_local_money_placeholder"
                        )}
                        width="320px"
                        height="32px"
                        type="number"
                        name="virtualConsultation"
                        id="virtualConsultation"
                        showErrorMessage={
                          props.errors.virtualConsultation &&
                          props.touched.virtualConsultation
                        }
                        handleInputOnBlur={() =>
                          props.setTouched({ virtualConsultation: true })
                        }
                        errorMessage={props.errors.virtualConsultation}
                        handleInputChange={(e) => {
                          props.setFieldValue(
                            "virtualConsultation",
                            Number(e.target.value)
                          )
                          saveFeesDebounce()
                        }}
                      />
                    </Flex>
                    <Box backgroundColor="#F7FAFC" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_doc_charges")}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          type="number"
                          width="320px"
                          height="32px"
                          name="virtualConsultationDocCharges"
                          id="virtualConsultationDocCharges"
                          defaultValue={
                            providerPricing?.pricing?.virtual?.totals.local
                              ?.sessionDocFees
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width={isMobile ? "100%" : "80%"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          color="#1A202C"
                          width="180px"
                        >
                          {t("web_client.register_total_charges")}
                        </Text>
                      </Flex>
                      <CustomInput
                        countryCurrency={get(countryData, "currencyCode", "")}
                        placeholder={t(
                          "web_client.register_local_money_placeholder"
                        )}
                        width="320px"
                        height="32px"
                        type="number"
                        name="virtualConsultationTotalCharges"
                        id="virtualConsultationTotalCharges"
                        defaultValue={
                          providerPricing?.pricing?.virtual?.totals.local
                            ?.sessionTotalPrice
                        }
                        isReadOnly
                      />
                    </Flex>

                    <Box
                      borderBottom="1px solid #E2E8F0"
                      backgroundColor="#F7FAFC"
                      width="100%"
                    >
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width="90%"
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                          >
                            {t("web_client.register_virtual_duration")}
                          </Text>
                        </Flex>
                        <Box
                          width={isMobile ? "320px" : "360px"}
                          height="40px"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <CustomSelect
                            formKey="patient_time"
                            lngKey="web_client.duration"
                            items={durationOptions}
                            handleChange={(key, value) => {
                              props.setFieldValue(key, value)
                            }}
                            showError={
                              props.errors.patient_time && props.submitCount > 0
                            }
                            value={props.values.patient_time}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  </>
                )}

                <Box
                  width="100%"
                  borderBottom={
                    props.values.homeAppointmentEnabled
                      ? "none"
                      : "1px solid #E2E8F0"
                  }
                >
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width="90%"
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Text fontSize="16px" fontWeight="600" color="#1A202C">
                        {t("web_client.register_home_switch")}
                      </Text>
                    </Flex>
                    <Flex
                      width={isMobile ? "320px" : "300px"}
                      height="40px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Switch
                        name="homeAppointmentEnabled"
                        id="homeAppointmentEnabled"
                        isChecked={props.values.homeAppointmentEnabled}
                        onChange={(e) => {
                          e.persist()
                          props.setFieldValue(
                            "homeAppointmentEnabled",
                            e.target.checked
                          )
                          saveFeesDebounce()
                        }}
                      />
                    </Flex>
                  </Flex>
                </Box>

                {props.values.homeAppointmentEnabled && (
                  <>
                    <Box backgroundColor="#F7FAFC" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_fees_home_label")}
                          </Text>
                        </Flex>
                        <CustomInput
                          defaultValue={
                            providerPricing?.pricing?.home?.totals.local
                              ?.sessionBasePrice
                          }
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          name="homeConsultation"
                          id="homeConsultation"
                          type="number"
                          handleInputChange={(e) => {
                            props.setFieldValue(
                              "homeConsultation",
                              Number(e.target.value)
                            )
                            saveFeesDebounce()
                          }}
                        />
                      </Flex>
                    </Box>

                    <Box width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_doc_charges")}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          type="number"
                          name="homeConsultationDocCharges"
                          id="homeConsultationDocCharges"
                          defaultValue={
                            providerPricing?.pricing?.home?.totals.local
                              ?.sessionDocFees
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Box backgroundColor="#F7FAFC" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_fees_home_total_label")}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          type="number"
                          name="homeConsultationTotalCharges"
                          id="homeConsultationTotalCharges"
                          defaultValue={
                            providerPricing?.pricing?.home?.totals.local
                              ?.sessionTotalPrice
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Box width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_cash_doc_charges")}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          type="number"
                          name="homeConsultationCashDocCharges"
                          id="homeConsultationCashDocCharges"
                          defaultValue={
                            providerPricing?.pricing?.home?.totals.local
                              ?.sessionCashFees
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Box backgroundColor="#F7FAFC" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t(
                              "web_client.register_fees_cash_home_total_label"
                            )}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          type="number"
                          name="homeConsultationCashTotalCharges"
                          id="homeConsultationCashTotalCharges"
                          defaultValue={
                            providerPricing?.pricing?.home?.totals.local
                              ?.sessionTotalCashPrice
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Box borderBottom="1px solid #E2E8F0" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width="90%"
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                          >
                            {t("web_client.register_fees_home_duration_label")}
                          </Text>
                        </Flex>
                        <Box
                          width={isMobile ? "320px" : "360px"}
                          height="40px"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <CustomSelect
                            formKey="homeVisitTime"
                            lngKey="web_client.duration"
                            items={durationOptions}
                            handleChange={(key, value) => {
                              props.setFieldValue(key, value)
                            }}
                            showError={
                              props.errors.homeVisitTime &&
                              props.submitCount > 0
                            }
                            value={props.values.homeVisitTime}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  </>
                )}

                <Box
                  backgroundColor="#F7FAFC"
                  width="100%"
                  borderBottom={
                    props.values.questionsEnabled ? "none" : "1px solid #E2E8F0"
                  }
                >
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width="90%"
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Text fontSize="16px" fontWeight="600" color="#1A202C">
                        {t("web_client.register_questions_switch")}
                      </Text>
                    </Flex>
                    <Flex
                      width={isMobile ? "320px" : "300px"}
                      height="40px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Switch
                        name="questionsEnabled"
                        id="questionsEnabled"
                        isChecked={props.values.questionsEnabled}
                        onChange={(e) => {
                          e.persist()
                          props.setFieldValue(
                            "questionsEnabled",
                            e.target.checked
                          )
                          saveFeesDebounce()
                        }}
                      />
                    </Flex>
                  </Flex>
                </Box>

                {props.values.questionsEnabled && (
                  <>
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width={isMobile ? "100%" : "80%"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          color="#1A202C"
                          width="180px"
                        >
                          {t("web_client.register_fees_questions")}
                        </Text>
                      </Flex>
                      <CustomInput
                        defaultValue={
                          providerPricing?.pricing?.questions?.totals.local
                            ?.sessionBasePrice
                        }
                        countryCurrency={get(countryData, "currencyCode", "")}
                        placeholder={t(
                          "web_client.register_local_money_placeholder"
                        )}
                        width="320px"
                        height="32px"
                        type="number"
                        name="questions"
                        id="questions"
                        handleInputChange={(e) => {
                          props.setFieldValue(
                            "questions",
                            Number(e.target.value)
                          )
                          saveFeesDebounce()
                        }}
                      />
                    </Flex>

                    <Box backgroundColor="#F7FAFC" width="100%">
                      <Flex
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        width={isMobile ? "100%" : "80%"}
                        minHeight="84px"
                        padding={isMobile ? "10px 20px" : "10px 0"}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={isMobile ? "0" : "24px"}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#1A202C"
                            width="180px"
                          >
                            {t("web_client.register_doc_charges")}
                          </Text>
                        </Flex>
                        <CustomInput
                          countryCurrency={get(countryData, "currencyCode", "")}
                          placeholder={t(
                            "web_client.register_local_money_placeholder"
                          )}
                          width="320px"
                          height="32px"
                          type="number"
                          name="questionsDocCharges"
                          id="questionsDocCharges"
                          defaultValue={
                            providerPricing?.pricing?.questions?.totals.local
                              ?.sessionDocFees
                          }
                          isReadOnly
                        />
                      </Flex>
                    </Box>

                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width={isMobile ? "100%" : "80%"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                      borderBottom="1px solid #E2E8F0"
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          color="#1A202C"
                          width="180px"
                        >
                          {`Total ${t("web_client.register_fees_questions")}`}
                        </Text>
                      </Flex>
                      <CustomInput
                        countryCurrency={get(countryData, "currencyCode", "")}
                        placeholder={t(
                          "web_client.register_local_money_placeholder"
                        )}
                        width="320px"
                        height="32px"
                        type="number"
                        name="questionsTotalCharges"
                        id="questionsTotalCharges"
                        defaultValue={
                          providerPricing?.pricing?.questions?.totals.local
                            ?.sessionTotalPrice
                        }
                        isReadOnly
                      />
                    </Flex>
                  </>
                )}

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Text
                    textAlign="center"
                    fontSize="16px"
                    fontWeight="600"
                    color="#1A202C"
                    marginTop="40px"
                    marginBottom="20px"
                  >
                    {t("q8_business_bankName_description")}
                  </Text>
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="#1A202C"
                        width="180px"
                      >
                        {t("q8_business_bankName_label")}
                      </Text>
                    </Flex>
                    <Box
                      width={isMobile ? "320px" : "360px"}
                      height="40px"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <CustomSelect
                        formKey="bankName"
                        lngKey="name"
                        items={bankOptions}
                        handleChange={props.setFieldValue}
                        showError={
                          props.errors.bankName && props.submitCount > 0
                        }
                        value={props.values.bankName}
                      />
                    </Box>
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      color="#1A202C"
                      width="180px"
                    >
                      {t("q9_business_bankAccountType_label")}
                    </Text>
                  </Flex>
                  <Box
                    width="320px"
                    height="40px"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <CustomSelect
                      formKey="bankAccountType"
                      lngKey="options"
                      items={constants.accountsType.map((i) => ({
                        value: i.value,
                        label: t(i.label),
                      }))}
                      handleChange={(key, value) => {
                        props.setFieldValue(key, value)
                      }}
                      showError={
                        props.errors.bankAccountType && props.submitCount > 0
                      }
                      value={props.values.bankAccountType}
                    />
                  </Box>
                </Flex>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="16px" fontWeight="400" color="#1A202C">
                        {t("q9_business_bankAccountCurrency_label")}
                      </Text>
                    </Flex>
                    <Box
                      width="320px"
                      height="40px"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <CustomSelect
                        formKey="bankAccountCurrency"
                        lngKey="options"
                        items={constants.accountsCurrencyType.map((i) => ({
                          value: i.value,
                          label: t(i.label),
                        }))}
                        handleChange={(key, value) => {
                          props.setFieldValue(key, value)
                        }}
                        showError={
                          props.errors.bankAccountCurrency &&
                          props.submitCount > 0
                        }
                        value={props.values.bankAccountCurrency}
                      />
                    </Box>
                  </Flex>
                </Box>

                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={isMobile ? "0" : "24px"}
                  >
                    <Box marginRight="10px" height="22px">
                      *
                    </Box>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      color="#1A202C"
                      width={isMobile ? "100%" : "180px"}
                    >
                      {t("q9_business_bankAccount_label")}
                    </Text>
                  </Flex>
                  <CustomInput
                    placeholder="(Código interbancario, IBAN-CCI)"
                    width="320px"
                    height="32px"
                    type="text"
                    name="bankAccount"
                    id="bankAccount"
                    showErrorMessage={
                      props.errors.bankAccount && props.touched.bankAccount
                    }
                    handleInputOnBlur={() =>
                      props.setTouched({
                        bankAccount: true,
                      })
                    }
                    errorMessage={props.errors.bankAccount}
                    handleInputChange={(e) =>
                      props.setFieldValue("bankAccount", e.target.value)
                    }
                    props={{ maxLength: 24 }}
                  />
                </Flex>

                {/*                <Box backgroundColor="#F7FAFC" width="100%">
                <Flex
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  width={isMobile ? "100%" : "80%"}
                  minHeight="84px"
                  padding={isMobile ? "10px 20px" : "10px 0"}
                >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="#1A202C"
                        width="200px"
                      >
                        {t("web_client.register_creditcard_number")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder="**** **** **** 9050"
                      width="320px"
                      height="32px"
                      name="bankAccountCard"
                      id="bankAccountCard"
                      handleInputChange={(e) =>
                        props.setFieldValue("bankAccountCard", e.target.value)
                      }
                    />
                  </Flex>
                </Box>


                    */}
                <Flex
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="150px"
                >
                  <Flex
                    width={isMobile ? "160px" : "300px"}
                    justifyContent="space-around"
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : "center"}
                  >
                    <Switch
                      name="chargeAdvanceAppointments"
                      id="chargeAdvanceAppointments"
                      isChecked={props.values.chargeAdvanceAppointments}
                      onChange={(e) => {
                        e.persist()
                        props.setFieldValue(
                          "chargeAdvanceAppointments",
                          e.target.checked
                        )
                      }}
                    />
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {t("web_client.register_anticipated_switch_label")}
                    </Text>
                  </Flex>
                  <Flex
                    width={isMobile ? "160px" : "300px"}
                    justifyContent="space-around"
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : "center"}
                  >
                    <Switch
                      name="chargeCashAppointments"
                      id="chargeCashAppointments"
                      isChecked={props.values.chargeCashAppointments}
                      onChange={(e) => {
                        e.persist()
                        props.setFieldValue(
                          "chargeCashAppointments",
                          e.target.checked
                        )
                      }}
                    />
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {t("web_client.register_cash_switch_label")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </AccordionPanel>
            <AccordionPanel pb={4}>
              {!isEmpty(props.errors) && props.submitCount >= 1 && (
                <Text
                  fontSize="12px"
                  color="rgb(98, 0, 238)"
                  fontWeight="500"
                  width="500px"
                  mb="20px"
                >
                  {t("error_all_fields_required_remember")}
                </Text>
              )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="220px"
              >
                <Button
                  colorScheme="blackAlpha"
                  width="104px"
                  height="40px"
                  color="#000"
                  fontSize="16px"
                  fontWeight="bold"
                  onClick={goToBack}
                >
                  {t("go_back")}
                </Button>
                <Button
                  colorScheme="blue"
                  background="#576EF5"
                  width="104"
                  height="40px"
                  borderRadius="6px"
                  color="#FFF"
                  fontSize="18px"
                  fontWeight="normal"
                  isLoading={isCreating}
                  type="submit"
                >
                  {t("finish")}
                </Button>
              </Box>
            </AccordionPanel>
          </Form>
        )}
      </Formik>
    </AccordionItem>
  )
}

const Fees = compose(withRouter, WithChakra)(FeesBase)
export default Fees
