import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import CryptoJS from "crypto-js"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { useHistory, withRouter, useLocation } from "react-router-dom"
import {
  VStack,
  Box,
  Image,
  Flex,
  Text,
  Accordion,
  useMediaQuery,
} from "@chakra-ui/react"

// Children
import GeneralInformation from "./generalInformation"
import ProfessionalInformation from "./professionalInformation"
import PrescriptionInformation from "./prescriptionInformation"
import Fees from "./fees"

// Components
import { WithChakra } from "../../components/atoms/WithChakra"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"
import ProgressBar from "../../components/chakra/ProgressBar"

// Utils
import { withFirebase } from "../../utils/Firebase"
import { AuthUserContext } from "../../context/Session"
import { honeybadger } from "../../utils/App"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"

const RegisterBase = ({ firebase }) => {
  const user = useContext(AuthUserContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const userTypeSelected = get(state, ["userType"], {})
  const userEmailFromLogin = get(state, "userEmail")
  const [showCard, setNextCard] = useState(0)
  const [userCreated, setUserCreated] = useState({})
  const [isFromBack, setIsFromBack] = useState(false)
  const [formValues, setFormValues] = useState({})

  const userType = get(user, ["medicProfile", "userType"])

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const profile = await firebase.getProfile({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        })
        return profile.data()
      }
      const Profile = await getProfile()
      if (Profile) {
        setFormValues(Profile)
        const getBusinessForm = async () => {
          try {
            const Business = await firebase.getBusiness({
              email: user.isImpersonating
                ? user.impersonatingEmail
                : user.email,
            })
            const businessData = Business.data()
            const Academic = await firebase.getAcademic({
              email: user.isImpersonating
                ? user.impersonatingEmail
                : user.email,
            })
            const academicData = Academic.data()

            setFormValues((prevUser) => ({
              ...prevUser,
              businessForm: businessData,
              academicForm: academicData,
            }))
          } catch (e) {
            window.Honeybadger?.notify(e)
          }
        }
        getBusinessForm()
      }
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType, showCard])

  const updateUserProfileRegisterStep = () => {
    if (!isFromBack) {
      const getStepCompleted =
        localStorage.getItem("registerStepCompleted") || 0
      setNextCard(Number(getStepCompleted))
    }
  }

  const getUserProfile = async () => {
    const userActive = await firebase.getCurrentUser()
    const getUserFromLS = localStorage.getItem("userRegister")
    const getUserStepCompleted = localStorage.getItem("registerStepCompleted")
    const parseUser = JSON.parse(getUserFromLS)

    if (parseUser) {
      const userPassword = CryptoJS.AES.decrypt(
        get(parseUser, "password", ""),
        "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
      )
      const userConfirmPassword = CryptoJS.AES.decrypt(
        get(parseUser, "confirmPassword", ""),
        "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
      )
      const userEmail = get(parseUser, "email")
      setUserCreated({
        ...parseUser,
        registerStepCompleted: getUserStepCompleted,
        password: userPassword.toString(CryptoJS.enc.Utf8),
        confirmPassword: userConfirmPassword.toString(CryptoJS.enc.Utf8),
      })

      if (user) {
        user.setUser({
          ...user,
          medicProfile: {
            ...user.medicProfile,
            photoURL: parseUser.photoURL || null,
            languages: parseUser.languages || null,
            gender: parseUser.gender || null,
            reference: parseUser.reference || null,
            referenceType: parseUser.referenceType || null,
            organizationReference: parseUser.organizationReference || null,
            organizationAgent: parseUser.organizationAgent || null,
            referenceCountry: parseUser.referenceCountry || null,
          },
        })
      }

      updateUserProfileRegisterStep()

      if (userActive) {
        updateUserProfileRegisterStep()
      } else if (userEmail && userPassword && !userActive) {
        await firebase
          .doSignInWithEmailAndPassword({
            userEmail,
            userPassword,
          })
          .then(() => {
            updateUserProfileRegisterStep()
          })
          .catch((err) => {
            honeybadger.notify("Register index", {
              message: "Error on login user with pending registration",
              context: {
                error: err,
              },
            })
          })
      }
    }
  }

  useEffect(() => {
    if (firebase) {
      getUserProfile()
    }
  }, [firebase, showCard])

  const goToBack = () => {
    setNextCard((prevCard) => prevCard - 1)
    setIsFromBack(true)
  }

  const goToNext = () => {
    setNextCard((prevCard) => prevCard + 1)
  }

  const goToConcludeRegister = () => {
    history.push("/verify")
  }

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  const goToWho = () => {
    history.push("/setup-account/userType")
  }

  const getStepsProgress = (step) => {
    const progress = {
      0: "0%",
      1: "25%",
      2: "55%",
      3: "90%",
    }

    return progress[step]
  }

  return (
    <Flex
      direction="column"
      width="full"
      height="100vh"
      overflowX="hidden"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
      paddingTop="76px"
    >
      <Box
        boxSize="sm"
        position="fixed"
        top={isMobile ? "0" : "12px"}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="50px"
        padding="0px 20px"
        zIndex="2000"
        background={isMobile ? "#FFF" : "transparent"}
      >
        <Box cursor="pointer">
          <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="200px"
        >
          <HelpButton onClick={() => goToHelp()} />
          <LanguageSelector isFromLogin />
        </Box>
      </Box>
      <VStack
        borderRadius="8px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text
          fontWeight="400"
          fontSize={isMobile ? "24px" : "36px"}
          fontFamily="Circular Std Medium"
          lineHeight={isMobile ? "40px" : "50px"}
          width="400px"
          textAlign="center"
        >
          {t("web_client.register_welcome")}
        </Text>
        <Text
          fontWeight="400"
          fontSize="14px"
          fontFamily="Circular Std Medium"
          lineHeight="18px"
          width="400px"
          textAlign="center"
        >
          {t("web_client.register_description")}
        </Text>
        <div style={{ height: 30 }} />
        <Box width={isMobile ? "90%" : "760px"} height="10px">
          <ProgressBar percentage={getStepsProgress(showCard)} />
        </Box>
        <Accordion
          defaultIndex={[showCard]}
          index={[showCard]}
          width={isMobile ? "100%" : "768px"}
        >
          <GeneralInformation
            firebase={firebase}
            goToBack={goToWho}
            goToNext={goToNext}
            userTypeSelected={userTypeSelected}
            cardShowed={showCard}
            userEmailFromLogin={userEmailFromLogin}
            userCreatedData={userCreated}
            setNextCard={setNextCard}
            setIsFromBack={setIsFromBack}
            isFromBack={isFromBack}
          />
          <ProfessionalInformation
            goToBack={goToBack}
            goToNext={goToNext}
            firebase={firebase}
            userCreatedData={userCreated}
            cardShowed={showCard}
            formValues={formValues}
            setNextCard={setNextCard}
            isFromBack={isFromBack}
          />
          <PrescriptionInformation
            goToBack={goToBack}
            goToNext={goToNext}
            firebase={firebase}
            userCreatedData={userCreated}
            cardShowed={showCard}
            formValues={formValues}
            setNextCard={setNextCard}
            isFromBack={isFromBack}
          />
          <Fees
            goToBack={goToBack}
            goToNext={goToConcludeRegister}
            firebase={firebase}
            userCreatedData={userCreated}
            cardShowed={showCard}
            formValues={formValues}
          />
        </Accordion>
      </VStack>
    </Flex>
  )
}

RegisterBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const Register = compose(withRouter, WithChakra, withFirebase)(RegisterBase)
export default Register
