/* eslint-disable no-undef */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Search2Icon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  chakra,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
  FormControl,
} from "@chakra-ui/react"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"

import Search from "./Search"
import LogoV2 from "../../../static/images/logoV2.svg"

const ChakraInputStyled = chakra(Input, {
  baseStyle: {
    minW: { base: "320px", md: "534px" },
    my: 2,
    pr: { base: 10, md: 0 },
    border: "1px solid #E2E8F0 !important",
  },
})

const defaultCrPosition = {
  lat: 9.93333,
  lng: -84.08333,
}

const options = {
  disableDefaultUI: true,
  zoomControl: true,
}

const LocationSelector = ({
  setCustomValue,
  errors,
  values,
  userDefaultCoords,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  }

  const [libraries] = useState(["places"])
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const mapRef = React.useRef()

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
  }, [])

  const [lat, setLat] = useState(defaultCrPosition.lat)
  const [lng, setLng] = useState(defaultCrPosition.lng)
  const [coordinates, setCoordinates] = useState("")
  const [markers, setMarkers] = useState({
    lat: defaultCrPosition.lat,
    lng: defaultCrPosition.lng,
  })

  const panTo = React.useCallback((latValue, lngValue) => {
    setMarkers({ lat: latValue, lng: lngValue })
    setLat(latValue)
    setLng(lngValue)
    mapRef.current.panTo({ lat: latValue, lng: lngValue })
    mapRef.current.setZoom(16)
  }, [])

  useEffect(() => {
    if (userDefaultCoords?.latitude && userDefaultCoords?.longitude) {
      setMarkers({
        lat: userDefaultCoords?.latitude,
        lng: userDefaultCoords?.longitude,
      })
      setLat(userDefaultCoords?.latitude)
      setLng(userDefaultCoords?.longitude)
    }
  }, [userDefaultCoords, mapRef])

  const onMapClick = React.useCallback((event) => {
    const latValue = event.latLng?.lat()
    const lngValue = event.latLng?.lng()
    if (latValue && lngValue) {
      setLat(latValue)
      setLng(lngValue)
      setMarkers({ lat: latValue, lng: lngValue })
    }
  }, [])

  if (loadError) return <div>Error Loading maps</div>
  if (!isLoaded) return <div>Loading Maps</div>

  const errorMessage = errors?.cordsError
    ? t("error_cords")
    : errors?.coordinates
  const getCordinates = values?.coordinates?.latitude
    ? `${values.coordinates.latitude}, ${values.coordinates.longitude}`
    : ""

  return (
    <>
      <FormControl isInvalid={!!errorMessage}>
        <VStack alignItems="flex-start">
          <Box>
            <InputGroup>
              <ChakraInputStyled
                name="coordinates"
                id="coordinates"
                value={
                  values?.coordinates?.latitude ? getCordinates : coordinates
                }
                onChange={(e) => {
                  setCoordinates(e.target.value)
                  if (setCustomValue) {
                    setCustomValue("coordinates", e.target.value)
                  }
                }}
                placeholder={t("address_cords_placeholder")}
              />
              <InputRightElement my={2}>
                <Button size="sm" onClick={onOpen} variant="ghost">
                  <Search2Icon color="#000" />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box>
            <FormErrorMessage mt={-2} mb={-2}>
              {errorMessage}
            </FormErrorMessage>
          </Box>
        </VStack>
      </FormControl>
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#FFF">
          <ModalHeader>Seleccionar ubicación de su negocio</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Search panTo={panTo} addressFromOut={values?.coordinates} />
            <Center h="300" w="500" mt="4">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={{ lat, lng }}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
              >
                <Marker
                  position={markers}
                  draggable
                  onDragEnd={onMapClick}
                  icon={LogoV2}
                />
              </GoogleMap>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              mr={2}
              size="md"
              colorScheme="blue"
              onClick={() => {
                setCoordinates({
                  latitude: lat,
                  longitude: lng,
                })
                if (setCustomValue) {
                  setCustomValue("coordinates", {
                    latitude: lat,
                    longitude: lng,
                  })
                }
                onClose()
              }}
            >
              Salvar
            </Button>
            <Button onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LocationSelector
