import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { Box, Flex, Text, Textarea, useMediaQuery } from "@chakra-ui/react"

// Components
import ImageUploader from "../../../../molecules/ImageUploader"
import Tooltip from "../../../../atoms/Tooltip"

// Chackra
import CountriesSelector from "../../../../chakra/CountriesSelector"
import LocationSelector from "../../../../chakra/LocationSelector"

// Styles
import { Section, Images } from "../../styles"

export const Business = ({
  coordsError,
  businessFormValues,
  propertiesMissing,
  setImageCompanyURL,
  setImageCompanyStampURL,
  setBusinessFormValues,
  showCoordsError,
  onChangeBusinessView,
  setWithChanges,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const { t } = useTranslation()

  const { addToast } = useToasts()
  const [userCoords, setUserCords] = useState({})

  const onChangeBusinessLocation = (key = "", value) => {
    if (key === "coordinates") {
      if (value?.latitude || value?.longitude) {
        showCoordsError(false)
      } else {
        showCoordsError(true)
      }
    }

    if (key === "country" && value !== businessFormValues?.address?.country) {
      onChangeBusinessView({ value: null }, "bankName")
      onChangeBusinessView({ value: null }, "bankAccountType")
      onChangeBusinessView({ value: null }, "bankAccountCurrency")
      onChangeBusinessView({ value: null }, "bankAccount")
    }

    setBusinessFormValues((currentValues) => ({
      ...currentValues,
      address: {
        ...currentValues.address,
        [key]: value,
      },
    }))
    setWithChanges(true)
  }

  const successCallback = (position) => {
    setUserCords(position?.coords)
  }

  const errorCallback = () => {
    addToast(t("location_error"), { appearance: "error" })
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }, [])

  return (
    <Section>
      <Images>
        <ImageUploader
          src={
            get(businessFormValues, "companyStamp")
              ? get(
                  businessFormValues,
                  ["companyStamp", "blobUrl"],
                  businessFormValues.companyStamp
                )
              : null
          }
          onImageLoaded={(field, data) => {
            setImageCompanyStampURL(data)
            setBusinessFormValues((currentValues) => ({
              ...currentValues,
              [field]: data,
            }))
            setWithChanges(true)
          }}
          fieldName="companyStamp"
          width={130}
          height={130}
          emptyMessage={t("web_client.medical_stamp_label")}
          showError={get(propertiesMissing, "companyStamp", false)}
        />
        <Tooltip title={t("web_client.business_logo_tooltip")}>
          <ImageUploader
            src={
              get(businessFormValues, "companyLogo")
                ? get(
                    businessFormValues,
                    ["companyLogo", "blobUrl"],
                    businessFormValues.companyLogo
                  )
                : null
            }
            onImageLoaded={(field, data) => {
              setImageCompanyURL(data)
              setBusinessFormValues((currentValues) => ({
                ...currentValues,
                [field]: data,
              }))
              setWithChanges(true)
            }}
            fieldName="companyLogo"
            width={130}
            height={130}
            emptyMessage="Logo"
            showError={get(propertiesMissing, "companyLogo", false)}
          />
        </Tooltip>
      </Images>
      <Flex
        direction="column"
        width="768px"
        backgroundColor="rgb(247, 250, 252)"
        borderRadius="10px"
        mt="100px"
      >
        <Box width="100%" paddingLeft={isMobile ? "20px" : "0"}>
          <CountriesSelector
            onChange={onChangeBusinessLocation}
            values={businessFormValues?.address}
            hasSubmitions={1}
            errors={{ country: propertiesMissing?.countryBusiness }}
          />
        </Box>
        <Box width="100%">
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            minHeight="84px"
            padding={isMobile ? "10px 20px" : "10px 0"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            width="97%"
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              marginLeft={isMobile ? "0" : "24px"}
            >
              <Box marginRight="10px" height="22px">
                *
              </Box>
              <Text fontSize="14px" fontWeight="600" color="#1A202C">
                {t("address_street1_label")}
              </Text>
            </Flex>
            <Box>
              <Textarea
                _hover={{}}
                variant="outline"
                _placeholder={{
                  color: "gray.500",
                }}
                focusBorderColor="#E2E8F0"
                border="1px solid #E2E8F0 !important"
                borderRadius="6px"
                color="gray.500"
                fontSize="16px"
                placeholder=""
                width={isMobile ? "320px" : "528px"}
                height="32px"
                type="text"
                name="addressDetails"
                id="addressDetails"
                onChange={(e) =>
                  onChangeBusinessLocation("addressDetails", e.target.value)
                }
                defaultValue={get(
                  businessFormValues,
                  "address.addressDetails",
                  null
                )}
              />
            </Box>
          </Flex>
        </Box>
        <Box width="100%">
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            minHeight="84px"
            padding={isMobile ? "10px 20px" : "10px 0"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            width="97%"
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              marginLeft={isMobile ? "0" : "24px"}
            >
              <Box marginRight="10px" height="22px">
                *
              </Box>
              <Text fontSize="14px" fontWeight="600" color="#1A202C">
                {t("address_street_cords")}
              </Text>
            </Flex>
            <Box>
              <LocationSelector
                errors={{
                  cordsError: coordsError || !!propertiesMissing?.address,
                }}
                setCustomValue={onChangeBusinessLocation}
                values={businessFormValues?.address}
                userDefaultCoords={userCoords}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Section>
  )
}

Business.propTypes = {
  propertiesMissing: PropTypes.shape().isRequired,
  coordsError: PropTypes.bool.isRequired,
  businessFormValues: PropTypes.shape().isRequired,
  setImageCompanyURL: PropTypes.func.isRequired,
  setImageCompanyStampURL: PropTypes.func.isRequired,
  setBusinessFormValues: PropTypes.func.isRequired,
  showCoordsError: PropTypes.func.isRequired,
  onChangeBusinessView: PropTypes.func.isRequired,
  setWithChanges: PropTypes.func.isRequired,
}

export default Business
