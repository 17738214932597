/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { v4 as uuidv4 } from "uuid"
import { customAlphabet } from "nanoid"
import moment from "moment"
import ProgressBar from "react-progress-bar-plus"
import { Close } from "@styled-icons/material/Close"
import get from "lodash.get"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import CustomModal from "../../atoms/CustomModal"
import { Name } from "../../atoms/Card/styles"
import Input from "../../atoms/Input"
import SectionTitle from "../../atoms/SectionTitle"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import { withFirebase } from "../../../utils/Firebase"
import { getPdfUrl } from "../../../utils/App"
import { AuthUserContext } from "../../../context/Session"
import ChatContext from "../../../context/Chat"
import { addMessage } from "../../../context/Chat/reducer"
import Theme from "../../../styles/theme"
import imagesData from "./data"
import {
  Container,
  EditableWrapper,
  CloseButton,
  HeaderTools,
  MedicalInfo,
  InfoCard,
} from "../../atoms/Forms"
import { secondsToDate, prepareMessage } from "../../../utils/helpers"

import {
  ImagesMedicalHeader,
  ImagesEditableSection,
  ImagesStudy,
  ImagesTitle,
  Option,
  ModalOptions,
  ModalWrapper,
  AbsoluteSendButton,
} from "./styles"

import { Avatar, Thumb, Image, Images } from "../Prescription/styles"

const getFormValues = () => {
  const values = {}
  Object.keys(imagesData).forEach((key) => {
    imagesData[key].options.forEach((value, indexOption) => {
      values[`${key}.option-${indexOption}`] = false
      if (value.type === "other") {
        values[`${key}.option-${indexOption}-other`] = ""
      }
    })
  })
  return values
}
const MedicalImages = ({ firebase }) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const { dispatch, state: chatState } = useContext(ChatContext)
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formValues, setFormValues] = useState(getFormValues())
  const [uuid, setUUID] = useState("")
  const [target, setTarget] = useState({})
  const [dependentData, setDependentData] = useState(null)
  const [selectedSection, setSelectedSection] = useState({
    key: Object.keys(imagesData)[0],
    index: 0,
  })
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isLoadedImage, setIsLoadedImage] = useState({
    sign: false,
    company: false,
  })
  const [company, setCompany] = useState({})
  const [academic, setAcademic] = useState({})
  const [progress, setProgress] = useState(0)
  const nanoid = customAlphabet("1234567890ABCDEF", 8)
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    const getBusinessForm = async () => {
      const Business = await firebase.getBusiness({ email: state.user.email })
      setCompany(Business.data())
      const Academic = await firebase.getAcademic({ email: state.user.email })
      setAcademic(Academic.data())
      const Target = await firebase.getProfile({
        email: state.info.clientEmail,
      })
      setTarget(Target.data())
      setUUID(nanoid())
      if (state?.dependent) {
        setDependentData(JSON.parse(state?.dependent?.form))
      }
    }
    if (firebase) {
      getBusinessForm()
    }
  }, [firebase])

  const generatePDF = async (prepMsg) => {
    try {
      const authToken = await firebase.getIdToken()
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat: {
            id: state.session,
            data: prepMsg,
          },
          type: prepMsg.type,
          dependent: dependentData,
          isFromQuestions: state?.isFromQuestions,
          questionData: state?.question,
        }),
      }
      if (state?.isFromQuestions) {
        const response = await fetch(getPdfUrl, options)
        const jsonData = await response.json()
        if (jsonData) {
          history.push("/questions", {
            question: state.question,
            medicalImages: jsonData,
          })
        } else {
          addToast(t("unexpected_error"), { appearance: "error" })
        }
      } else {
        fetch(getPdfUrl, options)
      }
      setProgress(100)
      setIsButtonLoading(false)
    } catch (error) {
      setProgress(100)
      setIsButtonLoading(false)
      // addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const checkButonDisabled = () => {
    let isDisabled = true
    Object.keys(imagesData).forEach((key) => {
      if (isDisabled) {
        Object.entries(formValues)
          .filter(([formKey, value]) => formKey.includes(key) && value)
          .forEach(() => {
            isDisabled = false
          })
      }
    })
    return isDisabled
  }

  const handleClose = () => {
    const hasValues = checkButonDisabled()
    if (!hasValues) {
      setIsModalOpen(true)
    } else if (state?.isFromQuestions) {
      history.push("/questions", {
        question: state.question,
      })
    } else {
      history.push("/sessions", { session: state.session })
    }
  }

  const handleCloseConfirmation = () => {
    if (state?.isFromQuestions) {
      history.push("/questions", {
        question: state.question,
      })
    } else {
      history.push("/sessions", { session: state.session })
    }
  }

  const handleClick = async () => {
    const data = {}
    try {
      // Create structure for message
      Object.keys(imagesData).forEach((key) => {
        data[key] = {
          title: imagesData[key].title,
          options: [],
        }
        Object.entries(formValues)
          .filter(
            ([formKey, value]) =>
              formKey.includes(key) && !formKey.includes("other") && value
          )
          .forEach((value) => {
            const keyToFind = value[0].split("option-")
            const customText =
              imagesData[key].options[keyToFind[1]].type === "other"
                ? `${imagesData[key].options[keyToFind[1]].title}, ${
                    formValues[`${value[0]}-other`]
                  }`
                : imagesData[key].options[keyToFind[1]].title
            data[key].options.push({ key: value[0], text: customText })
          })
      })
      setIsButtonLoading(true)
      const infoMsg = {
        _id: uuidv4(),
        form: JSON.stringify({
          id: uuidv4(),
          code: uuid,
          data,
        }),
        type: "loaderMedicalImages",
        sentDate: moment().format("DD/MM/YY hh:mm a"),
      }
      const prepMsg = prepareMessage(
        user,
        null,
        infoMsg,
        infoMsg.type || "text",
        infoMsg.targetTokens || []
      )

      if (state?.isFromQuestions) {
        await generatePDF(prepMsg)
      } else {
        dispatch(addMessage(prepMsg, state.session))

        setTimeout(async () => {
          await generatePDF(prepMsg)
          history.push("/sessions", {
            session: state.session,
          })
        }, 300)
      }
    } catch {
      setIsButtonLoading(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [`${selectedSection.key}.${e.target.name}`]: !currentValues[
        `${selectedSection.key}.${e.target.name}`
      ],
      valuesIncluded: true,
    }))
  }

  const handleInputOtherChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [`${selectedSection.key}.${e.target.name}`]: e.target.value,
      valuesIncluded: true,
    }))
  }

  const getBirthdate = () => {
    let age = "0"
    const birthDate =
      dependentData && Object.keys(dependentData).length > 0
        ? secondsToDate(get(dependentData, "birthDate", null), "seconds")
        : secondsToDate(get(target, "birthDate", null), "seconds")
    if (birthDate) {
      age = currentYear - birthDate.getFullYear()
    }
    return age
  }

  return (
    <SectionsDoubleContainer>
      {isButtonLoading && (
        <ProgressBar
          spinner={false}
          percent={progress}
          autoIncrement
          intervalTime={417}
        />
      )}
      <Toolbar>
        <SectionTitle withPadding>
          {t("web_client.medical_images")}
        </SectionTitle>
        <EditableWrapper>
          {Object.keys(imagesData).map((key) => (
            <ImagesEditableSection key={key}>
              <p>{imagesData[key].title}</p>
              <div>
                {Object.entries(formValues)
                  .filter(
                    ([formKey, value]) =>
                      formKey.includes(key) &&
                      !formKey.includes("other") &&
                      value
                  )
                  .map((value) => {
                    const keyToFind = value[0].split("option-")

                    return (
                      <span key={keyToFind[1]}>
                        - {imagesData[key].options[keyToFind[1]].title}
                        {imagesData[key].options[keyToFind[1]].type ===
                          "other" && `, ${formValues[`${value[0]}-other`]}`}
                      </span>
                    )
                  })}
              </div>
            </ImagesEditableSection>
          ))}
        </EditableWrapper>
      </Toolbar>
      <Container>
        <ImagesMedicalHeader>
          <CloseButton onClick={handleClose}>
            <Close size="36" />
          </CloseButton>
          <span>{`# ${t("web_client.medical_images")}: ${uuid}`}</span>
          <HeaderTools>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              small
              disabled={checkButonDisabled()}
            >
              {t("send")}
            </ButtonWithLoader>
          </HeaderTools>
        </ImagesMedicalHeader>
        <MedicalInfo>
          <p>{get(company, "companyName", "")}</p>
          <div>
            <InfoCard>
              <Name>
                {`${get(academic, "title", "")} ${get(state, [
                  "info",
                  "providerName",
                ])}`}
              </Name>
              <span>
                {`${t("q2_academic_profession_label")}: ${get(
                  academic,
                  "professionLangLabel",
                  "----"
                )}`}
              </span>
              <span>
                {`${t("q4_academic_license_label")}: ${get(
                  academic,
                  "license",
                  "----"
                )}`}
              </span>
            </InfoCard>
            <InfoCard>
              <Name>
                {dependentData && Object.keys(dependentData).length > 0
                  ? get(dependentData, "dependentName", null)
                  : get(state, ["info", "clientName"], "")}
              </Name>
              <span>{t("target_age", { age: getBirthdate() })}</span>
              <span>
                {`${t("q4_info_gender_label")}: ${
                  dependentData && Object.keys(dependentData).length > 0
                    ? get(dependentData, "dependentGender", "")
                    : get(target, "gender", "")
                }`}
              </span>
            </InfoCard>
            <InfoCard>
              <Images>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>Logo</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={get(company, "companyName", "")}
                    src={get(company, "companyLogo", "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>{t("web_client.medical_stamp_label")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={get(company, "companyName", "")}
                    src={get(company, "companyStamp", "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.sign}>
                  <Thumb isLoaded={isLoadedImage.sign}>
                    <span>{t("web_client.signature")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        sign: true,
                      }))
                    }}
                    alt={get(user, ["medicProfile", "displayName"], "")}
                    src={get(user, ["medicProfile", "personalSign"], "")}
                    isLoaded={isLoadedImage.sign}
                    isSign
                  />
                </Avatar>
              </Images>
            </InfoCard>
          </div>
        </MedicalInfo>
        <ImagesStudy>
          <section>
            {Object.values(imagesData).map((study, index) => (
              <ImagesTitle
                key={`study-${index}`}
                onClick={() =>
                  setSelectedSection({
                    key: Object.keys(imagesData)[index],
                    index,
                  })
                }
                isActive={selectedSection.index === index}
              >
                <span>{study.title}</span>
              </ImagesTitle>
            ))}
          </section>
          <section>
            {Object.values(imagesData)[selectedSection.index].options.map(
              (option, index) => (
                <Option key={`option-${index}`}>
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={`option-${index}`}
                    checked={
                      formValues[`${selectedSection.key}.option-${index}`]
                    }
                    onChange={handleInputChange}
                  />
                  <label htmlFor={`option-${index}`}>{option.title}</label>
                  {option.type === "other" &&
                    formValues[`${selectedSection.key}.option-${index}`] && (
                      <Input
                        value={
                          formValues[
                            `${selectedSection.key}.option-${index}-other`
                          ]
                        }
                        onChange={handleInputOtherChange}
                        labelName="Detalle"
                        type="text"
                        name={`option-${index}-other`}
                        noMargin
                      />
                    )}
                </Option>
              )
            )}
          </section>
          <AbsoluteSendButton>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              small
              disabled={checkButonDisabled()}
            >
              {t("send")}
            </ButtonWithLoader>
          </AbsoluteSendButton>
        </ImagesStudy>
      </Container>
      <CustomModal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>{t("web_client.unsaved_data")}</p>
              <div>
                <button type="button" onClick={() => setIsModalOpen(false)}>
                  {t("cancel")}
                </button>
                <button type="button" onClick={handleCloseConfirmation}>
                  {t("confirm")}
                </button>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
    </SectionsDoubleContainer>
  )
}

export default withFirebase(MedicalImages)
