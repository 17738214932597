import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { useHistory, withRouter } from "react-router-dom"
import {
  VStack,
  Box,
  Image,
  Flex,
  Text,
  Button,
  useMediaQuery,
} from "@chakra-ui/react"
import get from "lodash.get"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"
import EmailIcon from "./imgs/email-icon.svg"

import { WithChakra } from "../../components/atoms/WithChakra"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"

// Utils
import { AuthUserContext } from "../../context/Session"
import * as ROUTES from "../../utils/Constants/Routes"
import { withFirebase } from "../../utils/Firebase"

const DataVerificationBase = ({ firebase }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const [isMobile] = useMediaQuery("(max-width: 800px)")

  const goToLogin = () => {
    history.push("/login")
    firebase.logout()
  }

  const goToHelp = () => {
    history.push("/help")
  }

  const goToPlatform = () => {
    const getUserInfo = () => {
      try {
        const profile = get(user, "medicProfile")
        if (profile.userType === "provider") {
          history.push(ROUTES.HOME)
        } else if (profile.userType === "medicalAssistant") {
          history.push(ROUTES.DASHBOARD)
        } else {
          firebase.logout()
          goToLogin()
        }
      } catch (e) {
        firebase.logout()
        goToLogin()
      }
    }

    if (user) {
      getUserInfo()
    } else {
      goToLogin()
    }
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justify="center"
      width="full"
      height="100vh"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
    >
      <Box
        boxSize="sm"
        position="absolute"
        right="20px"
        top="12px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="200px"
        height="50px"
      >
        <HelpButton onClick={() => goToHelp()} />
        <LanguageSelector isFromLogin />
      </Box>
      <Box
        boxSize="sm"
        position="absolute"
        left="22px"
        top="12px"
        cursor="pointer"
      >
        <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
      </Box>
      <VStack
        borderRadius="8px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box height="200px" width="200px">
          <Image src={EmailIcon} alt="emailIcon" />
        </Box>
        <Text
          fontWeight="400"
          fontSize={isMobile ? "24px" : "36px"}
          fontFamily="Circular Std Medium"
          lineHeight="56px"
          textAlign="center"
        >
          {t("web_client.verify_in_progress")}
        </Text>
        <Text
          fontWeight="200"
          fontSize={isMobile ? "16px" : "18px"}
          fontFamily="Circular Std Medium"
          lineHeight="27px"
          width={isMobile ? "90%" : "615px"}
          marginBottom="30px !important"
          textAlign="center"
        >
          {t("web_client.verify_description")}
        </Text>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={isMobile ? "center" : "space-between"}
          alignItems="center"
          width="320px"
        >
          <Button
            colorScheme="blue"
            width="104px"
            height="40px"
            color="#000"
            fontSize="16px"
            fontWeight="bold"
            variant="ghost"
            onClick={goToLogin}
          >
            {t("close")}
          </Button>
          {!isMobile && (
            <Button
              colorScheme="blue"
              background="#576EF5"
              width="200px"
              height="40px"
              borderRadius="6px"
              color="#FFF"
              fontSize="16px"
              fontWeight="400"
              onClick={goToPlatform}
            >
              {t("web_client.use_platform")}
            </Button>
          )}
        </Box>
      </VStack>
    </Flex>
  )
}

const DataVerification = compose(
  withRouter,
  WithChakra,
  withFirebase
)(DataVerificationBase)

export default DataVerification
