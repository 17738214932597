/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { compose } from "recompose"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import forEach from "lodash.foreach"
import sortBy from "lodash.sortby"
import has from "lodash.has"
import merge from "lodash.merge"
import get from "lodash.get"

import SectionTitle from "../../atoms/SectionTitle"
import Input from "../../atoms/Input"
import BackIcon from "../../atoms/BackIcon"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import Card from "../../atoms/Card"
import Chat from "../Chat"
import constants from "../../../utils/constants"
import { withFirebase } from "../../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import ChatContext from "../../../context/Chat"
import { setFirstMessageKey } from "../../../context/Chat/reducer"
import {
  Container,
  MessageContainer,
  ChatWrapper,
  Search,
  BackContainer,
} from "./styles"

const History = ({ firebase, current }) => {
  const user = useContext(AuthUserContext)
  const { dispatch } = useContext(ChatContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation()
  const [customLimit, setCustomLimit] = useState(constants.chats.MESSAGE_LIMIT)
  const [chats, setChats] = useState([])
  const [messages, setMessages] = useState([])
  const [messageKeys, setMessageKeys] = useState({})
  const [currentSession, setCurrentSession] = useState(null)
  const [loadingMessages, setloadingMessages] = useState(true)
  const [loadingEarlier, setLoadingEarlier] = useState(false)
  const [isFromContacts, setIsFromContacts] = useState(false)
  const [formValues, setFormValues] = useState({ search: "" })
  const userType = get(user, ["medicProfile", "userType"])

  const activeChats = Object.values(chats).filter(
    (chat) =>
      chat.status !== "active" &&
      chat.status !== "scheduled" &&
      chat.clientName
        .toLowerCase()
        .includes(
          formValues.search !== ""
            ? formValues.search.toLowerCase()
            : chat.clientName.toLowerCase()
        )
  )
  activeChats.sort((a, b) => b.modifiedAt - a.modifiedAt)

  const onCardClick = (card, type) => {
    const currentId = type === "agenda" ? card.chatId : card.id
    if (currentId !== currentSession) {
      setCustomLimit(constants.chats.MESSAGE_LIMIT)
      setCurrentSession(currentId)
      setloadingMessages(true)
    }
  }

  const handleLoadMore = () => {
    setLoadingEarlier(true)
    if (customLimit > 0) {
      setCustomLimit(customLimit + 10)
    } else {
      setCustomLimit(constants.chats.MESSAGE_LIMIT + 10)
    }
  }

  useEffect(() => {
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      firebase.getChats({
        doctor: user.isImpersonating ? user.impersonatingUID : user.uid,
        onceValue: (snapshot) => {
          // eslint-disable-next-line no-shadow
          let chats = {}
          forEach(snapshot.val(), (chat, key) => {
            chats = {
              ...chats,
              [key]: {
                id: key,
                ...chat,
              },
            }
          })
          setChats(chats)
        },
      })
    }
  }, [firebase, user])

  useEffect(() => {
    if (
      (current || get(state, "selectedSession")) &&
      Object.keys(chats).length > 0
    ) {
      setIsFromContacts(true)
      setCurrentSession(current || get(state, "selectedSession"))
    }
  }, [chats, get(state, "selectedSession")])

  useEffect(() => {
    if (firebase) {
      firebase.getMessages({
        chat: currentSession,
        messagesLimit:
          customLimit > 0 ? customLimit : constants.chats.MESSAGE_LIMIT,
        onceValue: (snapshot) => {
          const msgTotal = snapshot.numChildren()
          let localMessageKeys = {}
          const sortedMessages = sortBy(snapshot.val(), ["createdAt"])
          console.log("sortedMessages ==>", sortedMessages)
          if (loadingEarlier) {
            console.log(
              "loadingEarlier ==>",
              loadingEarlier,
              sortedMessages?.[0]?._id
            )
            dispatch(setFirstMessageKey(sortedMessages?.[0]?._id))
            setLoadingEarlier(false)
          }
          if (msgTotal && msgTotal > 0) {
            forEach(sortedMessages, (msg) => {
              if (!has(localMessageKeys, msg._id)) {
                localMessageKeys = merge(localMessageKeys, { [msg._id]: msg })
              }
            })
            setMessages(sortedMessages)
            setMessageKeys(localMessageKeys)
            setloadingMessages(false)
          } else {
            setMessages([])
            setMessageKeys({})
            setloadingMessages(false)
          }
        },
      })
    }
  }, [currentSession, customLimit, firebase])

  useEffect(() => {
    const localSession = get(state, "selectedSession")
    if (localSession && isFromContacts) {
      const stateC = { ...history.location.state }
      delete stateC.selectedSession
      history.replace({ ...history.location, state: stateC })
    }
  }, [isFromContacts])

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
    setCurrentSession(null)
  }

  useEffect(() => {
    if (user && userType === "medicalAssistant" && !user.isImpersonating) {
      history.push("/dashboard")
    }
  }, [userType, get(user, "isImpersonating")])

  return (
    <SectionsDoubleContainer>
      <Toolbar>
        <SectionTitle withPadding>{t("web_client.menu_hist")}</SectionTitle>
        {isFromContacts && (
          <BackContainer>
            <BackIcon fill="#4d66f5" />{" "}
            <span
              onClick={() =>
                history.push({
                  pathname: "/contacts",
                  state: {
                    contact: get(state, "contact"),
                    isFromQuestions: state?.isFromQuestions,
                    question: state?.question,
                  },
                })
              }
            >
              {t("web_client.back_to_contacts")}
            </span>
          </BackContainer>
        )}
        <Search>
          <Input
            icon="search-person"
            value={formValues.title}
            onChange={handleInputChange}
            labelName={t("web_client.search")}
            type="text"
            name="search"
          />
        </Search>
        <MessageContainer>
          {Object.keys(activeChats).map((c, i) => (
            <Card
              key={`chat-${i}`}
              handleCardClick={onCardClick}
              session={activeChats[c]}
              isActive={
                !currentSession ? true : currentSession === activeChats[c].id
              }
              type="app"
              sessionType={activeChats[c].sessionType}
              readOnlyChat
            />
          ))}
        </MessageContainer>
      </Toolbar>
      <Container>
        <ChatWrapper isChat={currentSession}>
          {currentSession && (
            <Chat
              t={t}
              history={history}
              messages={messages}
              messageKeys={messageKeys}
              session={currentSession}
              firebase={firebase}
              user={user}
              info={chats[currentSession]}
              customLimit={customLimit}
              setLoadMore={handleLoadMore}
              loadingMessages={loadingMessages}
              isFromContacts={isFromContacts}
              readOnlyChat
            />
          )}
        </ChatWrapper>
      </Container>
    </SectionsDoubleContainer>
  )
}

History.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompHistory = compose(withFirebase, withAuthorization(condition))(History)
export default CompHistory
