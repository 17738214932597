import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { compose } from "recompose"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useClipboard } from "use-clipboard-copy"
import { useToasts } from "react-toast-notifications"
import get from "lodash.get"
import { Payment } from "@styled-icons/material/Payment"
import { ChatBubble } from "@styled-icons/material/ChatBubble"
import { Spinner } from "@chakra-ui/react"
import SectionTitle from "../../atoms/SectionTitle"
import { WithChakra } from "../../atoms/WithChakra"
import Input from "../../atoms/Input"
import { withFirebase } from "../../../utils/Firebase"
import { mailerUrl, reportDashboardUrl } from "../../../utils/App"
import constants from "../../../utils/constants"
import { checkMissingData } from "../../../utils/helpers"
import Theme from "../../../styles/theme"

import {
  Wrapper,
  EntriesSection,
  TitleIcon,
  Title,
  Data,
  Share,
  IncompleteProfileBanner,
  Tabs,
  Tab,
  Action,
} from "./styles"
import { ClockIcon } from "../../atoms/Common"

const Dashboard = ({ user, firebase }) => {
  const { t } = useTranslation()
  const clipboard = useClipboard()
  const { addToast } = useToasts()
  const [formValues, setFormValues] = useState({
    providerEmail: "",
    patientEmail: "",
  })
  const [dashboard, setDashboard] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [loadingDashboard, setLoadingDashboard] = useState(true)
  const [profileExtraData, setProfileExtraData] = useState({
    academic: null,
    business: null,
  })
  const [showCompleteProfileBanner, setShowCompleteProfileBanner] = useState(
    false
  )
  const [profile, setProfile] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [needsToRefresh, setNeedsToRefresh] = useState(false)
  const userType = get(user, ["medicProfile", "userType"])

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        setLoadingDashboard(true)
        const currentTimestamp = Date.now()
        const token = await firebase.getIdToken()
        const data = await fetch(reportDashboardUrl, {
          method: "POST",
          body: JSON.stringify({
            email: user.isImpersonating ? user.impersonatingEmail : user.email,
            targetDate: currentTimestamp,
            tzId: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })

        if (data.ok) {
          const dashboardData = await data.json()
          setDashboard(dashboardData)
        }
        setNeedsToRefresh(false)
        setLoadingDashboard(false)
      } catch (error) {
        setLoadingDashboard(false)
        setNeedsToRefresh(false)
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    const getUserProfile = get(user, "medicProfile")
    if (
      (firebase &&
        user.medicProfile &&
        !needsToRefresh &&
        (getUserProfile.userType === "provider" ||
          getUserProfile.userType === "medicalAssistant")) ||
      needsToRefresh
    ) {
      getDashboardData()
    } else {
      firebase.logout()
    }
  }, [firebase, needsToRefresh])

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }

  async function sendInvitationEmail() {
    try {
      const token = await firebase.getIdToken()
      const getEmail = formValues.providerEmail
        ? formValues.providerEmail
        : formValues.patientEmail
      const getCode = formValues.providerEmail ? 2526190 : 2981842

      await fetch(mailerUrl, {
        method: "POST",
        body: JSON.stringify({
          data: {
            templateId: getCode, // use reference code
            email: getEmail,
            variables: {
              subject: "Te han invitado a utilizar la plataforma DOC",
              providerName: `${get(user, ["medicProfile", "title"], "")} ${get(
                user,
                ["medicProfile", "displayName"],
                ""
              )}`,
              referenceCode: get(user, ["medicProfile", "referenceCode"], ""),
              websiteLink: `${constants.cronofy.redirectURL}/login`,
            },
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      setFormValues({ providerEmail: "", patientEmail: "" })
      addToast(t("web_client.invitation_sent"), {
        appearance: "success",
      })
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const getUserData = async () => {
    const data = {
      academic: null,
      business: null,
    }
    await firebase
      .getBusiness({
        email: user.isImpersonating ? user.impersonatingEmail : user.email,
      })
      .then((businessData) => {
        data.business = businessData.data()
      })
    await firebase
      .getAcademic({
        email: user.isImpersonating ? user.impersonatingEmail : user.email,
      })
      .then((academicData) => {
        data.academic = academicData.data()
      })
    setProfileExtraData(data)
    setLoadingData(false)
  }

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const local = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return local.data()
      }
      const Profile = await getProfile()
      setProfile(Profile)
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    if (profile) {
      getUserData()
    }
  }, [profile])

  useEffect(() => {
    if (profile && !loadingData) {
      const allUserData = { ...profile, ...profileExtraData }
      const getMissingData = checkMissingData(allUserData)
      if (
        getMissingData.propertiesMissing.length ||
        getMissingData.sectionsMissing.length
      ) {
        setShowCompleteProfileBanner(true)
      } else {
        setShowCompleteProfileBanner(false)
      }
    }
  }, [profileExtraData, loadingData, user, profile])

  return (
    <Wrapper>
      {showCompleteProfileBanner && (
        <IncompleteProfileBanner>
          <Link to="/account">{t("web_client.missing_profile_data")}</Link>
        </IncompleteProfileBanner>
      )}
      <SectionTitle size="larger">
        <>
          ¡
          {t("greeting", {
            name: get(user, ["medicProfile", "displayName"], "").replace(
              "undefined",
              ""
            ),
          })}
          {user?.medicProfile?.userType !== "provider" &&
            ` / ${profileExtraData?.academic?.title} ${profile?.name} ${profile?.surname1}`}
          !
        </>
      </SectionTitle>
      <div>
        <Action onClick={() => setNeedsToRefresh(true)}>
          {loadingDashboard ? (
            <Spinner size="md" marginRight={4} />
          ) : (
            <ClockIcon />
          )}
          {t("refresh_dashboard")}
        </Action>
      </div>
      <EntriesSection>
        <Title>
          <TitleIcon>
            <Payment size="24" />
          </TitleIcon>
          <span>{t("web_client.incomes")}</span>
        </Title>
        <Data>
          <section>
            <span>
              {dashboard?.summaryDay && dashboard?.summaryDay?.total
                ? `${dashboard.summaryDay.total.toFixed(2)}`
                : "0"}
            </span>
            <span>{t("today")}</span>
          </section>
          <section>
            <span>
              {dashboard?.summaryWeek && dashboard?.summaryWeek.total
                ? `${dashboard.summaryWeek.total.toFixed(2)}`
                : "0"}
            </span>
            <span>{t("web_client.week")}</span>
          </section>
          <section>
            <span>
              {dashboard.summaryMonth && dashboard.summaryMonth.total
                ? `${dashboard.summaryMonth.total.toFixed(2)}`
                : "0"}
            </span>
            <span>{t("web_client.month")}</span>
          </section>
        </Data>
      </EntriesSection>
      <EntriesSection isWhite>
        <Title isWhite>
          <TitleIcon isWhite>
            <ChatBubble size="24" />
          </TitleIcon>
          <span>{t("web_client.finished_sessions")}</span>
        </Title>
        <Data isWhite>
          <section>
            <span>{get(dashboard, ["summaryDay", "count"], 0)}</span>
            <span>{t("today")}</span>
          </section>
          <section>
            <span>{get(dashboard, ["summaryWeek", "count"], 0)}</span>
            <span>{t("web_client.week")}</span>
          </section>
          <section>
            <span>{get(dashboard, ["summaryMonth", "count"], 0)}</span>
            <span>{t("web_client.month")}</span>
          </section>
        </Data>
      </EntriesSection>
      <EntriesSection isWhite>
        <Title isWhite>
          <TitleIcon isWhite>
            <ChatBubble size="24" />
          </TitleIcon>
          <span>{t("web_client.donated_sessions")}</span>
        </Title>
        <Data isWhite>
          <section>
            <span>{get(dashboard, ["summaryDay", "countDonated"], 0)}</span>
            <span>{t("today")}</span>
          </section>
          <section>
            <span>{get(dashboard, ["summaryWeek", "countDonated"], 0)}</span>
            <span>{t("web_client.week")}</span>
          </section>
          <section>
            <span>{get(dashboard, ["summaryMonth", "countDonated"], 0)}</span>
            <span>{t("web_client.month")}</span>
          </section>
        </Data>
      </EntriesSection>
      <EntriesSection
        backgroundImage="/images/others/toy-faces.png"
        isWhite
        isFooter
      >
        <Data
          isWhite
          isFooter
          style={{ justifyContent: "space-between", padding: 40 }}
        >
          <div>
            <Tabs>
              <Tab onClick={() => setActiveTab(0)} isActive={activeTab === 0}>
                <span style={{ marginLeft: 0 }}>{t("web_client.refer")}</span>
              </Tab>
              <Tab onClick={() => setActiveTab(1)} isActive={activeTab === 1}>
                <span style={{ marginLeft: 0 }}>
                  {t("web_client.refer_patient")}
                </span>
              </Tab>
            </Tabs>
            {activeTab === 0 && (
              <section>
                <Share>
                  <Input
                    style={{
                      width: "100%",
                    }}
                    icon="email"
                    value={formValues.providerEmail}
                    onChange={handleInputChange}
                    labelName={`${t("email_address")} - ${t(
                      "web_client.colleague"
                    )} `}
                    type="text"
                    name="providerEmail"
                    readonly={formValues.patientEmail}
                    disabled={!!formValues.patientEmail}
                    fullWidth
                    noMargin
                  />
                </Share>
              </section>
            )}
            {activeTab === 1 && (
              <section>
                <Share>
                  <Input
                    style={{
                      width: "100%",
                    }}
                    icon="email"
                    value={formValues.patientEmail}
                    onChange={handleInputChange}
                    labelName={`${t("email_address")} - ${t(
                      "web_client.patient"
                    )}`}
                    type="text"
                    name="patientEmail"
                    readonly={formValues.providerEmail}
                    disabled={!!formValues.providerEmail}
                    fullWidth
                    noMargin
                  />
                </Share>
              </section>
            )}
          </div>
          <button
            style={{
              paddingRight: 5,
              marginTop: 25,
              backgroundColor: Theme.color.secondary,
              color: Theme.color.white.regular,
              borderRadius: 100,
              width: 100,
              height: 50,
            }}
            type="button"
            onClick={sendInvitationEmail}
          >
            {t("send")}
          </button>
          <section>
            <Title isWhite>
              <span>{t("share_reference_code")}</span>
            </Title>
            <Share>
              <input
                style={{
                  border: 0,
                  color: " gray",
                  width: "70%",
                  outline: "none",
                  marginLeft: 5,
                }}
                ref={clipboard.target}
                value={get(user, ["medicProfile", "referenceCode"], "")}
                readOnly
              />
              <button
                style={{ paddingRight: 5 }}
                type="button"
                onClick={() => {
                  clipboard.copy()
                  addToast(t("web_client.copied"), { appearance: "success" })
                }}
              >
                {t("web_client.copy")}
              </button>
            </Share>
          </section>
        </Data>
      </EntriesSection>
    </Wrapper>
  )
}

Dashboard.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    medicProfile: PropTypes.shape({
      displayName: PropTypes.string,
      referenceCode: PropTypes.string,
    }),
  }).isRequired,
  firebase: PropTypes.shape().isRequired,
}

const ComposedDashboard = compose(withFirebase, WithChakra)(Dashboard)

export default ComposedDashboard
