import React from "react"
import isEmpty from "lodash.isempty"
import get from "lodash.get"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { getLocalTimeZone, getTimeZoneList } from "../../../utils/helpers"
import { appointmentsGetUrl, appointmentsUpdateUrl } from "../../../utils/App"
import Modal from "../../atoms/CustomModal"
import TimezoneSelect from "../../atoms/TimezoneSelect"
import Button from "../../atoms/ButtonWithLoader"

const TimezoneWrapper = styled.div`
  width: 50%;
`

const ModalWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  outline: none;
  background: white;
`

const ButtonContainer = styled.div`
  margin-top: 30px;
`

const TimeZoneModal = ({ user, firebase, isOpen, onFinish }) => {
  const { t } = useTranslation()
  const [zone, setZone] = React.useState("")
  const [isSavingTimezone, setSavingTimezone] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState({})

  const timezones = React.useMemo(() => getTimeZoneList(), [])
  const localTimeZone = React.useMemo(() => getLocalTimeZone(), [])

  const getDefaultTimeZone = React.useCallback(() => {
    const userTimezone = get(currentUser, ["timezone"])
    if (userTimezone) {
      return {
        value: userTimezone,
        label: userTimezone,
      }
    }
    const defaultTimezone = timezones.find(
      (timeZone) =>
        localTimeZone.name() === timeZone.name ||
        timeZone.group.includes(localTimeZone.name())
    )
    return {
      value: defaultTimezone.name,
      label: defaultTimezone.name,
    }
  }, [localTimeZone, currentUser])

  const getNextUser = async () => {
    const nextUser = await firebase.getProfile({ email: user.email })
    setCurrentUser(nextUser.data())
  }

  const updateAvailabilityTimezone = async () => {
    try {
      const authToken = await firebase.getIdToken()
      const reponse = await fetch(appointmentsGetUrl, {
        method: "POST",
        body: JSON.stringify({
          user: user.email,
        }),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })

      const data = await reponse.json()

      const medicalHours = data.availability_rules.find(
        (rule) => rule.availability_rule_id === "medical_work_hours"
      )

      const medicalVisitorsHours = data.availability_rules.find(
        (rule) => rule.availability_rule_id === "medical_visitors_hours"
      )

      const promiseUpdates = []

      if (medicalHours) {
        promiseUpdates.push(
          fetch(appointmentsUpdateUrl, {
            method: "POST",
            body: JSON.stringify({
              user: user.email,
              availability_rule_id: "medical_work_hours",
              tzid: zone,
              weekly_periods: get(medicalHours, "weekly_periods"),
            }),
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          })
        )
      }

      if (medicalVisitorsHours) {
        promiseUpdates.push(
          fetch(appointmentsUpdateUrl, {
            method: "POST",
            body: JSON.stringify({
              user: user.email,
              availability_rule_id: "medical_visitors_hours",
              tzid: zone,
              weekly_periods: get(medicalVisitorsHours, "weekly_periods"),
            }),
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          })
        )
      }

      if (promiseUpdates.length > 0) {
        Promise.all(promiseUpdates)
      }
    } catch (error) {
      window.Honeybadger?.notify(error)
      // eslint-disable-next-line no-console
      // TODO: (marvin) Implement error handling
      // console.error(error)
    }
  }

  const onSaveTimezone = async () => {
    try {
      if (isEmpty(zone)) throw new Error("zone is required")
      setSavingTimezone(true)
      await firebase.savePersonalData({
        email: user.email,
        data: {
          timezone: zone,
        },
      })
      await getNextUser()
      await updateAvailabilityTimezone()
      user.setUser({
        ...user,
        timezone: zone,
      })
      setSavingTimezone(false)
      if (onFinish) onFinish(zone)
      return true
    } catch (error) {
      return error
    }
  }

  const noTimezone =
    isOpen ||
    (!isEmpty(currentUser) &&
      (isEmpty(currentUser.timezone) || !currentUser.timezone))

  React.useEffect(() => {
    if (firebase) {
      getNextUser()
    }
  }, [])

  return (
    <Modal modalOpen={noTimezone} showClose={false}>
      <ModalWrapper>
        <TimezoneWrapper>
          <h2>{t("timezone_title")}</h2>
          <TimezoneSelect
            timezones={timezones}
            zone={zone}
            defaultZone={getDefaultTimeZone()}
            onChange={setZone}
          />
        </TimezoneWrapper>
        <ButtonContainer>
          <Button isLoading={isSavingTimezone} onClick={onSaveTimezone}>
            {t("timezone_button_label")}
          </Button>
        </ButtonContainer>
      </ModalWrapper>
    </Modal>
  )
}

TimeZoneModal.defaultProps = {
  isOpen: false,
  onFinish: undefined,
}

TimeZoneModal.propTypes = {
  isOpen: PropTypes.bool,
  onFinish: PropTypes.func,
  firebase: PropTypes.shape({
    getProfile: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    timezone: PropTypes.string,
  }).isRequired,
}

export default TimeZoneModal
