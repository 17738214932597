import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import Video from "twilio-video"
import { Mic } from "@styled-icons/material/Mic"
import { MicOff } from "@styled-icons/material/MicOff"
import { Phone } from "@styled-icons/material/Phone"
import { InitialAvatar, Name } from "../../atoms/Card/styles"
import Participant from "../Participant"
import {
  RoomWrapper,
  ParticipantsWrapper,
  MicWrapper,
  FinishWrapper,
  Tools,
  Header,
  LocalWrapper,
} from "./styles"

const Room = ({ roomName, token, handleLogout, info, onNewSession, user }) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const [room, setRoom] = useState(null)
  const [participants, setParticipants] = useState([])
  const [voiceStatus, setVoiceStatus] = useState("Mute")

  const handleFinish = () => {
    if (room) {
      room.disconnect()
    }
    handleLogout()
  }

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant])
    }

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      )
    }

    Video.connect(token, {
      name: roomName,
    })
      .then(
        (r) => {
          setRoom(r)
          r.on("reconnecting", (error) => {
            window.Honeybadger?.notify(error)
          })
          r.on("participantConnected", participantConnected)
          r.on("participantDisconnected", participantDisconnected)
          r.once("disconnected", (rm, error) => {
            if (error) {
              window.Honeybadger?.notify(error)
            }
          })
          r.participants.forEach(participantConnected)
        },
        (error) => {
          window.Honeybadger?.notify(error)
        }
      )
      .catch((error) => {
        // https://www.twilio.com/docs/api/errors
        // 20104 Access Token expired or expiration date invalid
        window.Honeybadger?.notify(error)
        addToast(t("unexpected_error"), { appearance: "error" })
        if (error.code === 20104) {
          handleFinish()
        }
      })

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        }
        return currentRoom
      })
    }
  }, [roomName, token])

  useEffect(() => {
    if (room && participants.length === 0) {
      onNewSession(user.displayName)
    }
  }, [room])

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ))

  const toggleVoice = () => {
    if (voiceStatus === "Mute") {
      room.localParticipant.audioTracks.forEach((audioTrack) => {
        audioTrack.track.disable()
      })
      setVoiceStatus("Unmute")
    } else {
      room.localParticipant.audioTracks.forEach((audioTrack) => {
        audioTrack.track.enable()
      })
      setVoiceStatus("Mute")
    }
  }

  return (
    <RoomWrapper>
      <LocalWrapper>
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
          />
        ) : (
          ""
        )}
      </LocalWrapper>
      <Header>
        <InitialAvatar>
          <span>{info.clientName[0]}</span>
        </InitialAvatar>
        <Name>{info.clientName}</Name>
      </Header>
      <ParticipantsWrapper>{remoteParticipants}</ParticipantsWrapper>
      <Tools>
        <FinishWrapper type="button" onClick={handleFinish}>
          <Phone size="40" />
        </FinishWrapper>
        {room && (
          <MicWrapper type="button" onClick={toggleVoice}>
            {voiceStatus === "Mute" ? <Mic size="40" /> : <MicOff size="40" />}
          </MicWrapper>
        )}
      </Tools>
    </RoomWrapper>
  )
}

export default Room
