/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext, useRef } from "react"
import * as Yup from "yup"
import get from "lodash.get"
import PropTypes from "prop-types"
import forEach from "lodash.foreach"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { useHistory, useLocation } from "react-router-dom"
import Select from "react-select"
import { compose } from "recompose"
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  VStack,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react"
import {
  mailerUrl,
  newUserUrl,
  getProviderPricingUrl,
  generatePassword,
  deleteUserUrl,
} from "../../../utils/App"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"
import constants from "../../../utils/constants"
import { capitalizeAllWords } from "../../../utils/helpers"
import ContactRow from "../../molecules/ContactRow"
import ContactInfo from "../../molecules/ContactInfo"
import Input from "../../atoms/Input"
import BackIcon from "../../atoms/BackIcon"
import { WithChakra } from "../../atoms/WithChakra"
import countries from "../../chakra/CountriesSelector/data/countries.json"
import AddAppointment from "./AddAppointment"

import CustomInput from "../../chakra/CustomInput"
import { BackContainer } from "../History/styles"
import {
  Container,
  SectionTitle,
  ContactsContainer,
  Title,
  LeftTitleSection,
  RightTitleSection,
  LeftContainerSection,
  RightContainerSection,
  EmptyMessage,
  AddButton,
} from "./styles"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const mappedCountries = countries.map((country) => ({
  value: country.iso2,
  label: country.name,
}))

const ManageContacts = ({ firebase, paramContact }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const history = useHistory()
  const { state } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const [userTypeView, setUserTypeView] = useState("all")
  const [search, setSearch] = useState({})
  const [contacts, setContacts] = useState([])
  const [currentContact, setCurrentContact] = useState(paramContact)
  const [chats, setChats] = useState([])
  const [filteredChats, setFilteredChats] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [providerPricing, setProviderPricing] = useState(null)
  const [isAddingContact, setIsAddingContact] = useState(false)
  const userType = get(user, ["medicProfile", "userType"])
  const formRef = useRef()

  const userTypeOptions = [
    {
      value: "all",
      label: t("all"),
    },
    {
      value: "client",
      label: t("type_patient"),
    },
    {
      value: "medical_visitor",
      label: t("type_visitor"),
    },
  ]

  const handleAddContact = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit()
      }
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  useEffect(() => {
    const getContacts = async () => {
      try {
        const currentContacts = []
        const docs = await firebase.getContacts(
          user.isImpersonating ? user.impersonatingEmail : user.email
        )
        docs.forEach((doc) => {
          const data = doc.data()
          currentContacts.push({ ...data, id: doc.id })
        })
        setContacts(currentContacts)
      } catch {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    if (firebase) {
      getContacts()
    }
  }, [firebase])

  useEffect(() => {
    const getPricing = async () => {
      try {
        const authToken = await firebase.getIdToken()
        if (authToken) {
          const params = {
            email: user.isImpersonating ? user.impersonatingEmail : user.email,
          }
          const url = new URL(getProviderPricingUrl)
          Object.keys(params).forEach((k) => {
            url.searchParams.append(k, params[k])
          })

          const providerPricingData = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          })

          if (providerPricingData.ok) {
            const pricing = await providerPricingData.json()
            setProviderPricing(pricing)
          }
        }
      } catch {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    if (user) {
      getPricing()
    }
  }, [user])

  useEffect(() => {
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      firebase.getChats({
        doctor: user.isImpersonating ? user.impersonatingUID : user.uid,
        onceValue: (snapshot) => {
          // eslint-disable-next-line no-shadow
          let chats = {}
          forEach(snapshot.val(), (chat, key) => {
            chats = {
              ...chats,
              [key]: {
                id: key,
                ...chat,
              },
            }
          })
          setChats(chats)
        },
      })
    }
  }, [firebase, userType])

  useEffect(() => {
    if (currentContact) {
      if (chats) {
        const localChats = Object.values(chats).filter(
          (c) =>
            c.clientEmail === currentContact.email && c.status === "finished"
        )
        setFilteredChats(localChats)
      }
    }
  }, [get(currentContact, "email")])

  useEffect(() => {
    if (paramContact && contacts.length > 0) {
      const localContact = contacts.find((c) => c.email === paramContact.email)
      if (localContact) {
        setCurrentContact(localContact)
      }
      if (!localContact && formRef && paramContact) {
        onOpen()
      }
    }
  }, [paramContact, contacts])

  useEffect(() => {
    if (userTypeView !== "all") {
      setFilteredContacts(contacts.filter((c) => c.userType === userTypeView))
    } else {
      setFilteredContacts(contacts)
    }
  }, [contacts, userTypeView])

  useEffect(() => {
    if (
      user &&
      userType === "medicalAssistant" &&
      !get(user, "isImpersonating", false)
    ) {
      history.push("/dashboard")
    }
  }, [userType, get(user, "isImpersonating")])

  return (
    <Container>
      <SectionTitle>
        <LeftTitleSection>
          <Title size="large">
            {currentContact
              ? `${t("web_client.menu_contacts")} (${capitalizeAllWords(
                  currentContact.displayName
                )})`
              : t("web_client.menu_contacts")}
          </Title>
          <Title size="small" isSub>
            {`${filteredContacts.length.toString()} ${t(
              "web_client.menu_contacts"
            ).toLowerCase()}`}
          </Title>
          {(state?.session || state?.isFromQuestions) && (
            <BackContainer pl="0px">
              <BackIcon fill="#4d66f5" />{" "}
              <span
                onClick={() => {
                  if (state?.isFromQuestions) {
                    history.push("/questions", {
                      question: state?.question,
                    })
                  } else {
                    history.push("/sessions", { session: state?.session })
                  }
                }}
              >
                {state?.isFromQuestions
                  ? t("web_client.back_to_questions")
                  : t("web_client.back_to_room")}
              </span>
            </BackContainer>
          )}
        </LeftTitleSection>
        <RightTitleSection>
          <Select
            styles={customStyles}
            defaultValue={userTypeView}
            value={userTypeOptions.find(
              (option) => option.value === userTypeView // get(timeSettings, "medical_visitor_time")
            )}
            options={userTypeOptions}
            onChange={(option) => {
              setCurrentContact(null)
              setUserTypeView(option.value)
            }}
            width="230px"
          />
          <AddButton type="button" onClick={onOpen}>
            + {t("add_contact")}
          </AddButton>
        </RightTitleSection>
      </SectionTitle>
      <ContactsContainer>
        <LeftContainerSection>
          <Input
            icon="search"
            value={search.contact}
            disabled={state?.session}
            labelName={t("search_input")}
            onChange={(e) => {
              const { value } = e.target
              setSearch((prevState) => ({
                ...prevState,
                contact: value,
              }))
            }}
            type="text"
            name="search"
            id="search"
            fullWidth
            noMargin
            required
          />
          {filteredContacts
            .filter((ct) =>
              search.contact
                ? ct.displayName.toLowerCase().includes(search.contact) ||
                  ct.email.toLowerCase().includes(search.contact)
                : true
            )
            .sort((a, b) => b.isFavorite - a.isFavorite)
            .map((c) => (
              <ContactRow
                key={`c-${c.id}-${c.email}`}
                disabled={state?.session}
                active={currentContact && c.id === currentContact.id}
                handleClick={() =>
                  setCurrentContact(
                    currentContact && c.email === currentContact.email
                      ? null
                      : c
                  )
                }
                handleModalClick={() => onModalOpen}
                contact={c}
                isSimple={!providerPricing?.pricing?.faceToFace?.duration}
                translation={t}
              />
            ))}
        </LeftContainerSection>
        <RightContainerSection>
          {!currentContact ? (
            <EmptyMessage>
              <span>Seleccione un contacto para ver sus opciones</span>
              <AddButton type="button" onClick={onOpen}>
                + {t("add_contact")}
              </AddButton>
            </EmptyMessage>
          ) : (
            <ContactInfo
              contact={currentContact}
              chats={filteredChats}
              subpath={state?.subpath}
              session={
                state?.isFromQuestions ? state?.question : state?.session
              }
              isFromQuestions={state?.isFromQuestions}
            />
          )}
        </RightContainerSection>
      </ContactsContainer>
      <Drawer size="sm" isOpen={isOpen} onClick={onClose}>
        <DrawerOverlay>
          <DrawerContent background="white">
            <DrawerHeader>Agregar un contacto</DrawerHeader>
            <DrawerBody>
              <Formik
                innerRef={formRef}
                initialValues={{
                  email: paramContact?.email || "",
                  displayName: paramContact?.displayName || "",
                  phoneNumber: "",
                  userType: null,
                  country: user?.medicProfile?.country || "PE",
                }}
                onSubmit={async (values, actions) => {
                  setIsAddingContact(true)
                  actions.setSubmitting(true)
                  try {
                    const token = await firebase.getIdToken()
                    const contact = {
                      ...values,
                      email: values.email.toLowerCase(),
                      isFavorite: false,
                    }

                    const getPassword = generatePassword()

                    const medicProfile = await firebase.getProfile({
                      email: user.isImpersonating
                        ? user.impersonatingEmail
                        : user.email,
                    })

                    const medicProfileData = medicProfile.data()

                    const newUserResponse = await fetch(newUserUrl, {
                      method: "POST",
                      body: JSON.stringify({
                        user: {
                          email: values.email.toLowerCase(),
                          password: getPassword,
                          displayName: values.displayName,
                          phoneNumber: values.phoneNumber,
                          userType: "client",
                          country: values.country,
                        },
                      }),
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                    })

                    if (newUserResponse.ok) {
                      const getPatientProfile = await firebase.getProfile({
                        email: values.email,
                      })

                      const patientData = getPatientProfile.data()

                      if (patientData?.uid) {
                        await firebase.savePersonalData({
                          email: values.email.toLowerCase(),
                          data: {
                            uid: patientData.uid,
                            email: values.email.toLowerCase(),
                            displayName: values.displayName,
                            phoneNumber: values.phoneNumber,
                            userType: values.userType,
                            photoURL: "local",
                            status: "active",
                            messagingTokens: null,
                            locale: "es",
                            country: values.country,
                            medicalFormReady: false,
                            billingAddressCount: 0,
                            creationTime: new Date().getTime(),
                            lastSignInTime: null,
                          },
                        })

                        await firebase.addContact({
                          doctor: user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email,
                          contact,
                        })

                        setTimeout(async () => {
                          await fetch(mailerUrl, {
                            method: "POST",
                            body: JSON.stringify({
                              data: {
                                templateId: 4442418,
                                email: values.email,
                                variables: {
                                  providerName: get(
                                    medicProfileData,
                                    "displayName",
                                    ""
                                  ),
                                  referenceCode: get(
                                    medicProfileData,
                                    "referenceCode",
                                    ""
                                  ),
                                  password: getPassword,
                                  websiteLink: `${constants.cronofy.redirectURL}/login`,
                                  subject:
                                    "Te han invitado como paciente a la plataforma DOC",
                                },
                              },
                            }),
                            headers: {
                              Authorization: `Bearer ${token}`,
                              "Content-Type": "application/json",
                            },
                          })
                        }, 2500)

                        setContacts((prevState) => [...prevState, contact])

                        addToast(t("web_client.contact_created_successfully"), {
                          appearance: "success",
                        })
                      } else {
                        window.Honeybadger?.notify("Error - Create contact", {
                          message: "Error on create contact",
                          action: "submit",
                          context: {
                            formValues: values,
                          },
                        })
                        await fetch(deleteUserUrl, {
                          method: "POST",
                          body: JSON.stringify({
                            userToDeleteEmail: values.email,
                          }),
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                        })
                        addToast(t("unexpected_error"), { appearance: "error" })
                      }

                      onClose()
                      setIsAddingContact(false)
                      if (paramContact) {
                        const localContact = contacts.find(
                          (c) => c.email === paramContact.email
                        )
                        if (localContact) {
                          setCurrentContact(localContact)
                        }
                      }
                    } else if (
                      (await newUserResponse.json())?.error?.code ===
                      "auth/email-already-exists"
                    ) {
                      const isAlreadyContact = await firebase.checkContact(
                        user.isImpersonating
                          ? user.impersonatingEmail
                          : user.email,
                        contact.email
                      )

                      if (isAlreadyContact && !isAlreadyContact.empty) {
                        onClose()
                        setIsAddingContact(false)
                        addToast(
                          "El correo digitado ya se encuentra registrado como un contacto.",
                          {
                            appearance: "error",
                          }
                        )
                      } else {
                        await firebase.addContact({
                          doctor: user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email,
                          contact,
                        })

                        setTimeout(async () => {
                          await fetch(mailerUrl, {
                            method: "POST",
                            body: JSON.stringify({
                              data: {
                                templateId: 4442418,
                                email: values.email,
                                variables: {
                                  providerName: get(
                                    medicProfileData,
                                    "displayName",
                                    ""
                                  ),
                                  referenceCode: get(
                                    medicProfileData,
                                    "referenceCode",
                                    ""
                                  ),
                                  password:
                                    "actual o utiliza la opción para recuperar tu contraseña.",
                                  websiteLink: `${constants.cronofy.redirectURL}/login`,
                                  subject:
                                    "Te han agregado como contacto a la plataforma DOC",
                                },
                              },
                            }),
                            headers: {
                              Authorization: `Bearer ${token}`,
                              "Content-Type": "application/json",
                            },
                          })
                        }, 1000)

                        setContacts((prevState) => [...prevState, contact])
                        onClose()
                        addToast(t("web_client.contact_added_successfully"), {
                          appearance: "success",
                        })
                      }
                      setIsAddingContact(false)
                    } else {
                      setIsAddingContact(false)
                      addToast(t("unexpected_error"), { appearance: "error" })
                    }
                    actions.setSubmitting(false)
                  } catch (error) {
                    window.Honeybadger?.notify(error)
                    setIsAddingContact(false)
                    addToast(t("unexpected_error"), { appearance: "error" })
                  }
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email(t("error_auth_invalid_email"))
                    .required(t("error_all_fields_required")),
                  displayName: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  userType: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  phoneNumber: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                })}
                enableReinitialize
              >
                {(props) => (
                  <Form>
                    <VStack spacing={7}>
                      <FormControl>
                        <CustomInput
                          type="email"
                          name="email"
                          id="email"
                          placeholder={t("placeholder_email")}
                          showErrorMessage={!!props.errors.email}
                          errorMessage={props.errors.email}
                          handleInputChange={(e) =>
                            props.setFieldValue("email", e.target.value)
                          }
                          props={{
                            value: props.values.email,
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <CustomInput
                          name="displayName"
                          id="displayName"
                          placeholder={t("placeholder_fullname")}
                          showErrorMessage={!!props.errors.displayName}
                          errorMessage={props.errors.displayName}
                          handleInputChange={(e) =>
                            props.setFieldValue("displayName", e.target.value)
                          }
                          props={{
                            value: props.values.displayName,
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <CustomInput
                          type="tel"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder={t("placeholder_phone")}
                          showErrorMessage={!!props.errors.phoneNumber}
                          errorMessage={props.errors.phoneNumber}
                          handleInputChange={(e) =>
                            props.setFieldValue("phoneNumber", e.target.value)
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <Select
                          styles={customStyles}
                          value={userTypeOptions.find(
                            (option) => option.value === props.values.userType
                          )}
                          options={userTypeOptions.filter(
                            (v) => v.value !== "all"
                          )}
                          onChange={(option) => {
                            props.setFieldValue("userType", option.value)
                          }}
                          placeholder="Seleccione el tipo de contacto"
                          width="348px"
                        />
                      </FormControl>
                      <FormControl>
                        <Select
                          inputProps={{
                            autoComplete: "off",
                            autoCorrect: "off",
                            spellCheck: "off",
                          }}
                          styles={customStyles}
                          value={mappedCountries.find(
                            (option) => option.value === props.values.country
                          )}
                          options={mappedCountries}
                          onChange={(option) => {
                            props.setFieldValue("country", option.value)
                          }}
                          placeholder="Seleccione el país"
                          width="348px"
                        />
                      </FormControl>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="solid" color="black" mr={3} onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                background="brand.primary"
                color="white"
                _hover={{
                  background: "brand.primary",
                }}
                mr={3}
                onClick={handleAddContact}
                isLoading={isAddingContact}
              >
                Agregar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={onModalClose}
      >
        <ModalOverlay />
        <ModalContent minW="690px">
          <ModalHeader width="690px" backgroundColor="white" />
          <ModalCloseButton />
          <ModalBody
            backgroundColor="white"
            pb={1}
            pt={1}
            pl={0}
            pr={0}
            width="690px"
          >
            <AddAppointment
              providerPricing={providerPricing}
              currentContact={currentContact}
              onModalClose={onModalClose}
              isModalOpen={isModalOpen}
              user={user}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  )
}

ManageContacts.defaultProps = {
  paramContact: null,
}

ManageContacts.propTypes = {
  paramContact: PropTypes.shape(),
}

const ComponsedManageContacts = compose(
  WithChakra,
  withFirebase
)(ManageContacts)

export default ComponsedManageContacts
