import React from "react"
import proptypes from "prop-types"
import styled from "styled-components"

const TooltipBox = styled.div`
  position: absolute;
  z-index: 1;
  visibility: hidden;
  color: #fff;
  ${(props) => props.theme.font_size.xxxsmall};
  padding: 5px 12px;
  max-width: 300px;
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.background.black80};
  ${({ isDropdown }) =>
    isDropdown &&
    `
    right: 102%;
    top: -10px;
  `}
  ${({ isDropdownLeft }) =>
    isDropdownLeft &&
    `
  left: 102%;
  top: -10px;
`}
`

const TooltipContainer = styled.div`
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    & > ${TooltipBox} {
      visibility: ${(props) => (props.showTooltip ? "visible" : "hidden")};
    }
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    &:hover {
      & > ${TooltipBox} {
        visibility: visible;
      }
    }
  }
`

const Tooltip = ({
  children,
  title,
  isDropdown,
  isDropdownLeft,
  showTooltip,
}) => (
  <TooltipContainer showTooltip={showTooltip}>
    {children}

    <TooltipBox isDropdown={isDropdown} isDropdownLeft={isDropdownLeft}>
      {title}
    </TooltipBox>
  </TooltipContainer>
)

Tooltip.defaultProps = {
  isDropdown: false,
  isDropdownLeft: false,
  showTooltip: false,
}

Tooltip.propTypes = {
  children: proptypes.node.isRequired,
  isDropdown: proptypes.bool,
  isDropdownLeft: proptypes.bool,
  showTooltip: proptypes.bool,
}

export default Tooltip
