import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import get from "lodash.get"
import Tooltip from "../../../../atoms/Tooltip"
import Input from "../../../../atoms/Input"

import { withFirebase } from "../../../../../utils/Firebase"
import packageJson from "../../../../../../package.json"

import { Section, ForgotButton, StyledForm, SubmitButton } from "./styles"
import {
  PasswordMessagesContainer,
  PasswordInfoMessage,
} from "../../../CreateAccount/styles"
import Theme from "../../../../../styles/theme"

const loadingSpeed = 5
const Security = ({ firebase, user }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [changingPassword, setChangingPassword] = useState(false)
  const [passwordFormValues, setPasswordFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })
  const [online, setOnline] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useEffect(() => {
    if (firebase && user) {
      const Profile = get(user, ["medicProfile"])
      if (Profile) {
        setOnline(Profile.online)
      }
    }
  }, [user])

  useEffect(() => {
    if (isButtonLoading) {
      setTimeout(() => {
        setIsButtonLoading(false)
      }, 1000 / loadingSpeed)
    }
  }, [isButtonLoading])

  const handlePasswordInputChange = (e) => {
    e.persist()
    setPasswordFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }

  const handleChangePassword = async () => {
    if (!changingPassword) {
      setChangingPassword(true)
    } else {
      try {
        await firebase.updatePassword(passwordFormValues.newPassword)
        setChangingPassword(false)
        addToast(t("password_updated"), { appearance: "success" })
      } catch (e) {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }
  }

  const handleConnectStatus = async () => {
    try {
      await firebase.toggleOnlineStatus({
        email: user.email,
        data: {
          online: !online,
          indexDocument: true,
        },
      })
      setOnline(!online)
      addToast(t("web_client.saved_successfully"), { appearance: "success" })
    } catch (error) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleCloseClick = async () => {
    try {
      await firebase.toggleOnlineStatus({
        email: user.email,
        data: {
          online: false,
          indexDocument: true,
        },
      })
      firebase.logout()
    } catch (e) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const isButtonDisabled = () =>
    passwordFormValues.newPassword !== passwordFormValues.confirmNewPassword ||
    !passwordFormValues.newPassword.match(/[a-zA-Z]+/) ||
    !passwordFormValues.newPassword.match(/[0-9]+/) ||
    !passwordFormValues.newPassword.match(
      // eslint-disable-next-line no-useless-escape
      /[\!\#\$\^\&\*\-\_\+\=\|\\\/\"\[\]\{\}\(\)]+/
    ) ||
    passwordFormValues.newPassword.length < 6

  return (
    <>
      {!changingPassword && (
        <Section>
          <Tooltip title={t("go_online_tooltip")}>
            <SubmitButton
              isLoading={isButtonLoading}
              type="button"
              onClick={handleConnectStatus}
              color={Theme.color.darkPurple}
              noRightMargin
            >
              {online ? t("go_offline") : t("go_online")}
            </SubmitButton>
          </Tooltip>
          <SubmitButton
            type="button"
            isLoading={isButtonLoading}
            onClick={handleChangePassword}
            color={Theme.color.darkPurple}
            noRightMargin
          >
            {t("change_password")}
          </SubmitButton>
          <SubmitButton
            isLoading={isButtonLoading}
            type="submit"
            onClick={handleCloseClick}
            color={Theme.color.darkPurple}
            noRightMargin
          >
            {t("log_out")}
          </SubmitButton>
          <span
            style={{
              fontSize: "0.8rem",
              textAlign: "center",
              width: "100%",
            }}
          >
            Version. {packageJson.version}
          </span>
        </Section>
      )}
      {changingPassword && (
        <Section>
          <ForgotButton
            type="button"
            onClick={() => setChangingPassword(false)}
          >
            <span>{t("go_back")}</span>
          </ForgotButton>
          <p>{t("change_password_description")}</p>
          <StyledForm>
            <Input
              icon="password"
              value={passwordFormValues.currentPassword}
              onChange={handlePasswordInputChange}
              labelName={t("current_password")}
              type="password"
              name="currentPassword"
              id="currentPassword"
              required
            />
            <Input
              icon="password"
              value={passwordFormValues.newPassword}
              onChange={handlePasswordInputChange}
              labelName={t("new_password")}
              type="password"
              name="newPassword"
              id="newPassword"
              required
            />
            <Input
              icon="password"
              value={passwordFormValues.confirmNewPassword}
              onChange={handlePasswordInputChange}
              labelName={t("confirm_new_password")}
              type="password"
              name="confirmNewPassword"
              id="confirmNewPassword"
              required
            />
            <PasswordMessagesContainer>
              <PasswordInfoMessage
                isEmpty={!passwordFormValues.newPassword}
                isValid={passwordFormValues.newPassword.match(/[0-9]+/)}
              >
                {t("password_contain_number")}
              </PasswordInfoMessage>
              <PasswordInfoMessage
                isEmpty={!passwordFormValues.newPassword}
                isValid={passwordFormValues.newPassword.match(/[a-zA-Z]+/)}
              >
                {t("password_contain_letters")}
              </PasswordInfoMessage>
              <PasswordInfoMessage
                isEmpty={!passwordFormValues.newPassword}
                isValid={passwordFormValues.newPassword.match(
                  // eslint-disable-next-line no-useless-escape
                  /[\!\#\$\^\&\*\-\_\+\=\|\\\/\"\[\]\{\}\(\)]+/
                )}
              >
                {t("password_contain_symbols")}
              </PasswordInfoMessage>
              <PasswordInfoMessage
                isEmpty={!passwordFormValues.newPassword}
                isValid={passwordFormValues.newPassword.length >= 6}
              >
                {t("password_minimun")}
              </PasswordInfoMessage>
              <PasswordInfoMessage
                isEmpty={!passwordFormValues.confirmNewPassword}
                isValid={
                  passwordFormValues.newPassword ===
                  passwordFormValues.confirmNewPassword
                }
              >
                {t("password_confirmation_not_matching")}
              </PasswordInfoMessage>
            </PasswordMessagesContainer>
            <SubmitButton
              isLoading={isButtonLoading}
              type="button"
              onClick={handleChangePassword}
              color={Theme.color.darkPurple}
              disabled={isButtonDisabled()}
            >
              {t("update")}
            </SubmitButton>
          </StyledForm>
        </Section>
      )}
    </>
  )
}

export default withFirebase(Security)
